import React, { useState, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { useSFLApi } from 'api';
import MobileNavbar from 'v2/components/MobileNavbar';
import WaterLogForm from './WaterLogForm'

const scales = [
    { key: 'litre' },
    { key: 'cup' },
    { key: 'ml' },
    { key: 'ounce' }
]

export default function WaterTrackerEditLoader() {
    const [isLoading, setIsLoading] = useState(true)
    const { waterLogUuid = '' } = useParams();
    const { get } = useSFLApi()
    const [state, setState] = useState({ scale: 'cup' })

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!waterLogUuid) {
                    setIsLoading(false)
                    return
                }

                const results = await get(`waterLogs/${waterLogUuid}`)

                setState(results.waterLog)
                setIsLoading(false)
            }
            catch (error) {
                console.log('error', error)
            }
        }

        fetchData()
    }, [waterLogUuid, get, setState, setIsLoading])

    if (isLoading === true) {
        return <div></div>
    }

    return <WaterLogEdit
        defaultSelectedScale={state.scale}
        defaultState={state}
    />
}


const WaterLogEdit = ({ defaultSelectedScale, defaultState = {} }) => {
    const [searchParams] = useSearchParams()
    const { waterLogUuid = '', memberUuid } = useParams();
    const date = searchParams.get('date')
    const navigate = useNavigate()
    const { post } = useSFLApi()
    const [state, setState] = useState(defaultState)
    const [selectedScale, setSelectedScale] = useState(defaultSelectedScale)

    const handleChange = (key, val) => {
        if (key == 'scale') {
            setSelectedScale(val)
        }

        setState(currentState => {
            return { ...currentState, [key]: val }
        })
    }

    const handleSave = async () => {
        try {
            await post(`waterLogs/${waterLogUuid}`, {
                "member_uuid": memberUuid,
                "datetime": date,
                "amount": state.amount,
                "scale": selectedScale,
            })
            navigate(`..?date=${date}`)
        }
        catch (error) {
            console.log('error', error)
        }
    }

    return <>
        <MobileNavbar goBack={true} title={<FormattedMessage id="app.waterTracker" defaultMessage="Water Tracker" />} />
        <WaterLogForm
            scales={scales}
            clearSelectedScale={() => { setSelectedScale() }}
            data={state}
            onChange={handleChange}
            onSave={handleSave}
        />
    </>
}
