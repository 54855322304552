import * as React from "react"
import Tag from 'v2/components/Tags/Tag'
import Dairy from 'v2/assets/svgIcons/Dairy'

export default function SpicyTag() {
    return <Tag
        colorLeft="#1034a6"
        colorRight="#1e90ff"
        Icon={Dairy}
        title="Dairy Free"
    />
}