import React, { useEffect, useState } from "react";
import Card from '../components/Card'
import CardTitle from '../components/CardTitle'
import CircleCheck from 'v2/assets/svgIcons/CircleCheck'
import CircleDash from 'v2/assets/svgIcons/CircleDash'
import { useSFLApi } from 'api';
import TextField from 'sfl-components/TextField/Outlined';
import { FormattedMessage } from 'react-intl';


const Option = ({ title = "", selected = false, onClick }) => {
    if (selected) {
        return <div className='hoverUnderline' style={{ display: 'flex' }} onClick={onClick}>
            <div style={{ flexGrow: 1, padding: '10px', display: 'flex', alignItems: 'center', marginLeft: '30px', borderBottom: '1px solid #dddddd' }}>{title}</div>
            <div style={{ paddingRight: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #dddddd' }}>
                <CircleCheck
                    size={30}
                    color={"#17B169"}
                />
            </div>
        </div>
    }

    return <div style={{ display: 'flex' }} onClick={onClick}>
        <div className='hoverUnderline' style={{ flexGrow: 1, padding: '10px', display: 'flex', alignItems: 'center', marginLeft: '30px', borderBottom: '1px solid #dddddd' }}>{title}</div>
        <div style={{ paddingRight: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #dddddd' }}>
            <CircleDash
                size={30}
                color={"#cccccc"}
            />
        </div>
    </div>
}

const OptionTitle = ({ title = "", children }) => {
    const [showOptions, setShowOptions] = useState(true)
    return <div>
        <div className='hoverUnderline' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #dddddd', padding: '10px', marginBottom: '10px' }} onClick={() => { setShowOptions(!showOptions) }}>
            <div style={{ fontWeight: 600 }}>{title}</div>
            <div style={{ color: '#aaaaaa' }}>{showOptions ? <FormattedMessage id="questionnaireV2.hideList" defaultMessage="Hide List" /> : <FormattedMessage id="questionnaireV2.showList" defaultMessage="Show List" />}</div>
        </div>
        {showOptions && <div style={{ paddingBottom: '30px' }}>
            {children}
        </div>}
    </div>
}

export default function QuestionnaireDietRestrictions({ data, onChange, onNext, onPrevious }) {
    const [categories, setCategories] = useState([])
    const { get } = useSFLApi();
    const { gluten_free = false, dairy_free = false, pescatarian = false, vegetarian = false, vegan = false, allergies = [], restrictions_notes = '' } = data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await get(`foodCategories?status=active&sort=name&questionnaire=true`)
                setCategories(results.foodCategories)
            }
            catch (error) {
                console.log(error)
            }
        }

        fetchData()
    }, [get, setCategories])

    return <Card onNext={onNext} onPrevious={onPrevious}>
        <CardTitle
            title={<FormattedMessage id="questionnaireV2.restrictions.title" defaultMessage="Do you have any allergies or dietary restrictions?" />}
            description={<FormattedMessage id="questionnaireV2.restrictions.description" defaultMessage="Please select all applicable restrictions." />}
        />
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.dieateryRestrictions" defaultMessage="Dieatery Restrictions" />}>
            <Option
                title={<FormattedMessage id="questionnaireV2.restrictions.dairyFree" defaultMessage="Dairy Free" />}
                selected={dairy_free}
                onClick={() => { onChange('dairy_free', !dairy_free) }}
            />
            <Option
                title={<FormattedMessage id="questionnaireV2.restrictions.glutenFree" defaultMessage="Gluten Free" />}
                selected={gluten_free}
                onClick={() => { onChange('gluten_free', !gluten_free) }}
            />
            <Option
                title={<FormattedMessage id="questionnaireV2.restrictions.pescatarian" defaultMessage="Pescatarian" />}
                selected={pescatarian}
                onClick={() => { onChange('pescatarian', !pescatarian) }}
            />
            <Option
                title={<FormattedMessage id="questionnaireV2.restrictions.vegetarian" defaultMessage="Vegetarian" />}
                selected={vegetarian}
                onClick={() => { onChange('vegetarian', !vegetarian) }}
            />
            <Option
                title={<FormattedMessage id="questionnaireV2.restrictions.vegan" defaultMessage="Vegan" />}
                selected={vegan}
                onClick={() => { onChange('vegan', !vegan) }}
            />
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.proteins" defaultMessage="Proteins" />}>
            {categories.filter(category => category.type === 'protein')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.dairy" defaultMessage="Dairy" />}>
            {categories.filter(category => category.type === 'dairy')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.dairyAlternatives" defaultMessage="Dairy Alternatives" />}>
            {categories.filter(category => category.type === 'dairy_alt')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.fruits" defaultMessage="Fruits" />}>
            {categories.filter(category => category.type === 'fruit')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.vegetables" defaultMessage="Vegetables" />}>
            {categories.filter(category => category.type === 'vegetable')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.nutsSeeds" defaultMessage="Nuts / Seeds" />}>
            {categories.filter(category => category.type === 'nut_seed')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.grain" defaultMessage="Grains" />}>
            {categories.filter(category => category.type === 'grain')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.miscellaneous" defaultMessage="Miscellaneous" />}>
            {categories.filter(category => category.type === 'misc')
                .map(category => {
                    const isSelected = allergies.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        selected={isSelected}
                        onClick={() => {
                            onChange('allergies', isSelected ? [...allergies.filter(uuid => uuid !== category.uuid)] : [...allergies, category.uuid])
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle
            title={<FormattedMessage id="questionnaireV2.restrictions.otherRestrictionsAllergies" defaultMessage="Other Restrictions / Allergies" />}
        >
            <div style={{ marginLeft: '30px' }}>
                <TextField
                    multiline
                    id="restrictions_notes"
                    placeholder=""
                    value={restrictions_notes}
                    minRows={3}
                    onChange={onChange}
                />
            </div>
        </OptionTitle>
    </Card>
}