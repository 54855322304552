import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { FormattedMessage, useIntl } from 'react-intl';
import DialogListSelect from 'sfl-components/DialogListSelect';

export default function ModeSelectButton({ mode = 'day', onChange }) {
    const [showDialog, setShowDialog] = useState(false)
    const intl = useIntl();

    return [
        <CardButton key='button' style={{ marginRight: '30px' }}
            onClick={() => {
                setShowDialog(true)
            }}>
            <FontAwesomeIcon icon={['fas', 'history']} />
            <FormattedMessage id="foodLog.modeSelect" defaultMessage={`Mode: ${mode}`} />
        </CardButton>,
        <DialogListSelect
            key='dialog'
            selectedUuid={mode}
            title={intl.formatMessage({ id: 'foodLogMode', defaultMessage: 'Food Log Mode' })}
            options={[
                {
                    uuid: 'day',
                    name: '1 Day',
                    description: 'Show food log entries for the selected date only.'
                },
                {
                    uuid: 'week',
                    name: '7 Days',
                    description: 'Show food log entries for the selected date and past 6 days.'
                }
            ]}
            onSelect={onChange}
            open={showDialog}
            onClose={() => {
                setShowDialog(false)
            }}
        />
    ]
}