import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        width={size}
        viewBox="0 0 810 810"
    >
        <path fill={color} d="M 728.550781 343.398438 C 728.550781 301.46875 694.492188 267.40625 652.5625 267.40625 C 610.574219 267.40625 576.570312 301.46875 576.570312 343.398438 C 576.570312 385.386719 610.574219 419.390625 652.5625 419.390625 C 694.492188 419.390625 728.550781 385.386719 728.550781 343.398438 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 81.90625 529.042969 C 81.273438 529.84375 81.390625 530.9375 82.136719 531.570312 C 82.824219 532.144531 83.800781 532.144531 84.492188 531.570312 C 125.847656 498.425781 178.519531 482.746094 231.304688 487.800781 C 236.1875 488.257812 240.898438 489.753906 245.148438 492.050781 C 324.816406 535.761719 418.039062 547.882812 506.265625 525.996094 C 565.082031 504.972656 628.265625 498.941406 690.011719 508.476562 C 690.988281 508.648438 691.90625 508.019531 692.078125 507.042969 C 692.195312 506.179688 691.734375 505.375 690.929688 505.03125 C 628.953125 479.988281 559.394531 481.652344 498.738281 509.683594 C 414.191406 529.441406 325.332031 516.75 249.683594 474.070312 C 246.46875 472.292969 243.023438 470.96875 239.402344 470.167969 C 179.953125 457.757812 118.609375 480.675781 81.90625 529.097656 L 81.90625 529.042969 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 255.714844 345.007812 C 242.449219 354.199219 224.125 350.921875 214.933594 337.597656 C 205.746094 324.328125 209.019531 306.121094 222.230469 296.875 L 334.351562 218.871094 C 341.414062 213.933594 350.835938 214.21875 357.613281 219.503906 L 516.257812 344.605469 C 521.945312 349.085938 526.769531 354.597656 530.445312 360.804688 L 589.089844 460.113281 C 555.71875 461.550781 522.863281 469.363281 492.421875 483.089844 C 413.15625 501.125 330.101562 489.007812 259.277344 449.085938 C 256.980469 447.824219 254.625 446.675781 252.097656 445.699219 L 410.226562 335.703125 L 344.058594 283.492188 L 255.714844 345.007812 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 81.90625 588.261719 C 81.273438 589.066406 81.390625 590.15625 82.136719 590.789062 C 82.824219 591.363281 83.800781 591.363281 84.492188 590.789062 C 125.847656 557.644531 178.519531 541.964844 231.304688 547.019531 C 236.1875 547.480469 240.898438 548.914062 245.148438 551.269531 C 324.816406 594.980469 418.039062 607.101562 506.265625 585.214844 C 565.082031 564.195312 628.265625 558.164062 690.011719 567.699219 C 690.988281 567.871094 691.90625 567.238281 692.078125 566.261719 C 692.195312 565.398438 691.734375 564.59375 690.929688 564.25 C 628.953125 539.207031 559.394531 540.875 498.738281 568.902344 C 414.191406 588.664062 325.332031 575.910156 249.683594 533.292969 C 246.46875 531.453125 243.023438 530.191406 239.402344 529.386719 C 179.953125 516.980469 118.609375 539.898438 81.90625 588.316406 L 81.90625 588.261719 " fillOpacity="1" fillRule="nonzero"/>
    </svg>
}

export default SvgComponent