import * as React from "react"

export default function ClockSvg({ size, color = "currentColor", style }) {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 24 24`}
        width={size}
        height={size}
        style={style}
    >
        <path fill={color} d="M12 20a1 1 0 0 1-.437-.1C11.214 19.73 3 15.671 3 9a5 5 0 0 1 8.535-3.536l.465.465.465-.465A5 5 0 0 1 21 9c0 6.646-8.212 10.728-8.562 10.9A1 1 0 0 1 12 20zM8 6a3 3 0 0 0-3 3c0 4.639 5.4 7.981 7 8.868 1.6-.89 7-4.247 7-8.868a3 3 0 0 0-5.121-2.121L12.707 8.05a1 1 0 0 1-1.414 0l-1.172-1.171A2.98 2.98 0 0 0 8 6z" />
    </svg>
}