import React from "react";


export default function NutritionList({ ingredients = [] }) {
    //TODO: Kind of dirty
    const ingSort = (ingA, ingB) => {
        const a = ingA.amount
        const b = ingB.amount
        // equal items sort equally
        if (a === b) {
            return 0;
        }

        // nulls sort after anything else
        if (a === '') {
            return 1;
        }
        if (b === '') {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first

        return 0
    }

    const tableData = (parentUuid = null, servings) => {
        return ingredients.filter((e) => e.parent_uuid === parentUuid).sort(ingSort)
            .map((ingredient, index) => {
                if (ingredient.type === 'group') {
                    return [
                        <div key={index} style={{ display: 'flex', gap: '10px' }}>
                            <div style={{ padding: '10px', paddingTop: '20px', flex: 3, fontWeight: 600 }}> {ingredient.name}</div>
                            <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right' }}></div>
                            <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right' }}></div>
                            <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right' }}></div>
                        </div>,
                        tableData(ingredient.uuid)
                    ]
                }

                return <div key={index} style={{ display: 'flex', gap: '10px', backgroundColor: index % 2 == 0 ? '#eeeeee' : '#ffffff' }}>
                    <div style={{ padding: '10px', flex: 3 }}>{ingredient.amount} {ingredient.size}{ingredient.scale} {ingredient.name}</div>
                    <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right' }}>{ingredient.protein}</div>
                    <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right' }}>{ingredient.fat}</div>
                    <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right' }}>{ingredient.carb}</div>
                </div>
            })
    }


    return <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
            <div style={{ padding: '10px', flex: 3 }}></div>
            <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right', fontWeight: 600 }}>Protein (g)</div>
            <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right', fontWeight: 600 }}>Fat (g)</div>
            <div style={{ padding: '10px', paddingRight: '10px', flex: 1, textAlign: 'right', fontWeight: 600 }}>Carb (g)</div>
        </div>
        {tableData().flat()}
    </div>
}
