import React from "react";
import { useApi } from 'api';
import { FormattedMessage } from 'react-intl';

export default function RecipeDetails() {
    const { data: { articles = [] } } = useApi('articles')

    return <>
        <div style={{ fontSize: '24px', fontWeight: 700, marginBottom: '20px' }}>
            <FormattedMessage
                id="app.LatestNewsFromSimplyForLife"
                defaultMessage="Latest News From Simply For Life"
            />
        </div>
        <div style={{ display: 'grid', gap: '30px', gridTemplateColumns: 'repeat(auto-fit,  minmax(600px, 1fr))' }}>
            {articles.filter(e => e.name !== '').map(article => {
                if (!article.image) {
                    return null
                }

                if (article.name === '') {
                    return null
                }

                return <ArticleCard
                    key={article.uuid}
                    url={article.url}
                    name={article.name}
                    description={article.description}
                    imageUrl={article.image.path}
                />
            })}
        </div>
    </>
}

const ArticleCard = ({ name, description, imageUrl, url }) => {
    return <a className='plainLink' target='_blank' rel="noreferrer" href={url}>
        <div className='shadowBox shadowBoxHover' style={{ display: 'flex' }}>
            <div style={{ flex: 1, padding: '10px 0px 10px 10px', textAlign: 'center' }}>
                <img
                    src={`${imageUrl}?width=300`}
                    alt={name}
                    style={{ borderRadius: '10px', width: '100%', objectFit: 'cover' }}
                />
            </div>
            <div style={{ flex: 2, padding: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'space-between', minHeight: '100%' }}>
                    <div>
                        <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '10px' }}>{name}</div>
                        <div style={{ fontWeight: 300 }}>
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
}