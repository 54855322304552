import React, { useState } from "react";
import { useSFLApi } from 'api';
import { FormattedMessage } from 'react-intl';
import HeartSolid from 'v2/assets/svgIcons/HeartSolid'
import HeartOutline from 'v2/assets/svgIcons/HeartOutline'


export default function DownloadRecipeButton({ uuid }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false)
    const { post } = useSFLApi();

    const handleFavoriteToggle = async () => {
        if (isLoading) {
            return
        }

        setIsLoading(true)
        try {
            await post(`recipes/${uuid}/favorite`, { favorite: !isFavorite })
            setIsFavorite(!isFavorite)
        }
        catch (error) {
            console.log('error')
        }
        setIsLoading(false)
    }


    if (isFavorite) {
        return <div className='greenButton' onClick={handleFavoriteToggle}>
            <HeartOutline color="currentColor" size={24} style={{ marginRight: '5px' }} />
            <FormattedMessage id="app.unfavorite" defaultMessage="Unfavourite" />
        </div>
    } else {
        return <div className='greenButton' onClick={handleFavoriteToggle}>
            <HeartSolid color="currentColor" size={24} style={{ marginRight: '5px' }} />
            <FormattedMessage id="app.favorite" defaultMessage="Favourite" />
        </div>
    }
}