import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import Recipes from 'v2/assets/svgIcons/Recipes'
import MealPlan from 'v2/assets/svgIcons/MealPlan'
import RecipeCard from 'v2/components/Recipe'
import { useSFLApi } from 'api';
import Tabs from 'v2/components/Tabs'
import Tab from 'v2/components/Tab'
import ReactMarkdown from 'react-markdown';
import { Card, CardTitle, CardContent } from 'v2/components/TitleCard'
import ServingsNutritionCard from 'v2/components/ServingsNutritionCard'
import IngredientList from 'v2/components/IngredientList'
import NutritionList from 'v2/components/NutritionList'
import DownloadRecipeButton from 'v2/components/Buttons/DownloadRecipeButton';
import FavoriteRecipeButton from 'v2/components/Buttons/FavoriteRecipeButton';
import MobileNavbar from 'v2/components/MobileNavbar';


export default function RecipeDetails() {
    const { recipeUuid } = useParams()
    const { get } = useSFLApi();
    const [recipe, setRecipe] = useState({})
    const [ingredients, setIngredients] = useState([])
    const [activeTab, setActiveTab] = useState(1)

    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                const recipeResults = await get(`recipes/${recipeUuid}`)
                setRecipe(recipeResults.recipe)


                const ingredientResults = await get(`recipeIngredients?recipe_uuid=${recipeUuid}`)
                setIngredients(ingredientResults.recipeIngredients)
            }
            catch (error) {
                console.log('error', error)
            }
        }

        fetchRecipe()
    }, [recipeUuid, setRecipe, setIngredients])


    //TODO: figure out cleaner way
    const imageUrl = Array.isArray(recipe.images) && recipe.images.length > 0 ? recipe.images[0].path : ''

    return <>
        <MobileNavbar goBack={true} title={<FormattedMessage id="app.recipes" defaultMessage="Recipes" />} />
        <RecipeCard
            uuid={recipe.uuid}
            imageUrl={imageUrl}
            title={recipe.name}
            servings={recipe.servings}
            minutes={recipe.minutes}
            ingredientCount={recipe.ingredient_count}
            rating={recipe.rating}
            imageModal={true}
            spicy={recipe.spicy}
            vegan={recipe.vegan}
            vegetarian={recipe.vegetarian}
            pescatarian={recipe.pescatarian}
            gluten_free={recipe.gluten_free}
            dairy_free={recipe.dairy_free}
        >
            <div style={{ display: 'flex', gap: '10px' }}>
                <DownloadRecipeButton uuid={recipeUuid} />
                <FavoriteRecipeButton uuid={recipeUuid} />
            </div>
        </RecipeCard>
        <Tabs style={{ marginBottom: '20px' }}>
            <Tab
                name={<FormattedMessage id="ingredientsDirections" defaultMessage="Ingredients & Directions" />}
                active={activeTab === 1}
                onClick={() => { setActiveTab(1) }}
            />
            <Tab
                name={<FormattedMessage id="nutritionalInformation" defaultMessage="Nutritional Information" />}
                active={activeTab === 2}
                onClick={() => { setActiveTab(2) }}
            />
        </Tabs>
        {activeTab === 2
            ? <ServingsNutritionCard
                servings={recipe.servings}
                protein={recipe.protein}
                fat={recipe.fat}
                carb={recipe.carb}
            >
                <NutritionList ingredients={ingredients} />
            </ServingsNutritionCard>
            : <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap-reverse' }}>
                <Card style={{ minWidth: '350px' }}>
                    <CardTitle><FormattedMessage id="app.directions" defaultMessage="Directions" /> <MealPlan size={30} color={"white"} /></CardTitle>
                    <CardContent>
                        <ReactMarkdown>{recipe?.directions}</ReactMarkdown>
                    </CardContent>
                </Card>
                <Card style={{ minWidth: '350px' }}>
                    <CardTitle><FormattedMessage id="app.ingredients" defaultMessage="Ingredients" /> <Recipes size={30} color={"white"} /></CardTitle>
                    <CardContent>
                        <IngredientList ingredients={ingredients} />
                    </CardContent>
                </Card>
            </div>
        }
    </>
}
