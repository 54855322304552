import React, { useState, useEffect, useCallback } from "react";
import { useParams,useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';
import CardButton from 'sfl-components/SFLCards/CardButton';
import generateEntry from './Components/Entry';
import { Row, Cell } from 'sfl-components/Containers2';
import Title from 'sfl-components/Title';
import EntryDialog from './Components/EntryDialog';
import DialogListSelect from 'sfl-components/DialogListSelect';
import { FormattedMessage, useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import MealPlanIcon from 'assets/svgIcons/MealPlan'
import { useSFLApi } from 'api';
import Hidden from "@material-ui/core/Hidden";

const DownloadMealPlanButton = (props) => {
    const { uuid } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useSFLApi();

    const getPDF = async () => {
        setIsLoading(true)
        try {
            const printResults = await get(`mealPlans/${uuid}/print2`)
            if (!printResults) {
                throw 'API file download error!';
            }
            window.open(printResults.download.path)
        }
        catch (error) {
            console.log('error', error)
            toast.error('Download Failed')
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <CardButton iconComponent={<CircularProgress size={20} style={{ color: 'white' }} />} style={{ backgroundColor: '#b999ba' }}><FormattedMessage id="app.print" defaultMessage="Print" /></CardButton>
    } else {
        return <CardButton icon={['far', 'file-pdf']} onClick={getPDF}><FormattedMessage id="app.print" defaultMessage="Print" /></CardButton>
    }
}


export default function MealPlanEditor() {
    const [editEntry, setEditEntry] = useState();
    const [mealPlans, setMealPlans] = useState([]);
    const [mealPlan, setMealPlan] = useState({});
    const [mealPlanEntries, setMealPlanEntries] = useState([]);
    const [expandedEntries, setExpandedEntries] = useState([]);
    const [showMealPlanSelect, setShowMealPlanSelect] = useState(false);
    const { mealPlanUuid } = useParams();
    const { get, post } = useSFLApi();
    const navigate = useNavigate();
    const [error, setError] = useState();
    const intl = useIntl();


    const fetchData = useCallback(async () => {
        const results = await get(`mealPlans?status=active&sort=start_date:desc,name:asc`);
        setMealPlans(results.mealPlans);

        if (results.mealPlans.length === 0) {
            return
        }

        const selectedMealPlan = mealPlanUuid
            ? results.mealPlans.find(e => e.uuid === mealPlanUuid)
            : results.mealPlans[0]

        if (!selectedMealPlan) {
            setError({
                type: 'warning',
                title: 'Warning',
                description: <FormattedMessage id="mealPlan.mealPlanEmptyContactConsultant" defaultMessage='The selected meal plan was not found. Please contact your consultant for assistance.' />
            });

            return;
        }

        setMealPlan(selectedMealPlan);

        get(`mealPlanEntries?status=active&sort=order&meal_plan_uuid=${selectedMealPlan.uuid}`)
            .then(results => {
                if (results.mealPlanEntries.length === 0) {
                    setError({
                        type: 'warning',
                        title: 'Warning',
                        description: <FormattedMessage id="mealPlan.mealPlanEmptyContactConsultant" defaultMessage='The selected meal plan appears to be empty. Please contact your consultant for assistance.' />
                    });
                }
                setMealPlanEntries(results.mealPlanEntries)
            })
            .catch(error => {
                setError({
                    type: 'error',
                    title: 'Error',
                    description: <FormattedMessage id="mealPlan.mealPlanErrorContactConsultant" defaultMessage='An error has occured while retreiving the selected meal plan. Please contact your consultant for assistance.' />
                });
                console.log('error', error)
            })
    }, [get, mealPlanUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toggleShoppingList = entryUuid => {
        const entryIndex = mealPlanEntries.findIndex(e => e.uuid === entryUuid);
        post(`mealPlanEntries/${entryUuid}`, { shopping_list: !mealPlanEntries[entryIndex].shopping_list })
            .then(results => {
                mealPlanEntries[entryIndex] = results.mealPlanEntry;
                setMealPlanEntries([...mealPlanEntries])
            });
    }


    const toggleFavorite = entryUuid => {
        const entryIndex = mealPlanEntries.findIndex(e => e.uuid === entryUuid);
        post(`mealPlanEntries/${entryUuid}`, { favorite: !mealPlanEntries[entryIndex].favorite })
            .then(results => {
                mealPlanEntries[entryIndex] = results.mealPlanEntry;
                setMealPlanEntries([...mealPlanEntries])
            });
    }


    const toggleChildren = entryUuid => {
        if (expandedEntries.includes(entryUuid)) {
            setExpandedEntries(expandedEntries.filter(e => e !== entryUuid));
        } else {
            setExpandedEntries([...expandedEntries, entryUuid]);
        }
    }

    const actions = {
        editEntry: setEditEntry,
        childrenToggle: toggleChildren,
        cartToggle: toggleShoppingList,
        favoriteToggle: toggleFavorite,
        helpToggle: () => { }
    }


    let dialog;
    if (editEntry) {
        const entry = mealPlanEntries.find(e => e.uuid === editEntry);
        dialog = <EntryDialog entry={entry} actions={actions} onClose={setEditEntry} />
    }

    const modifiedEntries = mealPlanEntries.map(mpEntry => {
        return {
            ...mpEntry,
            displayChildren: expandedEntries.includes(mpEntry.uuid)
        }
    })


    if (!mealPlan.uuid || mealPlanEntries.length === 0) {
        return <div style={{ display: 'flex', paddingTop: '150px' }}>
            <Hidden mdDown>
                <div style={{ flex: 1, textAlign: 'center', padding: '50px' }}>
                    <MealPlanIcon size='300' color='#bbbbbb' />
                </div>
            </Hidden>
            <div style={{ flex: 1, padding: '50px' }}>
                <div style={{ color: '#bbbbbb', fontSize: '48px', fontWeight: 'bold', margin: '50px 0' }}>
                    <FormattedMessage id="mealPlan.checkBackSoon" defaultMessage='Check back soon!' />
                </div>
                <div style={{ color: '#bbbbbb', fontSize: '24px', fontWeight: 'bold', maxWidth: '500px' }}>
                    <FormattedMessage id="mealPlan.mealPlanNotReady" defaultMessage="Your meal plan isn't quite ready yet. Please check with your consultant for additional information." />
                </div>
            </div>
        </div>
    }

    return <>
        {dialog}
        <DialogListSelect
            title={intl.formatMessage({ id: "mealPlan.selectAMealPlan", defaultMessage: 'Select A Meal Plan' })}
            options={mealPlans}
            selectedUuid={mealPlan?.uuid}
            onSelect={(uuid) => { navigate(`/meal-plan/${uuid}`) }}
            open={showMealPlanSelect}
            onClose={setShowMealPlanSelect}
        />
        <Row>
            <Title>{mealPlan.name}</Title>

            <DownloadMealPlanButton uuid={mealPlan.uuid} />
            <CardButton icon={['fas', 'calendar-alt']} onClick={() => setShowMealPlanSelect(true)}><FormattedMessage id="mealPlan.pastMealPlans" defaultMessage='Past Meal Plans' /></CardButton>
            <CardButton icon={['fas', 'shopping-cart']} onClick={() => navigate(`/meal-plan/grocery-list/${mealPlan.uuid}`)}><FormattedMessage id="app.groceryList" defaultMessage="Grocery List" /></CardButton>
        </Row>
        <Row>
            <Cell style={{ whiteSpace: 'pre-line' }}>{mealPlan.description}</Cell>
        </Row>
        {generateEntry(modifiedEntries, mealPlan.members, null, actions)}
        {error ? error.description : undefined}
    </>
}