import { useIntl } from 'react-intl';

export default function ServingSize({ amount = '', size = '', scale = '' }) {
    const intl = useIntl();
    const validScales = ['serving', 'cup', 'tbsp', 'tsp', 'ml', 'Gram', 'Ounce', 'handful', 'Poignée', 'dash']

    try {
        amount = amount || ''
        size = size || ''
        scale = scale || ''
        const scaleLower = scale ? scale.toLowerCase() : ''
        const scaleTranslated = validScales.includes(scaleLower) ? intl.formatMessage({ id: `app.scales.${scaleLower}`, defaultMessage: scale || ' ' }) : scale
        return `${amount}${size} ${scaleTranslated}`
    }
    catch (error) {
        return `${amount}${size} ${scale}`
    }
}