import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { useSFLApi } from 'api';
import { toast } from 'react-toastify';
import MobileNavbar from 'v2/components/MobileNavbar';
import DateCycle from "sfl-components/DateCycle";
import moment from 'moment';
import { buildQuerystring } from 'misc';
import BottleIcon from 'v2/assets/svgIcons/Bottle'
import EditIcon from 'assets/svgIcons/Edit'
import DeleteIcon from 'assets/svgIcons/Delete'


export default function WaterLogs() {
    const [waterLogs, setWaterLogs] = useState([])
    const intl = useIntl()
    const [searchParams] = useSearchParams()
    const { memberUuid } = useParams()
    const navigate = useNavigate()
    const { get, del } = useSFLApi()
    const date = searchParams.has('date') ? searchParams.get('date') : moment().format('YYYY-MM-DD')


    const fetchData = useCallback(async (date) => {
        try {
            const queryString = buildQuerystring({ date: date, member_uuid: memberUuid });
            const results = await get(`waterLogs?${queryString}`)
            setWaterLogs(results.waterLogs)
        }
        catch (error) {
            console.log('error', error)
        }
    }, [get, buildQuerystring, setWaterLogs])


    useEffect(() => {
        fetchData(date)
    }, [date])


    const selectDate = (d) => {
        navigate(`?date=${d}`, { replace: true });
    }

    const handleDelete = async (uuid) => {
        try {
            await del(`waterLogs/${uuid}`)
            toast.success(intl.formatMessage({ id: "waterRemoved", defaultMessage: "Water Removed" }));
        }
        catch (error) {
            console.log('error', error)
            toast.error('Action Failed!');
        }
        fetchData(date)
    }

    return <>
        <MobileNavbar title={<FormattedMessage id="waterTracker" defaultMessage="Water Tracker" />} />
        <DateCycle date={date} onChange={selectDate} />
        <div
            className='greenButton'
            style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}
            onClick={() => { navigate(`edit?date=${date}`) }}
        >
            <div style={{ fontWeight: 700 }}>+ <FormattedMessage id="addWater" defaultMessage="Add Water" /></div>
        </div>
        {waterLogs.length == 0
            ? <div style={{ display: 'flex', paddingTop: '150px' }}>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <BottleIcon size='300' color='#cccccc' />
                </div>
            </div>
            : waterLogs.map(log => <div key={log.uuid} className='shadowBox' style={{ padding: '20px', display: 'flex', gap: '20px', alignItems: 'center', marginTop: '20px' }}>
                <div style={{ flex: 0 }}><BottleIcon size='40' color='#000000' /></div>
                <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 700 }}>{log.amount} <FormattedMessage id={`v.scale.${log.scale}`} values={{ value: log.amount }} defaultMessage={log.scale} /></div>
                </div>
                <div style={{ flex: 0, color: '#aaaaaa' }}>
                    <span className='hoverGreen' style={{ padding: '10px', cursor: 'pointer' }} onClick={() => { navigate(`edit/${log.uuid}?date=${date}`) }}>
                        <EditIcon size={20} color="currentcolor" />
                    </span>
                </div>
                <div style={{ flex: 0, color: '#aaaaaa' }}>
                    <span className='hoverRed' style={{ padding: '10px', cursor: 'pointer' }} onClick={() => { handleDelete(log.uuid) }}>
                        <DeleteIcon size={20} color="currentcolor" />
                    </span>
                </div>
            </div>
            )}
    </>
}
