import React, { useState } from "react";
import { useSFLApi } from 'api';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { FormattedMessage } from 'react-intl';
import Files from 'v2/assets/svgIcons/Files'

export default function DownloadRecipeButton({ uuid }) {
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useSFLApi();

    const getPDF = async () => {
        setIsLoading(true)
        try {
            const printResults = await get(`recipes/${uuid}/print2`)
            if (!printResults) {
                throw 'API file download error!';
            }
            window.open(printResults.download.path)
        }
        catch (error) {
            console.log('error', error)
            toast.error('Download Failed')
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <div className='greenButton'>
            <Files color="currentColor" size={20} style={{ marginRight: '5px' }} />
            <FormattedMessage id="printPdf" defaultMessage="Print PDF" />
        </div>
    } else {
        return <div className='greenButton' onClick={getPDF}>
            <Files color="currentColor" size={20} style={{ marginRight: '5px' }} />
            <FormattedMessage id="printPdf" defaultMessage="Print PDF" />
        </div>
    }
}