import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ClickAwayListener, Grow, Paper, Popper, Button, ButtonGroup, MenuItem, MenuList } from '@material-ui/core';
import GridItem from "components/Grid/GridItem.js";

const Pagination = (props) => {
	const { currentPage, totalPages, setResultsPerPage, firstPage, previousPage, nextPage, lastPage } = props;

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);



	const handleToggle = () => {
		setOpen(!open);
	};

	const handleResultsPerPage = (resultsPerPage) => {
		setOpen(false);
		setResultsPerPage(resultsPerPage);
	};

	return (<GridItem xs={12} sm={12} md={12} container justifyContent="center" style={{ marginTop: '30px' }}>
		<ButtonGroup
			color="primary"
			ref={anchorRef}
			size='small'
		>
			<Button onClick={firstPage} style={{ background: 'transparent', borderColor: 'transparent', color: 'rgb(110, 48, 118)' }}>
				<FontAwesomeIcon icon={faAngleDoubleLeft} fixedWidth />
			</Button>
			<Button onClick={previousPage} style={{ background: 'transparent', borderColor: 'transparent', color: 'rgb(110, 48, 118)' }}>
				<FontAwesomeIcon icon={faAngleLeft} fixedWidth />
			</Button>
			<Button onClick={handleToggle} style={{ textTransform: 'none', background: 'transparent', borderColor: 'transparent', color: 'rgb(110, 48, 118)' }}>
				Page {currentPage} of {totalPages}
			</Button>
			<Button onClick={nextPage} style={{ background: 'transparent', borderColor: 'transparent', color: 'rgb(110, 48, 118)' }}>
				<FontAwesomeIcon icon={faAngleRight} fixedWidth />
			</Button>
			<Button onClick={lastPage} style={{ background: 'transparent', borderColor: 'transparent', color: 'rgb(110, 48, 118)' }}>
				<FontAwesomeIcon icon={faAngleDoubleRight} fixedWidth />
			</Button>
		</ButtonGroup>
		<Popper open={open} anchorEl={anchorRef.current} transition placement='bottom-end' style={{ zIndex: 10 }} disablePortal>
			<Grow in={true} style={{ transformOrigin: 'top' }}>
				<Paper>
					<ClickAwayListener onClickAway={handleToggle}>
						<MenuList>
							<MenuItem onClick={() => handleResultsPerPage(10)} key='page_10'>10 Per Page</MenuItem>
							<MenuItem onClick={() => handleResultsPerPage(25)} key='page_25'>25 Per Page</MenuItem>
							<MenuItem onClick={() => handleResultsPerPage(100)} key='page_100'>100 Per Page</MenuItem>
							<MenuItem onClick={() => handleResultsPerPage(500)} key='page_500'>500 Per Page</MenuItem>
						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Grow>
		</Popper>
	</GridItem>);
}

export default Pagination;