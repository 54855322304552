import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';
import TextField from 'sfl-components/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { CardTitle } from 'sfl-components/Containers2';
import MenuItem from '@material-ui/core/MenuItem';

import { useSFLApi } from 'api';

import Title from 'sfl-components/Title';
import bowlImg from 'assets/images/bowls/Bal_Leaves_circle_dishes_400px.png';
import { FormattedMessage, useIntl } from 'react-intl';


export default function MemberForm() {
	const [state, setState] = useState({});
	const [formComplete, setFormComplete] = useState(false);
	const { get, post } = useSFLApi();
	const intl = useIntl();

	const { username = '', password = '', language = 'en', appointment_reminder_sms = 'on' } = state;

	useEffect(() => {
		get('profile')
			.then(results => setState(results.profile))
			.catch(error => {
				console.log(error)
				toast.error(intl.formatMessage({ id: "profile.loadFail", defaultMessage: 'Error Loading Profile' }))
			})
	}, [get, intl]);

	const handleChange = (field, value) => {
		setState(currentState => ({ ...currentState, [field]: value }))
	}

	const handleSave = () => {
		post('profile', state)
			.then(() => {
				setFormComplete(true)
				toast.success(intl.formatMessage({ id: "profile.profileSaved", defaultMessage: 'Profile Saved' }));
			})
			.catch(error => {
				console.log(error)
				toast.error(intl.formatMessage({ id: "profile.saveFail", defaultMessage: 'Error Saving Profile' }))
			})
	}


	if (formComplete) {
		return [
			<div key='title' style={{ display: 'flex', marginBottom: '40px' }}>
				<Title>
					<FormattedMessage id="profile.title" defaultMessage="Account Profile" />
				</Title>
			</div>,
			<div key='content' style={{ textAlign: 'center' }} onClick={() => { setFormComplete(false) }}>
				<div style={{ fontSize: '32px', marginTop: '180px', marginBottom: '40px' }}>
					<i><FormattedMessage id="profile.yourProfileHasBeenUpdated" defaultMessage="Your profile has been updated!" /></i>
				</div>
				<img src={bowlImg} alt='' style={{ maxWidth: '300px' }} />
			</div >
		]
	}

	return [
		<div key='title' style={{ display: 'flex', marginBottom: '40px' }}>
			<Title>
				<FormattedMessage id="profile.title" defaultMessage="Account Profile" />
			</Title>
			<CardButton onClick={handleSave}>
				<FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
				<FormattedMessage id="profile.saveProfile" defaultMessage="Save Profile" />
			</CardButton>
		</div>,
		<Card key='content'>
			<Grid container spacing={3}>
				<Grid item xs={12} style={{ paddingLeft: '32px', paddingRight: '32px', marginTop: '40px' }}>
					<CardTitle>
						<FormattedMessage id="loginInformation" defaultMessage="Login Information" />
					</CardTitle>
				</Grid>
				<Grid item xs={6} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
					<TextField
						id="username"
						label={intl.formatMessage({ id: "app.username", defaultMessage: "Username" })}
						value={username}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={6} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
					<TextField
						id="password"
						label={intl.formatMessage({ id: "app.password", defaultMessage: "Password" })}
						type="password"
						value={password}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} style={{ paddingLeft: '32px', paddingRight: '32px', marginTop: '40px' }}>
					<CardTitle>
						<FormattedMessage id="preferences" defaultMessage="Preferences" />
					</CardTitle>
				</Grid>
				<Grid item xs={6} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
					<TextField
						id="language"
						select
						label={intl.formatMessage({ id: "app.preferredLanguage", defaultMessage: "Preferred Language" })}
						value={language || 'en'}
						onChange={handleChange}
					>
						<MenuItem key='en' value='en'><FormattedMessage id="app.english" defaultMessage="English" /></MenuItem>
						<MenuItem key='fr' value='fr'><FormattedMessage id="app.french" defaultMessage="French" /></MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={6} style={{ paddingLeft: '32px', paddingRight: '32px' }}>
					<TextField
						id="appointment_reminder_sms"
						select
						label={intl.formatMessage({ id: "app.appointmentReminderSMS", defaultMessage: "Appointment Reminder SMS" })}
						value={appointment_reminder_sms || 'on'}
						onChange={handleChange}
					>
						<MenuItem key='on' value='on'><FormattedMessage id="app.on" defaultMessage="On" /></MenuItem>
						<MenuItem key='off' value='off'><FormattedMessage id="app.off" defaultMessage="Off" /></MenuItem>
					</TextField>
				</Grid>
			</Grid>
		</Card>
	]
}