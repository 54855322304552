import * as React from "react"

export default function DairySvg({ size, color = "#aaaaaa", style }) {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 470 470`}
        width={size}
        height={size}
        style={style}
    >
        <path fill={color} d="m332.026,155.641l-45.386-89.926v-20.715h2.5c4.142,0 7.5-3.357 7.5-7.5v-30c0-4.143-3.358-7.5-7.5-7.5h-108.28c-4.142,0-7.5,3.357-7.5,7.5v30c0,4.143 3.358,7.5 7.5,7.5h2.5v20.715l-45.386,89.926c-5.504,10.905-9.816,29.017-9.816,41.233v245.626c0,15.163 12.336,27.5 27.5,27.5h158.683c15.164,0 27.5-12.337 27.5-27.5v-245.626c0-12.217-4.311-30.328-9.815-41.233zm-143.666-140.641h93.279v15h-93.279v-15zm9.196,55.879c0.529-1.048 0.804-2.205 0.804-3.379v-22.5h73.279v22.5c0,1.174 0.275,2.331 0.804,3.379l28.543,56.553c-5.411,1.754-9.204,4.583-12.487,7.037-4.452,3.329-7.669,5.733-14.841,5.733s-10.389-2.404-14.841-5.733c-5.196-3.884-11.663-8.719-23.823-8.719-12.159,0-18.625,4.835-23.821,8.72-4.451,3.328-7.667,5.732-14.838,5.732s-10.387-2.404-14.838-5.732c-3.282-2.454-7.075-5.283-12.485-7.037l28.544-56.554zm129.285,371.621c0,6.893-5.607,12.5-12.5,12.5h-158.682c-6.893,0-12.5-5.607-12.5-12.5v-245.626c0-9.874 3.758-25.661 8.207-34.476l10.736-21.271c4.305,0.797 6.999,2.802 10.415,5.355 5.195,3.885 11.662,8.72 23.82,8.72 12.158,0 18.625-4.835 23.82-8.72 4.451-3.328 7.667-5.732 14.838-5.732 7.172,0 10.389,2.404 14.841,5.733 5.196,3.885 11.663,8.719 23.822,8.719 12.16,0 18.626-4.835 23.823-8.719 3.416-2.554 6.111-4.559 10.417-5.356l10.736,21.272c4.448,8.813 8.207,24.601 8.207,34.475v245.626z" />
        <path fill={color} d="m165.659,395c-4.142,0-7.5,3.357-7.5,7.5v30c0,4.143 3.358,7.5 7.5,7.5s7.5-3.357 7.5-7.5v-30c0-4.143-3.358-7.5-7.5-7.5z" />
        <path fill={color} d="m174.832,165.842c-3.698-1.866-8.208-0.382-10.075,3.315-3.33,6.598-6.598,20.326-6.598,27.717v175.626c0,4.143 3.358,7.5 7.5,7.5s7.5-3.357 7.5-7.5v-175.626c0-5.071 2.704-16.431 4.989-20.957 1.866-3.698 0.381-8.209-3.316-10.075z" />
        <path fill={color} d="m220.86,75h28.279c4.142,0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-28.279c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5 7.5,7.5z" />
    </svg>
}