import React from "react";
import { FormattedMessage } from 'react-intl';

import CircleCheck from 'v2/assets/svgIcons/CircleCheck'
import CircleDash from 'v2/assets/svgIcons/CircleDash'


const Option = ({ title, selected, onChange }) => {
    return <div className='hoverUnderline' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderBottom: '0px solid #dddddd', padding: '15px 10px' }} onClick={onChange}>
        <div style={{ minWidth: '50px', display: 'flex', alignItems: 'center' }}>
            {selected ?
                <CircleCheck
                    size={30}
                    color={"#17B169"}
                />
                : <CircleDash
                    size={30}
                    color={"#cccccc"}
                />
            }
        </div>
        <div style={{ flexGrow: 1, fontWeight: selected ? 700 : 400 }}>{title}</div>
    </div>
}

export default function RecipeCategories({ categories, onCategoryChange, tags, onTagChange, onSearch }) {

    const handleCategoryChange = (categoryUuid) => {
        onCategoryChange(currentCategories => {
            const index = currentCategories.findIndex(e => e.uuid === categoryUuid)
            currentCategories[index].selected = !currentCategories[index].selected
            return [...currentCategories]
        })
    }

    const handleTagChange = (tagName) => {
        onTagChange(currentTags => {
            const index = currentTags.findIndex(e => e.name === tagName)
            currentTags[index].selected = !currentTags[index].selected
            return [...currentTags]
        })
    }

    return <>
        <div className='shadowBox' style={{ padding: '20px' }}>
            <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '20px' }}><FormattedMessage id="app.recipeCategories" defaultMessage="Recipe Categories" /></div>
            <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '0px 40px' }} >
                {categories.map(e => {
                    return <Option key={e.uuid} title={e.name} selected={e.selected} onChange={() => { handleCategoryChange(e.uuid) }} />
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', marginTop: '20px' }}>
                <div className='greenButton' onClick={onSearch}><FormattedMessage id="app.startSearch" defaultMessage="Start Search" /></div>
            </div>
        </div>
        {/*<div className='shadowBox' style={{ padding: '20px', marginTop: '20px' }}>
            <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '20px' }}>Preferences</div>
            <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '0px 40px' }} >
                {tags.map(e => {
                    return <Option key={e.name} title={e.title} selected={e.selected} onChange={() => { handleTagChange(e.name) }} />
                })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', marginTop: '20px' }}>
                <div className='greenButton' onClick={onSearch}>Start Search</div>
            </div>
            </div>*/}
    </>
}
