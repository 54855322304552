import React from 'react';
import TitleBar from 'sfl-components/TitleBar';
import { ViewportContext } from 'sfl-components/ViewportProvider';
import { useLocation } from "react-router-dom";


export default function MainPanel(props) {
    const { width } = React.useContext(ViewportContext);
    const location = useLocation();

    const pathArr = location.pathname.split('/')

    let bgColor = location.pathname.substring(1, 5) === 'beta' ? '#eeeeee' : 'white'
    const newStylePages = ['','member-profile','dashboard','recipe-categories', 'recipe', 'feedback', 'activity-log', 'water-log','summary','member-activity-summary']
    if (newStylePages.includes(pathArr[1]) || newStylePages.includes(pathArr[3])) {
        bgColor = '#eeeeee'
    }

    if (width < 800) {
        return <div style={{ paddingTop: '60px', minHeight: '100vh', boxSizing: 'border-box', backgroundColor: '#eeeeee', color: '#111111' }}>
            {props.children}
        </div>
    }

    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundImage: 'linear-gradient(90deg, #b4bb00, 50%, #e1e566)'
        }}
    >
        <div style={{ flexGrow: '0', marginRight: '50px' }}>
            <TitleBar />
        </div>
        <div style={{
            flexGrow: '1',
            marginRight: '50px',
            padding: '60px 40px 40px 40px',
            backgroundColor: bgColor,
            borderRadius: '25px 25px 0 0'
        }}>
            {props.children}
        </div>
    </div>

    /*
        return <ScrollContext.Provider >
            <PerfectScrollbar
                containerRef={(ref) => { scrollRef.current = ref; }}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                    minHeight: '100vh',
                    backgroundImage: 'linear-gradient(90deg, #b4bb00, 50%, #e1e566)'
                }}
            >
                <div style={{ flexGrow: '0', marginRight: '50px' }}>
                    <TitleBar />
                </div>
                <div style={{
                    flexGrow: '1',
                    marginRight: '50px',
                    padding: '60px 40px 40px 40px',
                    backgroundColor: bgColor,
                    borderRadius: '25px 25px 0 0'
                }}>
                    {props.children}
                </div>
            </PerfectScrollbar>
        </ScrollContext.Provider>
        */
}