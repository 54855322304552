import React, { useState } from "react";
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import CardButton from 'sfl-components/SFLCards/CardButton';
import { useSelector } from 'react-redux'
import { getDateFormat } from "misc";

import DatePickerPopover from 'sfl-components/DatePickerPopover';

export default function Cycle(props) {
    const { date, onChange, mode } = props;
    const [showDatePicker, setShowDatePicker] = useState(null);
    const { locale = 'en-ca' } = useSelector(state => state.appSettings);
    const dateFormat = getDateFormat(locale)

    const dateObj = date ? moment(date) : moment();
    const dateDisplay = dateObj.locale(locale).format(dateFormat)

    const previousDate = () => {
        onChange(dateObj.subtract(1, 'days').format('YYYY-MM-DD'));
    }

    const nextDate = () => {
        onChange(dateObj.add(1, 'days').format('YYYY-MM-DD'));
    }

    const openDatePicker = (event) => {
        setShowDatePicker(event.currentTarget);
    };

    const closeDatePicker = () => {
        setShowDatePicker(null);
    };

    const selectDate = (d) => {
        onChange(d.format('YYYY-MM-DD'));
        closeDatePicker();
    }

    if (mode === 'bubble') {
        return <div style={{ display: 'flex' }}>
            <DatePickerPopover
                open={Boolean(showDatePicker)}
                anchorEl={showDatePicker}
                onClose={closeDatePicker}
                onDateChange={selectDate}
                date={dateObj}
            />
            <CardButton style={{ marginLeft: '0', minWidth: 'auto', height: '40px', borderRadius: '25px 0 0 25px', cursor: 'pointer' }} onClick={previousDate}>
                <FontAwesomeIcon icon={['fa', 'chevron-left']} />
            </CardButton>
            <CardButton style={{ marginLeft: '0', height: '40px', borderRadius: '0' }} onClick={openDatePicker}>
                <FontAwesomeIcon icon={['fa', 'calendar-alt']} fixedWidth />{dateDisplay}
            </CardButton>
            <CardButton style={{ marginLeft: '0', minWidth: 'auto', height: '40px', borderRadius: '0 25px 25px 0', cursor: 'pointer' }} onClick={nextDate}><FontAwesomeIcon icon={['fa', 'chevron-right']} fixedWidth /></CardButton>
        </div>
    }

    return <Paper elevation={3}>
        <DatePickerPopover
            open={Boolean(showDatePicker)}
            anchorEl={showDatePicker}
            onClose={closeDatePicker}
            onDateChange={selectDate}
            date={dateObj}
        />
        <div style={{ display: 'flex', color: '#444444', alignItems: 'center', fontSize: '16px' }}>
            <div style={{ display: 'flex', padding: '10px', cursor: 'pointer' }} onClick={previousDate}>
                <FontAwesomeIcon icon={['fa', 'chevron-left']} fixedWidth />
            </div>
            <div style={{ display: 'flex', padding: '10px', flexGrow: '1', justifyContent: 'center', cursor: 'pointer'  }} onClick={openDatePicker}>{dateDisplay}</div>
            <div style={{ display: 'flex', padding: '10px', cursor: 'pointer' }} onClick={nextDate}>
                <FontAwesomeIcon icon={['fa', 'chevron-right']} fixedWidth />
            </div>
        </div>
    </Paper>



}