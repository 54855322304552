import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSFLApi } from 'api';

import Card from './components/Card'
import Logo from 'v2/assets/images/logo.svg'
import Questionnaire from './Questionnaire'

export default function ValidateToken() {
    const [tokenStatus, setTokenStatus] = useState('pending')
    const [locationEmail, setLocationEmail] = useState('')
    const { tokenUuid } = useParams()
    const { get } = useSFLApi()


    useEffect(() => {
        const fetchData = async () => {
            try {
                const { token } = await get(`tokens/${tokenUuid}`)
                if (token.status === 'active' && token.type === 'questionnaire') {
                    setTokenStatus('active')
                    setLocationEmail(token.location_email)
                } else {
                    setTokenStatus('error')
                    setLocationEmail('')
                }
            }
            catch (error) {
                console.log(error)
                setTokenStatus('error')
            }
        }

        fetchData()
    }, [setTokenStatus, tokenUuid])


    if (tokenStatus === 'active') {
        return <Questionnaire locationEmail={locationEmail} />

    } else if (tokenStatus === 'error' || tokenStatus === 'inactive') {
        return <Card>
            <div style={{ marginBottom: '50px', textAlign: 'center' }}>
                <img src={Logo} style={{ width: '100%', maxWidth: '400px' }} />
            </div>
            <div style={{ marginBottom: '20px' }}>
                <div style={{ marginBottom: '20px' }}>
                    Unfortuantely, this questionnaire is no longer accessible. It has either expired or it has been previously submitted.
                </div>
            </div>
        </Card>

    } else {
        return <Card>
            <div style={{ marginBottom: '50px', textAlign: 'center' }}>
                <img src={Logo} style={{ width: '100%', maxWidth: '400px' }} />
            </div>
            <div style={{ marginBottom: '20px' }}>
                Validating request...
            </div>
        </Card>
    }
}