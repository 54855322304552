import React, { useState } from "react";
import TextField from 'sfl-components/TextField/Outlined';
import Card from '../components/Card'
import CardTitle from '../components/CardTitle'
import Tabs from 'v2/components/Tabs'
import Tab from 'v2/components/Tab'
import Error from '../components/Error'
import DateSelect from 'v2/components/DateSelect';
import { FormattedMessage, useIntl } from 'react-intl';

export default function QuestionnaireProfile({ data, onChange, onNext, onPrevious }) {
    const [showError, setShowError] = useState(false)
    const intl = useIntl()
    const { date_of_birth = "", height_feet = "", height_inches = "", current_weight = "", goal_weight = "", gender = "", goal_lose_weight = false, average_week_meals = "", eat_out_frequency = "", supplements = "" } = data


    const handleNumericChange = (key, val) => {
        onChange(key, val.replace(/[^0-9]/g, ''))
    }

    const handleNext = () => {
        let formValid = ![date_of_birth, height_feet, height_inches, current_weight, gender, average_week_meals, eat_out_frequency].includes("")

        if (goal_lose_weight && goal_weight === '') {
            formValid = false
        }

        if (!formValid) {
            setShowError(true)
            return
        }

        onNext()
    }

    let dobError = false
    let heightError = false
    let genderError = false
    let currentWeightError = false
    let goalWeightError = false
    let eatOutError = false
    let mealsError = false

    if (showError) {
        if (date_of_birth === '') {
            dobError = true
        }

        if (height_feet === '' || height_inches === '') {
            heightError = true
        }

        if (gender === '') {
            genderError = true
        }

        if (current_weight === '') {
            currentWeightError = true
        }

        if (goal_weight === '') {
            goalWeightError = true
        }

        if (eat_out_frequency === '') {
            eatOutError = true
        }

        if (average_week_meals === '') {
            mealsError = true
        }
    }

    return <Card
        onNext={handleNext}
        onPrevious={onPrevious}
        error={showError && <Error onClick={() => { setShowError(false) }}>
            <FormattedMessage id="questionnaireV2.completeTheFormError" defaultMessage="Please complete the form to continue." />
        </Error>}
    >
        <CardTitle
            title="Tell us a little bit about yourself."
            description="This helps us create meal plans for your specific needs."
        />
        <div style={{ display: 'flex', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee', flexWrap: 'wrap' }}>
            <div style={{ flex: 1, fontWeight: 600, display: 'flex', alignItems: 'center', color: dobError ? '#c96464' : null }}>
                <FormattedMessage id="questionnaireV2.dateOfBirth" defaultMessage="Date of Birth" />
            </div>
            <div style={{ flex: 0, width: '300px' }}>
                <DateSelect
                    id="date_of_birth"
                    value={date_of_birth}
                    variant="outlined"
                    inputProps={{ style: { padding: 10 }, autoComplete: "off" }}
                    onChange={(newDob) => { onChange('date_of_birth', newDob) }}
                />
            </div>
        </div>
        <div style={{ display: 'flex', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee' }}>
            <div style={{ flex: 1, fontWeight: 600, display: 'flex', alignItems: 'center', color: genderError ? '#c96464' : null }}>
                <FormattedMessage id="questionnaireV2.sex" defaultMessage="Sex" />
            </div>
            <div style={{ flex: 1 }}>
                <Tabs>
                    <Tab
                        name={<FormattedMessage id="questionnaireV2.female" defaultMessage="Female" />}
                        active={gender === 'female'}
                        onClick={() => { onChange('gender', 'female') }}
                    />
                    <Tab
                        name={<FormattedMessage id="questionnaireV2.male" defaultMessage="Male" />}
                        active={gender === 'male'}
                        onClick={() => { onChange('gender', 'male') }}
                    />
                    <Tab
                        name={<FormattedMessage id="questionnaireV2.intersex" defaultMessage="Intersex" />}
                        active={gender === 'other'}
                        onClick={() => { onChange('gender', 'other') }}
                    />
                </Tabs>
            </div>
        </div>
        <div style={{ display: 'flex', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee', alignItems: 'stretch' }}>
            <div style={{ flex: 1, fontWeight: 600, display: 'flex', alignItems: 'center', color: heightError ? '#c96464' : null }}>
                <FormattedMessage id="questionnaireV2.height" defaultMessage="Height" />
            </div>
            <div style={{ width: '50px' }}>
                <TextField
                    id="height_feet"
                    type="number"
                    value={height_feet}
                    onChange={handleNumericChange}
                    inputProps={{ style: { padding: 10 } }}
                />
            </div>
            <div style={{ marginRight: '10px', border: '1px solid #17B169', backgroundColor: '#17B169', padding: '5px 10px', textAlign: 'center', color: 'white', fontWeight: 600, display: 'flex', alignItems: 'center' }}><FormattedMessage id="questionnaireV2.feet.abbr" defaultMessage="Ft" /></div>
            <div style={{ width: '50px' }}>
                <TextField
                    id="height_inches"
                    type="number"
                    value={height_inches}
                    onChange={handleNumericChange}
                    inputProps={{ style: { padding: 10 } }}
                />
            </div>
            <div style={{ border: '1px solid #17B169', backgroundColor: '#17B169', padding: '5px 10px', textAlign: 'center', color: 'white', fontWeight: 600 }}><FormattedMessage id="questionnaireV2.inches.abbr" defaultMessage="In" /></div>
        </div>
        <div style={{ display: 'flex', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee' }}>
            <div style={{ flex: 1, fontWeight: 600, display: 'flex', alignItems: 'center', color: currentWeightError ? '#c96464' : null }}>
                <FormattedMessage id="questionnaireV2.currentWeight" defaultMessage="Current Weight" />
            </div>
            <div style={{ width: '100px' }}>
                <TextField
                    id="current_weight"
                    type="number"
                    value={current_weight}
                    onChange={handleNumericChange}
                    inputProps={{ style: { padding: 10 } }}
                />
            </div>
            <div style={{ border: '1px solid #17B169', backgroundColor: '#17B169', padding: '5px 10px', textAlign: 'center', color: 'white', fontWeight: 600 }}><FormattedMessage id="lb" defaultMessage="lb" /></div>
        </div>
        {goal_lose_weight && <div style={{ display: 'flex', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee' }}>
            <div style={{ flex: 1, fontWeight: 600, display: 'flex', alignItems: 'center', color: goalWeightError ? '#c96464' : null }}>
                <FormattedMessage id="questionnaireV2.goalWeight" defaultMessage="Goal Weight" />
            </div>
            <div style={{ width: '100px' }}>
                <TextField
                    id="goal_weight"
                    type="number"
                    value={goal_weight}
                    onChange={handleNumericChange}
                    inputProps={{ style: { padding: 10 } }}
                />
            </div>
            <div style={{ border: '1px solid #17B169', backgroundColor: '#17B169', padding: '5px 10px', textAlign: 'center', color: 'white', fontWeight: 600 }}><FormattedMessage id="lb" defaultMessage="lb" /></div>
        </div>}
        <div style={{ display: 'flex', marginBottom: '20px', borderBottom: '1px solid #eeeeee', flexWrap: 'wrap' }}>
            <div style={{ paddingBottom: '20px', flex: 1, fontWeight: 600, display: 'flex', whiteSpace: 'nowrap', alignItems: 'center', color: eatOutError ? '#c96464' : null }}>
                <FormattedMessage id="questionnaireV2.howOftenDoYouEatOut" defaultMessage="How often do you eat out?" />
            </div>
            <div style={{ paddingBottom: '20px', width: '150px', display: 'flex', whiteSpace: 'nowrap', marginLeft: 'auto' }}>
                <div>
                    <TextField
                        id="eat_out_frequency"
                        type="number"
                        value={eat_out_frequency}
                        onChange={handleNumericChange}
                        inputProps={{ style: { padding: 10 } }}
                    />
                </div>
                <div style={{ border: '1px solid #17B169', backgroundColor: '#17B169', padding: '5px 10px', textAlign: 'center', color: 'white', fontWeight: 600 }}><FormattedMessage id="questionnaireV2.perWeek" defaultMessage="Per Week" /></div>
            </div>

        </div>
        <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee' }}>
            <div style={{ paddingBottom: '20px' }}>
                <div style={{ fontWeight: 600, color: mealsError ? '#c96464' : null }}>
                    <FormattedMessage id="questionnaireV2.whatDoYouNormallyEat" defaultMessage="What do you normally eat?" />
                </div>
            </div>
            <div>
                <TextField
                    multiline
                    id="average_week_meals"
                    placeholder={intl.formatMessage({ id: "questionnaireV2.mealsPlaceholder", defaultMessage: "Ex: Meals, snacks, quantities." })}
                    value={average_week_meals}
                    minRows={2}
                    onChange={onChange}
                />
            </div>
        </div>
        <div style={{ paddingBottom: '20px' }}>
            <div style={{ fontWeight: 600 }}>
                <FormattedMessage id="questionnaireV2.whatSupplementsDoYouTake" defaultMessage="What supplements do you currently take?" />
            </div>
        </div>
        <div>
            <TextField
                multiline
                id="supplements"
                value={supplements}
                minRows={2}
                onChange={onChange}
            />
        </div>
    </Card>
}
