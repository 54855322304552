import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        width={size}
        viewBox="0 0 810 810"
    >
        <path fill={color} d="M 624.902344 557.035156 L 508.464844 633.199219 C 492.839844 642.023438 487.066406 633.398438 493.472656 625.703125 L 504.671875 612.300781 C 513.199219 602.097656 510.925781 599.25 498.753906 596.277344 L 425.742188 578.683594 C 407.996094 574.335938 399.222656 576.070312 382.667969 589.367188 L 326.605469 634.28125 C 307.621094 652.328125 290.476562 654.296875 265.933594 654.296875 L 217.019531 654.296875 C 199.679688 654.296875 198.242188 655.480469 186.847656 668.542969 L 163.515625 695.207031 C 152.089844 708.265625 159.871094 726.5625 177.230469 726.5625 L 618.980469 726.5625 C 645.300781 726.5625 657.679688 713.246094 657.679688 691.269531 L 657.679688 572.472656 C 657.679688 549.539062 640.039062 547.183594 624.902344 557.035156 Z M 624.902344 557.035156 " fillOpacity="1" fillRule="nonzero" />
        <path fill={color} d="M 441.367188 182.875 C 424.113281 210.921875 387.445312 219.648438 359.390625 202.429688 C 331.394531 185.234375 322.667969 148.566406 339.867188 120.597656 C 357.113281 92.550781 393.75 83.769531 421.738281 100.976562 C 449.898438 118.203125 458.566406 154.878906 441.367188 182.875 Z M 441.367188 182.875 " fillOpacity="1" fillRule="evenodd" />
        <path fill={color} d="M 342.089844 233.941406 C 328.972656 233.941406 317.445312 242.617188 313.855469 255.1875 L 274.558594 393.179688 C 268.691406 413.773438 273.960938 424.726562 290.921875 430.335938 L 382.183594 460.652344 L 396.875 532.484375 C 398 573.191406 462.484375 568.714844 455.472656 528.585938 L 439.457031 436.933594 C 437.382812 425.113281 430.625 414.617188 419.191406 410.914062 L 359.25 391.539062 L 367.484375 364.566406 L 385.335938 306.488281 L 421.113281 346.675781 C 425.847656 352.003906 432.699219 355.054688 439.890625 355.054688 L 503.230469 355.054688 L 503.230469 562.839844 C 503.734375 587.199219 540.011719 587.199219 540.503906 562.839844 L 540.503906 258.539062 C 540.65625 248.089844 532.0625 239.5625 521.566406 239.695312 C 511.316406 239.84375 503.136719 248.277344 503.222656 258.539062 L 503.222656 304.964844 L 451.011719 304.964844 L 395.453125 242.5625 C 390.390625 236.890625 383.082031 233.589844 375.445312 233.929688 L 342.089844 233.929688 Z M 342.089844 233.941406 " fillOpacity="1" fillRule="nonzero" />
        <path fill={color} d="M 281.257812 445.234375 L 235.210938 595.433594 C 221.941406 633.835938 280.851562 651.882812 291.359375 612.691406 L 337.054688 463.816406 Z M 281.257812 445.234375 " fillOpacity="1" fillRule="nonzero" />
        <path fill={color} d="M 223.675781 203.414062 C 205.332031 201.996094 187.980469 213.582031 182.710938 232.007812 C 177.53125 250.105469 185.8125 268.683594 201.546875 277.363281 L 179.398438 354.808594 C 175.695312 367.71875 181.914062 380.730469 193.359375 383.988281 L 231.40625 394.871094 C 242.871094 398.136719 255.03125 390.394531 258.734375 377.523438 L 295.65625 248.363281 C 299.355469 235.492188 293.136719 222.488281 281.703125 219.175781 L 231.605469 204.84375 C 228.945312 204.117188 226.332031 203.671875 223.675781 203.414062 Z M 223.675781 203.414062 " fillOpacity="1" fillRule="evenodd" />
    </svg>
}

export default SvgComponent