import React, { useState } from "react";
import TextField from 'sfl-components/TextField/Outlined';
import validateDate from 'validate-date';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntl } from 'react-intl';

export default function QuestionnaireProfile({ value = '', onChange }) {
    const dobArr = value.split('-').map(e => parseInt(e))
    const [day, setDay] = useState(dobArr[2])
    const [month, setMonth] = useState(dobArr[1])
    const [year, setYear] = useState(dobArr[0])
    const intl = useIntl()

    const yearOffset = new Date().getFullYear() - 100


    const handleDateChange = (year = '', month = '', day = '') => {
        try {
            day = day.toString().padStart(2, '0')
            month = month.toString().padStart(2, '0')

            const isValid = validateDate(`${year}-${month}-${day}`, "boolean")
            if (isValid) {
                onChange(`${year}-${month}-${day}`)
            } else {
                onChange('')
            }
        }
        catch (error) {
            onChange('')
        }
    }


    const handleChange = (key, val) => {
        if (key === 'day') {
            setDay(val)
            handleDateChange(year, month, val)
        } else if (key === 'month') {
            setMonth(val)
            handleDateChange(year, val, day)
        } if (key === 'year') {
            setYear(val)
            handleDateChange(val, month, day)
        }
    }

    return <div style={{ display: 'flex', gap: '5px' }}>
        <TextField
            id="month"
            type="number"
            value={month || ''}
            select
            size="small"
            helperText={intl.formatMessage({ id: "month", defaultMessage: "Month" })}
            onChange={handleChange}
        >
            <MenuItem key='1' value='1'>{intl.formatMessage({ id: "january", defaultMessage: "January" })}</MenuItem>
            <MenuItem key='2' value='2'>{intl.formatMessage({ id: "february", defaultMessage: "February" })}</MenuItem>
            <MenuItem key='3' value='3'>{intl.formatMessage({ id: "march", defaultMessage: "March" })}</MenuItem>
            <MenuItem key='4' value='4'>{intl.formatMessage({ id: "april", defaultMessage: "April" })}</MenuItem>
            <MenuItem key='5' value='5'>{intl.formatMessage({ id: "may", defaultMessage: "May" })}</MenuItem>
            <MenuItem key='6' value='6'>{intl.formatMessage({ id: "june", defaultMessage: "June" })}</MenuItem>
            <MenuItem key='7' value='7'>{intl.formatMessage({ id: "july", defaultMessage: "July" })}</MenuItem>
            <MenuItem key='8' value='8'>{intl.formatMessage({ id: "august", defaultMessage: "August" })}</MenuItem>
            <MenuItem key='9' value='9'>{intl.formatMessage({ id: "september", defaultMessage: "September" })}</MenuItem>
            <MenuItem key='10' value='10'>{intl.formatMessage({ id: "october", defaultMessage: "October" })}</MenuItem>
            <MenuItem key='11' value='11'>{intl.formatMessage({ id: "november", defaultMessage: "November" })}</MenuItem>
            <MenuItem key='12' value='12'>{intl.formatMessage({ id: "december", defaultMessage: "December" })}</MenuItem>
        </TextField>
        <TextField
            id="day"
            type="number"
            value={day || ''}
            select
            size="small"
            helperText={intl.formatMessage({ id: "day", defaultMessage: "Day" })}
            onChange={handleChange}
            inputProps={{ style: { padding: 10 } }}
        >
            {[...Array(31)].map((e, index) => <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>)}
        </TextField>
        <TextField
            id="year"
            type="number"
            value={year || ''}
            select
            size="small"
            helperText={intl.formatMessage({ id: "year", defaultMessage: "Year" })}
            onChange={handleChange}
            inputProps={{ style: { padding: 10 } }}
        >
            {[...Array(100)].map((e, index) => {
                const yr = 100 - index + yearOffset
                return <MenuItem key={yr} value={yr}>{yr}</MenuItem>
            })}
        </TextField>
    </div>
}
