import * as React from "react"
import { FormattedMessage, useIntl } from 'react-intl';
import TagBox from 'v2/components/Tags/TagBox'
import Rating from 'v2/components/Rating'
import ImageModal from 'v2/components/ImageModal'
import TimeTag from 'v2/components/Tags/Presets/TimeTag'
import VegetarianTag from 'v2/components/Tags/Presets/VegetarianTag'
import VeganTag from 'v2/components/Tags/Presets/VeganTag'
import SpicyTag from 'v2/components/Tags/Presets/SpicyTag'
import PescatarianTag from 'v2/components/Tags/Presets/PescatarianTag'
import GlutenFreeTag from 'v2/components/Tags/Presets/GlutenFreeTag'
import DairyFreeTag from 'v2/components/Tags/Presets/DairyFreeTag'

export default function RecipeCard({
    uuid,
    imageUrl,
    title = "",
    servings = 0,
    minutes,
    ingredientCount = 0,
    rating = 0,
    spicy,
    pescatarian,
    vegetarian,
    vegan,
    dairy_free,
    gluten_free,
    children
}) {
    const intl = useIntl();
    const image = imageUrl || 'https://app.simplyforlife.com/static/media/placeholder.bd74effd4b61ad3de8c2.jpg'

    return <div className='shadowBox' style={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap-reverse' }} >
        <div style={{ flex: 1, padding: '10px 0px 10px 10px', textAlign: 'center' }}>
            <ImageModal imageUrl={image}>
                <img
                    src={`${image}?width=300`}
                    alt={title}
                    style={{ height: '200px', borderRadius: '10px', width: '300px', objectFit: 'cover' }}
                />
            </ImageModal>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 10, padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ flex: 1, minWidth: '250px' }}>
                    <div style={{ fontSize: '20px', fontWeight: '700' }}>{title}</div>
                    <div style={{ fontWeight: 300, display: 'flex', alignItems: 'center' }}>
                        {ingredientCount} <FormattedMessage id="app.ingredients" defaultMessage="Ingredients" />{servings ? `, ${servings} ${intl.formatMessage({ id: 'app.servings', defaultMessage: 'Servings' })}` : null}
                    </div>
                    <div style={{ margin: '15px 0px' }}>
                        <Rating score={rating} />
                    </div>
                </div>
                <div style={{ flex: 0 }}>
                    {children}
                </div>
            </div>
            <div style={{ marginTop: 'auto' }}>
                <TagBox>
                    <TimeTag minutes={minutes} />
                    {vegan ? <VeganTag />
                        : vegetarian ? <VegetarianTag />
                            : pescatarian ? <PescatarianTag />
                                : null
                    }
                    {spicy && <SpicyTag />}
                    {dairy_free && <DairyFreeTag />}
                    {gluten_free && <GlutenFreeTag />}
                </TagBox>
            </div>
        </div>
    </div>
}