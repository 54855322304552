import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from 'react-intl';

import Recipe from 'v2/components/Recipe'
import RecipesIcon from 'v2/assets/svgIcons/Recipes'
import { useSFLApi } from 'api';


export default function Recipes({ search = '', favourites = false, categories = [], tags = [], onRecipeSelect }) {
    //console.log('render search results', search, favourites, categories, tags)
    const { get } = useSFLApi();
    const [recipes, setRecipes] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const fetchData = useCallback(async (searchTerm = '', favouritesOnly = false, categories = [], tags = []) => {
        try {
            //console.log('fetch', categories)
            const url = [`recipes?sort=name&limit=100&search=${searchTerm}`]

            const selectedCategories = categories.filter(e => e.selected).map(e => e.uuid)
            if (selectedCategories.length > 0) {
                url.push(`&recipe_categories=${selectedCategories.join(',')}`)
            }

            const selectedTags = tags.filter(e => e.selected).map(e => e.name)
            if (selectedTags.length > 0) {
                url.push(`&tags=${selectedTags.join(',')}`)
            }

            if (favouritesOnly) {
                url.push(`&favorite=true`)
            }

            const results = await get(url.join(''))

            //Filter recipes without names (missing translations probably)
            setRecipes(results.recipes.filter(e => e.name !== ''))
            setIsLoading(false)
        }
        catch (error) {
            console.log(error)
        }
    }, [get, setRecipes])

    useEffect(() => {
        if (isLoading) {
            fetchData(search, favourites, categories, tags)
        } else {
            const delayDebounceFn = setTimeout(() => {
                fetchData(search, favourites, categories, tags)
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }

    }, [fetchData, search, favourites, categories, tags])

    if (isLoading) {
        return null
    }


    //TODO: Kinda ugly
    if (recipes.length === 0) {
        return <div style={{ display: 'flex', paddingTop: '150px' }}>
            <div style={{ flex: 1, textAlign: 'center', padding: '50px' }}>
                <RecipesIcon size='300' color='#bbbbbb' />
            </div>
            <div style={{ flex: 1, padding: '50px' }}>
                <div style={{ color: '#bbbbbb', fontSize: '48px', fontWeight: 'bold', margin: '50px 0' }}>
                    <FormattedMessage id="noRecipesFound" defaultMessage="No Recipes Found" />
                </div>
                <div style={{ color: '#bbbbbb', fontSize: '24px', fontWeight: 'bold', maxWidth: '500px' }}>
                    <FormattedMessage id="noRecipesFound.description" defaultMessage="We couldn't find any recipes that match your search" />.
                </div>
            </div>
        </div>
    }

    return recipes.map((recipe, index) => {
        const imageUrl = Array.isArray(recipe.images) && recipe.images.length > 0 ? `${recipe.images[0].path}` : ''

        return <Recipe
            key={index}
            onSelect={onRecipeSelect}
            uuid={recipe.uuid}
            imageUrl={imageUrl}
            title={recipe.name}
            minutes={recipe.minutes}
            ingredientCount={recipe.ingredient_count}
            rating={recipe.rating}
            spicy={recipe.spicy}
            vegan={recipe.vegan}
            vegetarian={recipe.vegetarian}
            pescatarian={recipe.pescatarian}
            gluten_free={recipe.gluten_free}
            dairy_free={recipe.dairy_free}
        >
            <div style={{ display: 'flex', gap: '10px' }}>
                <div className='greenButton' onClick={() => { onRecipeSelect(recipe.uuid) }}><FormattedMessage id="app.viewRecipe" defaultMessage="View" /></div>
            </div>
        </Recipe>
    })
}