import React from "react";

import HomeIcon from 'assets/svgIcons/Home'
import RecipesIcon from 'assets/svgIcons/Recipes'
import FeedbackIcon from 'assets/svgIcons/Feedback'
import ProfileIcon from 'assets/svgIcons/Profile'
import MembersIcon from 'assets/svgIcons/Users'
import FilesIcon from 'assets/svgIcons/Files'


const dashboardRoutes = [
	{
		path: "/dashboard",
		id: "app.dashboard",
		name: "Dashboard",
		iconComponent: <HomeIcon size={30} color='currentColor' />
	},
	{
		path: "/my-members",
		id: "app.myMembers",
		name: "My Members",
		iconComponent: <MembersIcon size={30} color='currentColor' />
	},
	{
		path: "/all-members",
		id: "app.allMembers",
		name: "All Members",
		iconComponent: <MembersIcon size={30} color='currentColor' />
	},
	{
		path: "/recipe-categories",
		id: "app.recipes",
		name: "Recipes",
		iconComponent: <RecipesIcon size={30} color='currentColor' />
	},
	{
		path: "/resource-center",
		id: "app.resourceCenter",
		name: "Resource Center",
		iconComponent: <FilesIcon size={30} color='currentColor' />
	},
	{
		path: "/member-activity-summary",
		id: "app.memberActivitySummary",
		name: "Member Activity Summary",
		iconComponent: <FeedbackIcon size={30} color='currentColor' />
	},
	{
		path: "/feedback",
		id: "app.feedback",
		name: "Feedback",
		iconComponent: <FeedbackIcon size={30} color='currentColor' />
	},
	{
		path: "/profile",
		id: "app.profile",
		name: "Profile",
		iconComponent: <ProfileIcon size={30} color='currentColor' />
	}
]

export default dashboardRoutes;
