import React from "react";
import Recipes from 'v2/assets/svgIcons/Recipes'
import { Card, CardTitle, CardContent } from 'v2/components/TitleCard'
import ServingsCard from './ServingsCard'
import NutritionCard from './NutritionCard'


export default function ServingsNutritionCard({ servings, protein, fat, carb, children }) {
    const showServingNutrition = Boolean(protein) || Boolean(fat) || Boolean(carb)

    return <Card>
        <CardTitle>Nutritional Information <Recipes size={30} color={"white"} /></CardTitle>
        <CardContent>
            {showServingNutrition && <>
                <div style={{ display: 'flex', gap: '10px', margin: '10px 0px', height: '100px' }}>
                    <ServingsCard color="#17B169" value={1} />
                    {protein && <NutritionCard title="Protein" color="#c96464" value={`${Math.round(protein)}g`} />}
                    {fat && <NutritionCard title="Fat" color="#5D8AA8" value={`${Math.round(fat)}g`} />}
                    {carb && <NutritionCard title="Carb" color="#DAA06D" value={`${Math.round(carb)}g`} />}
                </div>
                <div style={{ padding: "10px 0px", fontSize: '12px', }}>
                    *Please consult your meal plan and/or coach for appropriate portion sizes for your specific needs.
                </div>
            </>}
            {children}
        </CardContent>
    </Card>
}