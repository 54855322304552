import React, { useState, useEffect, useCallback } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import moment from 'moment';

import { useSFLApi } from 'api';
import { buildQuerystring } from 'misc';
import DateCycle from "sfl-components/DateCycle";

import MobileNavbar from 'v2/components/MobileNavbar';
import BackgroundIcon from 'v2/assets/svgIcons/activityTypes/Other'
import ActivityIcon from 'v2/assets/svgIcons/activityTypes/Activity'
import EditIcon from 'v2/assets/svgIcons/Edit'
import DeleteIcon from 'v2/assets/svgIcons/Delete'


export default function RecipeDetails() {
    const [activities, setActivities] = useState([])
    const intl = useIntl()
    const [searchParams] = useSearchParams()
    const { memberUuid } = useParams()
    const navigate = useNavigate()
    const { get, del } = useSFLApi()
    const date = searchParams.has('date') ? searchParams.get('date') : moment().format('YYYY-MM-DD')


    const fetchData = useCallback(async (date) => {
        try {
            const queryString = buildQuerystring({ date: date, member_uuid: memberUuid });
            const results = await get(`memberActivities?${queryString}`)
            setActivities(results.activities)
        }
        catch (error) {
            console.log('error', error)
        }
    }, [get, buildQuerystring, setActivities])


    useEffect(() => {
        fetchData(date)
    }, [date])


    const selectDate = (d) => {
        navigate(`?date=${d}`, { replace: true });
    }

    const handleDelete = async (uuid) => {
        try {
            await del(`memberActivities/${uuid}`)
            toast.success(intl.formatMessage({ id: "activityRemoved", defaultMessage: "Activity Removed" }));
        }
        catch (error) {
            console.log('error', error)
            toast.error('Action Failed!');
        }
        fetchData(date)
    }

    return <>
        <MobileNavbar title={<FormattedMessage id="activityLog" defaultMessage="Activity Log" />} />
        <DateCycle date={date} onChange={selectDate} />
        <div
            className='greenButton'
            style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}
            onClick={() => { navigate(`edit?date=${date}`) }}
        >
            <div style={{ fontWeight: 700 }}>+ <FormattedMessage id="addActivity" defaultMessage="Add Activity" /></div>
        </div>
        {activities.length == 0
            ? <div style={{ display: 'flex', paddingTop: '150px' }}>
                <div style={{ flex: 1, textAlign: 'center' }}>
                    <BackgroundIcon size='300' color='#cccccc' />
                </div>
            </div>
            : activities.map(activity => <div key={activity.uuid} className='shadowBox' style={{ padding: '20px', display: 'flex', gap: '20px', alignItems: 'center', marginTop: '20px' }}>
                <div style={{ flex: 0 }}><ActivityIcon type={activity.type} size='40' color='#000000' /></div>
                <div style={{ flex: 1 }}>
                    <div style={{ fontWeight: 700 }}><FormattedMessage id={`activityType.${activity.type}`} defaultMessage={activity.type} /></div>
                    <div>
                        {activity.description && <div>{activity.description}</div>}
                        {activity.data.map((e, index) => {
                            return e.value && <div key={index}>{Number(e.value).toLocaleString()} <FormattedMessage id={`v.activityMeasurement.${e.type}`} values={{ value: e.value }} defaultMessage={e.type} /></div>
                        })}
                    </div>
                </div>
                <div style={{ flex: 0, color: '#aaaaaa' }}>
                    <span className='hoverGreen' style={{ padding: '10px', cursor: 'pointer' }} onClick={() => { navigate(`edit/${activity.uuid}?date=${date}`) }}>
                        <EditIcon size={20} color="currentcolor" />
                    </span>
                </div>
                <div style={{ flex: 0, color: '#aaaaaa' }}>
                    <span className='hoverRed' style={{ padding: '10px', cursor: 'pointer' }} onClick={() => { handleDelete(activity.uuid) }}>
                        <DeleteIcon size={20} color="currentcolor" />
                    </span>
                </div>
            </div>
            )}
    </>
}
