import React, { useState, useEffect } from 'react';
import CheckboxSelect from 'sfl-components/CheckboxSelect';
import { useSFLApi } from 'api';


export default function CheckboxesTags(props) {
    const [options, setOptions] = useState([]);
    const { get } = useSFLApi();
    const { selectedOptions } = props;

    useEffect(() => {
        get(`foodCategories?status=active&sort=name`)
            .then(results => {

                const tmpCategories = []
                for (let i = 0; i < results.foodCategories.length; i++) {

                    const fc = results.foodCategories[i]
                    if (fc.questionnaire) {
                        tmpCategories.push(fc)
                    } else if (selectedOptions.includes(fc.uuid)) {
                        tmpCategories.push(fc)
                    }
                }
                setOptions(tmpCategories.map(category => ({ id: category.uuid, name: category.name })))
            })
    }, [selectedOptions, get]);

    return <CheckboxSelect
        options={options}
        {...props}
    />
}