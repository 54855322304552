import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Goals from "./pages/Goals"
import Profile from "./pages/Profile"
import Activity from "./pages/Activity"
import DietRestrictions from "./pages/DietRestrictions"
import Preferences from "./pages/Preferences"
import Medical from "./pages/Medical"
import Intro from "./pages/Intro"
import Complete from "./pages/Complete"
import { useSFLApi } from 'api';
import { useIntl } from 'react-intl';

const defaultProfile = {
    goal_more_energy: false,
    goal_lose_weight: false,
    goal_feel_better: false,
    goal_other: false,
    goal_notes: "",
    date_of_birth: '',
    height_feet: '',
    height_inches: '',
    gender: '',
    current_weight: '',
    goal_weight: '',
    eat_eout_frequency: '',
    average_week_meals: '',
    supplements: '',
    activity_level: '',
    gluten_free: false,
    dairy_free: false,
    pescatarian: false,
    vegetarian: false,
    vegan: false,
    restrictions_notes: '',
    allergies: [],
    medical_conditions: [],
    medical_notes: '',
    likes: [],
    dislikes: []
}

export default function RecipeDetails({ locationEmail = '' }) {
    const [profile, setProfile] = useState(defaultProfile)
    const [step, setStep] = useState('')
    const intl = useIntl()
    const { post } = useSFLApi()
    const { tokenUuid } = useParams()

    const handleChange = (key, val) => {
        setProfile(currentState => {
            currentState[key] = val
            return { ...currentState }
        })
    }

    const handleStepChange = newStep => {
        window.scrollTo(0, 0)
        setStep(newStep)

        if (newStep === 'complete') {
            handleSubmit()
        }
    }

    const handleSubmit = async () => {
        const goals = []
        if (profile.goal_more_energy) {
            goals.push(intl.formatMessage({ id: "questionnaireV2.goals.moreEenergy", defaultMessage: "I want to have more energy." }))
        }
        if (profile.goal_lose_weight) {
            goals.push(intl.formatMessage({ id: "questionnaireV2.goals.loseWeight", defaultMessage: "I want to lose weight." }))
        }
        if (profile.goal_feel_better) {
            goals.push(intl.formatMessage({ id: "questionnaireV2.goals.feelBetter", defaultMessage: "I want to feel better." }))
        }
        if (profile.goal_other) {
            goals.push(`${intl.formatMessage({ id: "questionnaireV2.goals.other", defaultMessage: "Other" })}: ${profile.goal_notes}`)
        }

        let exercise_level = ''
        if (profile.activity_level == 'sedentary') {
            exercise_level = 'none'
        }
        if (profile.activity_level == 'lightly_active') {
            exercise_level = 'low'
        }
        if (profile.activity_level == 'moderately_active') {
            exercise_level = 'medium'
        }
        if (profile.activity_level == 'very_active') {
            exercise_level = 'high'
        }


        await post(`questionnaires/token/${tokenUuid}`, {
            data: {
                "what_brings_you": goals.join('\n'),
                "date_of_birth": profile.date_of_birth,
                "height": `${profile.height_feet}'${profile.height_inches}"`,
                "gender": profile.gender,
                "current_weight": profile.current_weight,
                "goal_weight": profile.goal_weight,
                "average_food": profile.average_week_meals,
                "activity_level": profile.activity_level,
                "exercise_level": exercise_level,
                "restaurant_frequency": profile.eat_out_frequency,
                "medications": profile.medications,
                "allergies": profile.allergies,
                "liked_foods": profile.likes,
                "disliked_foods": profile.dislikes,
                "medical_conditions": profile.medical_conditions,
                "allergies_restrictions_other": profile.restrictions_notes,
                "medical_notes": profile.medical_notes,
                "supplements": profile.supplements,
                "restrictions": [
                    profile.gluten_free ? "gluten_free" : null,
                    profile.dairy_free ? "dairy_free" : null,
                    profile.pescatarian ? "pescatarian" : null,
                    profile.vegetarian ? "vegetarian" : null,
                    profile.vegan ? "vegan" : null
                ]
            }
        })
    }

    /*
        0. Intro
        1. Goals
        2. Profile
        3. Activity
        4. Medical
        5. restrictions
        6. food preferences
    */


    if (step === 'goals') {
        return <Goals
            data={profile}
            onChange={handleChange}
            onPrevious={() => {
                handleStepChange('intro')
            }}
            onNext={() => {
                handleStepChange('profile')
            }}
        />
    } else if (step === 'profile') {
        return <Profile
            data={profile}
            onChange={handleChange}
            onPrevious={() => {
                handleStepChange('goals')
            }}
            onNext={() => {
                handleStepChange('activity')
            }}
        />
    } else if (step === 'activity') {
        return <Activity
            data={profile}
            onChange={handleChange}
            onPrevious={() => {
                handleStepChange('profile')
            }}
            onNext={() => {
                handleStepChange('restrictions')
            }}
        />
    } else if (step === 'restrictions') {
        return <DietRestrictions
            data={profile}
            onChange={handleChange}
            onPrevious={() => {
                handleStepChange('activity')
            }}
            onNext={() => {
                handleStepChange('medical')
            }}
        />
    } else if (step === 'medical') {
        return <Medical
            data={profile}
            onChange={handleChange}
            onPrevious={() => {
                handleStepChange('restrictions')
            }}
            onNext={() => {
                handleStepChange('preferences')
            }}
        />
    } else if (step === 'preferences') {
        return <Preferences
            data={profile}
            onChange={handleChange}
            onPrevious={() => {
                handleStepChange('medical')
            }}
            onNext={() => {
                handleStepChange('complete')
            }}
        />
    } else if (step === 'complete') {
        return <Complete data={profile} onSubmit={handleSubmit} />
    }

    return <Intro
        locationEmail={locationEmail}
        onNext={() => {
            handleStepChange('goals')
        }}
    />
}