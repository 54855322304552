import * as React from "react"
import Tag from 'v2/components/Tags/Tag'
import Spicy from 'v2/assets/svgIcons/Spicy'

export default function SpicyTag() {
    return <Tag
        colorLeft="firebrick"
        colorRight="#c96464"
        Icon={Spicy}
        title="Spicy"
    />
}