import React from "react"
import CircleCheck from 'v2/assets/svgIcons/CircleCheck'

export default function Button({ title = "", description = "", selected = false, onClick }) {
    return <div
        style={{
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            borderRadius: '25px',
            marginBottom: '20px',
            border: '1px solid #17B169',
            backgroundColor: selected ? '#17B169' : 'white',
            color: selected ? 'white' : '#17B169'
        }}
        onClick={onClick}>
        <div style={{ minWidth: '40px' }}></div>
        <div style={{ flexGrow: 1, padding: '10px 0px' }}>
            <div style={{ fontWeight: 600 }}>{title}</div>
            <div>{description}</div>
        </div>
        <div style={{ minWidth: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {selected && <CircleCheck
                size={30}
                color="white"
            />}
        </div>
    </div>
}