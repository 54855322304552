import * as React from "react"
import Star from 'v2/assets/svgIcons/Star'

export default function Rating({ score = 0 }) {
    return <div style={{ display: 'flex', gap: '5px' }}>
        <Star color={score >= 1 ? '#17B169' : '#aaaaaa'} size={20} />
        <Star color={score >= 2 ? '#17B169' : '#aaaaaa'} size={20} />
        <Star color={score >= 3 ? '#17B169' : '#aaaaaa'} size={20} />
        <Star color={score >= 4 ? '#17B169' : '#aaaaaa'} size={20} />
        <Star color={score >= 5 ? '#17B169' : '#aaaaaa'} size={20} />
    </div>
}
