import React from "react";

import Grid from '@material-ui/core/Grid';

import Card from 'sfl-components/SFLCards/Card';

import AllergySelect from 'sfl-components/AllergySelect';
import FoodPreferenceSelect from 'sfl-components/FoodPreferenceSelect';

import Question from './Question';
import { useIntl } from 'react-intl';


const QuestionnairePreferences = props => {
    const {
        handleChange,
        average_food = '',
        setLikedFoods,
        likedFoods = [],
        setDislikedFoods,
        dislikedFoods = [],
        setAllergies,
        allergies = [],
        allergies_restrictions_other = ''
    } = props
    const intl = useIntl();


    return <Card key='preferences' title={intl.formatMessage({ id: "questionnaire.whatAreYourPreferences", defaultMessage: "What are you food preferences?" })} titleMode="inner" style={{ marginBottom: '75px' }}>
        <Grid container spacing={3} style={{ padding: '0 20px' }}>
            <Grid item xs={12} >
                <Grid container spacing={3}>
                    <Question
                        id="average_food"
                        label={intl.formatMessage({ id: "questionnaire.averageFood", defaultMessage: "What do you normally eat during the run of a day?" })}
                        helperText={intl.formatMessage({ id: "questionnaire.averageFood.helper", defaultMessage: "For example: I normally have a muffin and a latte for breakfast and for lunch I have..." })}
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={average_food}
                        onChange={handleChange}
                        gridProps={{
                            xs: 12
                        }}
                    />
                    <Grid item xs={12} lg={6}>
                        <FoodPreferenceSelect
                            id="FoodPreferenceSelect1"
                            label={intl.formatMessage({ id: "questionnaire.whatDoYouLikeToEat", defaultMessage: "What types of foods do you love to eat?" })}
                            selectedOptions={likedFoods}
                            onChange={setLikedFoods}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <FoodPreferenceSelect
                            id="FoodPreferenceSelect2"
                            label={intl.formatMessage({ id: "questionnaire.whatDoYouNotLikeToEat", defaultMessage: "What types of foods do you not like to eat?" })}
                            helperText=" "
                            selectedOptions={dislikedFoods}
                            onChange={setDislikedFoods}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <AllergySelect
                            id="AllergySelect"
                            label={intl.formatMessage({ id: "questionnaire.whatAreYouAllergicTo", defaultMessage: "What types of foods are you allergic to?" })}
                            helperText=" "
                            selectedOptions={allergies}
                            onChange={(allergyList) => { setAllergies(allergyList) }}
                        />
                    </Grid>
                    <Question
                        id="allergies_restrictions_other"
                        label={intl.formatMessage({ id: "questionnaire.allergiesOther", defaultMessage: "Are there any other allergies or restrictions you would like to mention?" })}
                        multiline
                        minRows={2}
                        maxRows={10}
                        value={allergies_restrictions_other}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Card>
}


export default React.memo(QuestionnairePreferences);