import * as React from "react"
import Tag from 'v2/components/Tags/Tag'
import Carbs from 'v2/assets/svgIcons/Carbs'

export default function SpicyTag() {
    return <Tag
        colorLeft="#CC5500"
        colorRight="#DAA06D"
        Icon={Carbs}
        title="Gluten Free"
    />
}