import * as React from "react"

function SvgComponent({ size, color}) {
    return <svg
        height={size}
        width={size}
        viewBox="0 0 810 809"
    >
        <path fill={color} d="M 414.976562 228.066406 C 455.371094 227.113281 487.320312 193.667969 486.363281 153.339844 C 485.480469 112.945312 452.035156 81 411.640625 81.886719 C 371.246094 82.769531 339.300781 116.285156 340.253906 156.679688 C 341.140625 197.003906 374.585938 229.019531 414.976562 228.066406 Z M 414.976562 228.066406 " fillOpacity="1" fillRule="nonzero" />
        <path fill={color} d="M 458.914062 522.128906 L 469.199219 596.578125 C 471.789062 615.585938 488.066406 629.277344 506.664062 629.277344 C 508.367188 629.277344 510.136719 629.140625 511.910156 628.9375 C 532.617188 626.074219 547.125 606.933594 544.265625 586.226562 L 530.097656 483.980469 C 527.847656 467.429688 514.972656 454.351562 498.558594 451.761719 L 428.191406 440.523438 L 450.945312 342.636719 L 487.320312 370.089844 C 497.398438 377.71875 511.023438 378.945312 522.261719 373.222656 L 584.929688 341.546875 C 601.277344 333.304688 607.816406 313.347656 599.574219 297 C 591.332031 280.652344 571.375 274.042969 555.027344 282.355469 L 510.820312 304.695312 L 458.097656 264.917969 C 448.492188 257.695312 437.117188 252.929688 425.265625 251.089844 L 377.992188 243.9375 C 370.566406 242.777344 363.140625 242.777344 355.785156 244.003906 L 263.894531 258.445312 C 254.628906 259.875 246.386719 265.1875 241.277344 273.023438 L 198.363281 338.6875 C 188.351562 354.015625 192.644531 374.585938 207.96875 384.597656 C 213.554688 388.277344 219.890625 389.980469 226.089844 389.980469 C 236.917969 389.980469 247.546875 384.667969 253.878906 374.996094 L 288.757812 321.660156 L 319 316.890625 C 314.84375 340.390625 311.234375 373.972656 307.488281 422.8125 C 305.105469 453.601562 315.59375 476.898438 338.277344 492.566406 L 294.273438 682.542969 C 289.574219 702.910156 302.242188 723.277344 322.609375 728.046875 C 325.472656 728.726562 328.402344 729 331.195312 729 C 348.425781 729 364.027344 717.148438 368.113281 699.640625 L 411.027344 514.5 Z M 458.914062 522.128906 " fillOpacity="1" fillRule="nonzero" />
        <path fill={color} d="M 619.738281 715.988281 L 601.414062 655.296875 C 600.1875 651.140625 596.304688 648.28125 591.945312 648.28125 L 428.191406 648.28125 C 423.832031 648.28125 420.019531 651.140625 418.722656 655.296875 L 400.402344 715.988281 C 398.492188 722.324219 403.261719 728.726562 409.871094 728.726562 L 456.871094 728.726562 C 461.230469 728.726562 465.042969 725.933594 466.339844 721.78125 L 471.3125 705.703125 L 548.828125 705.703125 L 553.800781 721.78125 C 555.09375 725.933594 558.910156 728.726562 563.269531 728.726562 L 610.269531 728.726562 C 616.945312 728.726562 621.644531 722.324219 619.738281 715.988281 Z M 619.738281 715.988281 " fillOpacity="1" fillRule="nonzero" />
    </svg>
}

export default SvgComponent