import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';
import Title from 'sfl-components/Title';

import Intro from './Intro';
import Goals from './Goals';
import Preferences from './Preferences';
import Habits from './Habits';
import Medical from './Medical';
import Employement from './Employement';


import { useSFLApi } from 'api';
import bowlImg from 'assets/images/bowls/Bal_Leaves_circle_dishes_400px.png';
import { FormattedMessage, useIntl } from 'react-intl';



export default function QuestionnaireForm() {
	const navigate = useNavigate();
	const { userType } = useSelector(state => state.session);
	const { memberUuid, questionnaireUuid } = useParams();
	const [state, setState] = useState({});
	const { get, post } = useSFLApi();
	const [formComplete, setFormComplete] = useState(false);

	const [restrictions, setRestrictions] = useState([]);
	const [allergies, setAllergies] = useState([]);
	const [likedFoods, setLikedFoods] = useState([]);
	const [dislikedFoods, setDislikedFoods] = useState([]);
	const [medicalConditions, setMedicalConditions] = useState([]);
	const intl = useIntl();



	const {
		employement_status = '',
		employment_activity_level = '',
		employment_company_name = '',
		height = '',
		height_scale = 'ft',
		current_weight = '',
		current_weight_scale = 'pounds',
		goal_weight = '',
		goal_weight_scale = 'pounds',
		exercise_level = '',
		family_doctor = '',
		last_checkup = '',
		average_food = '',
		medical_conditions_extra,
		medications,
		what_other_programs = '',
		what_brings_you = '',
		household = '',
		pets = '',
		gender = '',
		date_of_birth,
		primary_goal = '',
		goal_celebration = '',
		restaurant_frequency = '',
		cook_frequency = '',
		allergies_restrictions_other,
		tune = '',
		supplements = ''
	} = state;



	useEffect(() => {
		const fetchData = async () => {
			try {
				if (questionnaireUuid) {
					const data = await get(`questionnaires/${questionnaireUuid}?sort=creation_datetime:desc&limit=1`);
					setState(data.questionnaire.data);
					setRestrictions(data.questionnaire.data.restrictions);
					setAllergies(data.questionnaire.data.allergies);
					setLikedFoods(data.questionnaire.data.liked_foods);
					setDislikedFoods(data.questionnaire.data.disliked_foods);
					setMedicalConditions(data.questionnaire.data.medical_conditions);

				} else {
					const data = memberUuid
						? await get(`questionnaires/?member_uuid=${memberUuid}&sort=creation_datetime:desc&limit=1`)
						: await get(`questionnaires/?sort=creation_datetime:desc&limit=1`)

					if (data.questionnaires.length === 1) {
						setState(data.questionnaires[0].data);
						setRestrictions(data.questionnaires[0].data.restrictions);
						setAllergies(data.questionnaires[0].data.allergies);
						setLikedFoods(data.questionnaires[0].data.liked_foods);
						setDislikedFoods(data.questionnaires[0].data.disliked_foods);
						setMedicalConditions(data.questionnaires[0].data.medical_conditions);
					}
				}
			}
			catch (error) {
				console.log('error', error);
			}
		}

		fetchData();
	}, [get, questionnaireUuid, memberUuid]);

	const handleChange = React.useCallback((name, value) => {
		setState(currentState => ({ ...currentState, [name]: value }));
	}, [setState]);


	const handleSave = React.useCallback(async () => {
		const path = questionnaireUuid && questionnaireUuid !== 'new' ? `questionnaires/${questionnaireUuid}` : 'questionnaires'
		post(path, {
			data: {
				...state,
				restrictions: restrictions,
				allergies: allergies,
				liked_foods: likedFoods,
				disliked_foods: dislikedFoods,
				medical_conditions: medicalConditions
			},
			member_uuid: memberUuid
		})
			.then(() => {
				setFormComplete(true)
				toast.success(intl.formatMessage({ id: "questionnaire.saved", defaultMessage: 'Questionnaire Saved' }));
			})
			.catch(() => toast.error(intl.formatMessage({ id: "questionnaire.saveFailed", defaultMessage: 'Save Failed' })));
	}, [post, intl, state, memberUuid, questionnaireUuid, restrictions, allergies, likedFoods, dislikedFoods, medicalConditions]);


	const downloadQuestionnaire = async () => {
		try {

			const path = questionnaireUuid && questionnaireUuid !== 'new' ? `questionnaires/${questionnaireUuid}` : 'questionnaires'
			const saveResults = await post(path, {
				data: {
					...state,
					allergies: allergies,
					liked_foods: likedFoods,
					disliked_foods: dislikedFoods,
					medical_conditions: medicalConditions
				},
				member_uuid: memberUuid
			})

			const printResults = await get(`questionnaires/${saveResults.questionnaire.uuid}/print`)
			if (!printResults) {
				throw 'API file download error!';
			}
			window.open(printResults.download.path)
		}
		catch (error) {
			console.log('error', error)
			toast.error(intl.formatMessage({ id: "questionnaire.downloadFailed", defaultMessage: 'Download Failed' }))
		}
	}


	if (formComplete) {
		return [
			<div key='title' style={{ display: 'flex', marginBottom: '40px' }}>
				<Title>
					<FormattedMessage id="app.questionnaire" defaultMessage="Questionnaire" />
				</Title>
			</div>,
			<div key='content' style={{ textAlign: 'center' }}>
				<div style={{ fontSize: '32px', marginTop: '180px', marginBottom: '40px' }}>
					<i><FormattedMessage id="questionnaire.completionMessage" defaultMessage="Your questionnaire has been updated!" /></i>
				</div>
				<img src={bowlImg} alt='' style={{ maxWidth: '300px' }} />
			</div>
		]
	}


	return [
		<div key='title' style={{ display: 'flex', marginBottom: '20px' }}>
			<Title>
				<FormattedMessage id="app.questionnaire" defaultMessage="Questionnaire" />
			</Title>
			{userType === 'consultant' && <CardButton onClick={downloadQuestionnaire}>
				<FontAwesomeIcon icon={['fas', 'history']} />
				<FormattedMessage id="app.print" defaultMessage="Print" />
			</CardButton>}
			{userType === 'consultant' && <CardButton onClick={() => {
				navigate(`/members/${memberUuid}/questionnaires/list`)
			}}>
				<FontAwesomeIcon icon={['fas', 'history']} />
				<FormattedMessage id="questionnaire.viewHistory" defaultMessage="View History" />
			</CardButton>}
			<CardButton onClick={handleSave}>
				<FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
				<FormattedMessage id="questionnaire.saveQuestionnaire" defaultMessage="Save Questionnaire" />
			</CardButton>
		</div>,
		<Intro
			key='intro'
			handleChange={handleChange}
			what_brings_you={what_brings_you}
			what_other_programs={what_other_programs}
			household={household}
			pets={pets}
			tune={tune}
			date_of_birth={date_of_birth}
			height={height}
			height_scale={height_scale}
			gender={gender}
		/>,
		<Goals
			key='goals'
			handleChange={handleChange}
			primary_goal={primary_goal}
			current_weight={current_weight}
			current_weight_scale={current_weight_scale}
			goal_weight={goal_weight}
			goal_weight_scale={goal_weight_scale}
			goal_celebration={goal_celebration}
		/>,
		<Preferences
			key='Preferences'
			handleChange={handleChange}
			average_food={average_food}
			setLikedFoods={setLikedFoods}
			likedFoods={likedFoods}
			setDislikedFoods={setDislikedFoods}
			dislikedFoods={dislikedFoods}
			setAllergies={setAllergies}
			allergies={allergies}
			setRestrictions={setRestrictions}
			restrictions={restrictions}
			allergies_restrictions_other={allergies_restrictions_other}
		/>,
		<Habits
			key='Habits'
			handleChange={handleChange}
			exercise_level={exercise_level}
			restaurant_frequency={restaurant_frequency}
			cook_frequency={cook_frequency}
		/>,
		<Medical
			key='Medical'
			handleChange={handleChange}
			family_doctor={family_doctor}
			last_checkup={last_checkup}
			setMedicalConditions={setMedicalConditions}
			medicalConditions={medicalConditions}
			medical_conditions_extra={medical_conditions_extra}
			medications={medications}
			supplements={supplements}
		/>,
		<Employement
			key='Employement'
			handleChange={handleChange}
			employement_status={employement_status}
			employment_activity_level={employment_activity_level}
			employment_company_name={employment_company_name}
		/>,
		<div key='footer' style={{ textAlign: 'center' }}>
			<CardButton onClick={handleSave}>
				<FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
				<FormattedMessage id="questionnaire.saveQuestionnaire" defaultMessage="Save Questionnaire" />
			</CardButton>
		</div>
	]
}