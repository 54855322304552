import * as React from "react"
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import NavBarButton from "sfl-components/Navbars/NavBarButton.js";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hidden from "@material-ui/core/Hidden";


export default function Rating({ title, goBack = false }) {
    const navigate = useNavigate();

    return <Hidden mdUp>
        <div style={{ paddingTop: '40px' }}>
            <MobileNavbar key='MobileNavbar'>
                {goBack
                    ? <NavBarButton onClick={() => { navigate(-1) }}>
                        <FontAwesomeIcon flip='horizontal' icon={['fas', 'share']} fixedWidth />
                    </NavBarButton>
                    : <SidebarToggle />
                }
                <NavBarTitle>{title}</NavBarTitle>
            </MobileNavbar>
        </div>
    </Hidden>
}
