import React from "react";
import { useIntl } from 'react-intl'
import Card from '../components/Card'
import Logo from 'v2/assets/images/logo.svg'


export default function QuestionnaireComplete() {
    const intl = useIntl()
    const language = intl.locale

    return <Card>
        <div style={{ marginBottom: '50px', textAlign: 'center' }}>
            <img src={Logo} style={{ width: '100%', maxWidth: '400px' }} />
        </div>
        {language === 'en'
            ? <div style={{ marginBottom: '20px' }}>
                <div style={{ marginBottom: '20px' }}>
                    Awesome! Thanks for filling out your profile questionnaire!  Your detailed responses will be invaluable as we prepare for your upcoming consultation. They provide us with essential insights into your health goals, preferences, and specific needs and allow us to make the most of our time together during the consultation.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <b>What to expect?</b><br />
                    If you're a returning member, you know the drill - but here is a refresher:
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <b>In-Person</b><br />
                    Arrive 10 mins ahead of your scheduled time. Our team will check you in and lead you to our client area where you will be introduced to your assigned coach. Your session will be approximately 20 minutes in duration.  After the session if you do decide to join our wellness program, our client services coordinator will get you registered, book your appointment and give you a quick tour - this should all take around 10 - 15 mins.  You'll receive a Food Journal, login/password to the SFL App and our Quick Start Plan to hit the ground running while your coach develops your personalized plan.  All future sessions will be 15 minutes.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <b>For Virtual or Call-in sessions</b><br />
                    Login in or call in a few minutes ahead of time.  You'll be connected directly with your coach who will walk you through the same steps as in-person. The registration process may be handled directly with your coach, depending on the servicing location (if applicable).
                </div>
                <div style={{ marginBottom: '20px' }}>
                    If you have any questions or need additional information before your consultation, please don't hesitate to reach out.
                </div>
                Looking forward to working with you!  Let's do this ;)
            </div>
            : <div style={{ marginBottom: '20px' }}>
                <div style={{ marginBottom: '20px' }}>
                    Génial! Merci d'avoir rempli votre questionnaire de profil ! Vos réponses détaillées seront inestimables alors que nous préparons votre prochaine consultation. Ils nous fournissent des informations essentielles sur vos objectifs de santé, vos préférences et vos besoins spécifiques et nous permettent de tirer le meilleur parti de notre temps ensemble pendant la consultation.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <b>À quoi s'attendre?</b><br />
                    Si vous êtes un membre fidèle, vous connaissez le principe, mais voici un rappel :
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <b>En personne</b><br />
                    Arrivez 10 minutes avant l'heure prévue. Notre équipe vous enregistrera et vous conduira à notre espace client où vous serez présenté à votre coach assigné. Votre séance durera environ 20 minutes. Après la séance, si vous décidez de rejoindre notre programme de bien-être, notre coordinateur du service client vous enregistrera, prendra rendez-vous et vous fera une visite rapide - tout cela devrait prendre environ 10 à 15 minutes. Vous recevrez un journal alimentaire, un identifiant/mot de passe pour l'application SFL et notre plan de démarrage rapide pour vous lancer pendant que votre coach élabore votre plan personnalisé. Toutes les sessions futures dureront 15 minutes.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <b>Pour les sessions virtuelles ou téléphoniques</b><br />
                    Connectez-vous ou appelez quelques minutes à l’avance. Vous serez directement connecté avec votre coach qui vous guidera à travers les mêmes étapes qu'en personne. Le processus d'inscription peut être effectué directement avec votre coach, en fonction du lieu de service (le cas échéant).
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Si vous avez des questions ou avez besoin d'informations supplémentaires avant votre consultation, n'hésitez pas à nous contacter.
                </div>
                Au plaisir de travailler avec vous! Faisons cela ;)
            </div>}
    </Card>
}