import * as React from "react"

export default function Tag({ Icon, title, colorLeft, colorRight }) {
    return <div style={{ display: 'flex' }}>
        <div style={{ backgroundColor: colorLeft, display: 'flex', alignItems: 'center', padding: '5px 10px', borderRadius: '10px 0px 0px 10px' }}>
            <Icon
                size={24}
                color='white'
            />
        </div>
        <div style={{ backgroundColor: colorRight, display: 'flex', alignItems: 'center', color: 'white', padding: '5px 15px', fontSize: '14px', borderRadius: '0px 10px 10px 0px' }}>
            {title}
        </div>
    </div>
}