import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        width={size}
        viewBox="0 0 256 256"
    >
        <path fill={color} d="M85.9,33c-35.7,3.5-64.9,19.7-73.5,40.7C7.8,85.2,9.9,97.9,18.1,108l2.8,3.5l-0.5,30.8c-0.5,32.8-0.3,35.4,2.4,41c2.7,5.7,5.5,7.5,21.4,14.2c33.9,14.3,52.3,21,65,23.9c4.4,1,16.8,2.3,18.8,1.9c10-1.8,92.1-20.2,94.2-21.2c1.6-0.8,3.6-2.4,4.6-3.8c3.5-4.8,3.6-5.9,4-43.8l0.4-34.9l1.7-1.3c3.6-2.6,8.3-8.1,10.2-11.9c8.4-17.1-0.7-35.1-23.2-46.1c-26-12.7-63.4-23.1-96.8-26.9C115.3,32.7,92.5,32.3,85.9,33z M113.8,43.2c11,0.9,22.8,2.6,35.8,5.3c15.3,3.3,15,3.1,4.8,4.3c-16.2,1.9-31.2,6.6-42,12.9c-21.7,12.6-31.8,32.6-25.6,50.3c1.3,3.8,4.3,8.3,7.3,10.9c3,2.8,2.5,2.8-6,0.8c-12.8-3.1-28-9.2-48.5-19.3c-11.7-5.8-12.9-6.5-14.7-9.1c-8-11.7-4.7-25.6,8.9-36.6C51.9,48.1,81.7,40.9,113.8,43.2z M185.7,62.7c23.1,2.1,41.5,10.9,47.5,22.6c2.6,5.1,2.9,10.7,0.8,15.2c-1.9,4-4.7,7.4-8.5,10c-4.8,3.3-5.2,4.8-5.2,19.6c0,7-0.2,23.5-0.4,36.5c-0.3,20.8-0.5,23.9-1.4,25c-0.9,1.1-8.7,3-46.3,11.3c-24.8,5.5-45.9,10-46.9,10c-2.9,0-4.8-1.3-6.3-4.3c-1.4-2.8-1.4-3.6-1.4-16.7c0-7.6,0.1-24.9,0.3-38.5c0.3-28.2,0.6-26.4-5.5-28.2c-9.9-2.8-15.7-9-16.8-17.7C94.1,96,105,81.2,120.7,73.2C137.7,64.7,162.6,60.7,185.7,62.7z M39.4,120.2c24.7,12.4,48.1,19.9,64.7,20.6l3.5,0.1l-0.1,20.1c-0.1,11.1-0.3,24.9-0.5,30.8c-0.3,8.5-0.2,11.4,0.6,14.3c0.9,3.6,0.9,3.6-0.4,3.6c-5.5,0-70.2-25.9-73.5-29.4c-3.1-3.3-3.1-4.2-2.6-35c0.3-15.7,0.7-28.6,1-28.6C32.2,116.8,35.6,118.4,39.4,120.2z" />
        <path fill={color} d="M122.3,80.6c-7.2,3-10.9,6.2-13.5,12c-3.1,6.8-2.4,15,1.9,21.5c1.9,2.9,2.5,3.3,4.3,3.3c2.6,0,5.2-1.9,5.7-4.3c0.3-1.3-0.1-2.8-1.6-5.4c-2.1-4.1-2.5-7.5-0.9-10.8c1.3-2.8,5-5.8,8.1-6.7c5-1.4,6.9-5.9,4-9.6C128.8,78.7,126.9,78.6,122.3,80.6z" />
        <path fill={color} d="M138.2,145.1c-3,1.2-3.1,2.2-3.6,23.3c-0.4,19.2-0.4,19.9,0.8,21.4c1.7,2.1,5.7,2.1,7.9,0.1l1.5-1.4l0.4-19.5c0.4-21.3,0.3-22.1-3-23.6C140.2,144.3,140.1,144.3,138.2,145.1z" />
    </svg>
}

export default SvgComponent