import React, { useState, useEffect } from "react";
import SectionTitle from 'sfl-components/MealPlan/SectionTitle';
import SectionBody from 'sfl-components/MealPlan/SectionBody';
import SectionEntry from 'sfl-components/MealPlan/SectionEntry';
import SectionEntryCell from 'sfl-components/MealPlan/SectionEntryCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Dialog from './Dialog';
import PortionDialog from './PortionDialog';
import { useSFLApi } from 'api';
import { getChildKeys } from 'misc';
import { FormattedMessage, useIntl } from 'react-intl';

export default function FoodLogDesktopList(props) {
    const { uuid: mealPlanUuid, section, date, refresh, onClose, triggerSave, memberUuid } = props;
    const [mealPlanEntries, setMealPlanEntries] = useState([]);
    const [editEntry, setEditEntry] = useState();
    const [showOptionsDialog, setShowOptionsDialog] = useState(false);
    const [showPortionDialog, setShowPortionDialog] = useState(false);
    const { get, post } = useSFLApi();
    const intl = useIntl();

    useEffect(() => {
        try {
            const fetchData = async () => {
                const results = await get(`mealPlanEntries?status=active&sort=order&meal_plan_uuid=${mealPlanUuid}`)
                const data = results.mealPlanEntries.map(e => {
                    const portion = e.portions.find(p => p.member_uuid === memberUuid) || {}
                    return {
                        ...e,
                        amount: portion.amount || '',
                        size: portion.size || '',
                        scale: portion.scale || ''
                    }
                })
                setMealPlanEntries(data)
            }

            fetchData();
        }
        catch (error) {
            console.log('error', error)
        }
    }, [get, memberUuid, mealPlanUuid]);


    const getParentUuid = (entries, parentUuid) => {
        const parent = entries.find(e => e.uuid === parentUuid)

        //If a parent exist and is of the appropirate type, return it's uuid
        //If the parent isn't found, uuid is null
        //If the parent is found, but has the wrong type, get it's parent uuid instead
        if (parent) {
            if (['recipe', 'food', 'food_child', 'food_parent'].includes(parent.type)) {
                return parent.uuid
            }

            return getParentUuid(entries, parent.parent_entry_uuid)
        }

        return null
    }


    useEffect(() => {
        if (!triggerSave) {
            return
        }

        const handleSave = async () => {
            try {
                //1. Get all selected entries
                //2. Assign the member's portions
                //3. Assign the proper parent UUID (removes groups and such)
                let entriesToSave = mealPlanEntries.filter(e => e.selected)

                entriesToSave = entriesToSave.map(entry => {
                    return {
                        uuid: entry.uuid,
                        section: section,
                        name: entry.name,
                        type: entry.type,
                        portion: entry.amount,
                        scale: entry.scale,
                        date: date,
                        parent_entry_uuid: getParentUuid(entriesToSave, entry.parent_entry_uuid)
                    }
                })

                //Post everything to the bulk insert
                await post('foodLogs/bulkInsert', { entries: entriesToSave })

                onClose();
                refresh();
                return toast.success(intl.formatMessage({ id: "app.entrySaved", defaultMessage: "Entry Saved" }));
            }
            catch (error) {
                console.log('error', error);
                return toast.error(intl.formatMessage({ id: "app.saveFailed", defaultMessage: "Save Failed" }));
            }
        }

        handleSave();
    }, [triggerSave]);


    /*
    * Toggle the display children state of the UUID which will make recipe ingredient visible.
    */
    const toggleChildren = () => {
        setMealPlanEntries(currentState => {
            const entryIndex = mealPlanEntries.findIndex(e => e.uuid === editEntry.uuid);
            currentState[entryIndex].displayChildren = !currentState[entryIndex].displayChildren;
            return currentState;
        })
    }


    /*
    * Toggle the selected state for the selected UUID and all child entries.
    */
    const selectEntry = (uuid) => {
        const isSelected = mealPlanEntries.find(e => e.uuid === uuid).selected;
        const uuids = [uuid, ...getChildKeys(mealPlanEntries, uuid, 'uuid', 'parent_entry_uuid')];

        /*
        * Loop over all the entries and update all the selected states.
        */
        setMealPlanEntries(
            mealPlanEntries.map(e => ({
                ...e,
                selected: uuids.includes(e.uuid)
                    ? !isSelected
                    : e.selected
            }))
        )
    }


    const generateEntry = (parentUuid, indentCells = []) => {
        return mealPlanEntries.filter(entry => entry.parent_entry_uuid === parentUuid)
            .map(entry => {
                const { uuid, name, ingredient_description, amount, size, scale, recipe_servings, displayChildren } = entry;

                let scaleLang = '';
                if (scale) {
                    const scaleLower = scale.toLowerCase()
                    scaleLang = intl.formatMessage({ id: `scale.${scaleLower}`, defaultMessage: scale })
                }

                if (['section'].includes(entry.type)) {
                    return [
                        <SectionTitle key='title' title={name} description={ingredient_description} />,
                        <SectionBody key='body'>
                            {generateEntry(uuid)}
                        </SectionBody>
                    ]
                }

                if (['group', 'group_public'].includes(entry.type)) {
                    return [
                        <SectionEntry
                            key={uuid}
                            title={name}
                            description={ingredient_description}
                            before={[...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '10px', borderLeft: '5px solid #b5bb00' }} />]}
                            style={{ color: '#b5bb00', paddingLeft: '5px' }}
                        />,
                        generateEntry(uuid, [...indentCells, <div key='indent' style={{ display: 'flex', flexGrow: '0', width: '10px', borderLeft: '5px solid #b5bb00' }} />])
                    ]
                }

                if (['food', 'food_child', 'food_parent'].includes(entry.type)) {
                    return [
                        <SectionEntry
                            key={uuid}
                            title={name}
                            description={`${amount} ${size}${scaleLang}`}
                            onClick={() => { setEditEntry(entry); setShowOptionsDialog(true) }}
                            before={indentCells}
                        >
                            <SectionEntryCell key='toggle' onClick={() => selectEntry(entry.uuid)}>
                                {entry.selected === true
                                    ? <FontAwesomeIcon icon={['fas', 'check-square']} style={{ fontSize: '20px', color: '#6e3076' }} />
                                    : <FontAwesomeIcon icon={['far', 'square']} style={{ fontSize: '20px' }} />
                                }
                            </SectionEntryCell>
                        </SectionEntry>,
                        displayChildren === true && generateEntry(uuid, [indentCells, <div key={uuid} style={{ display: 'flex', width: '20px' }} />])
                    ]
                }

                if (['recipe'].includes(entry.type)) {
                    return [
                        <SectionEntry
                            key={uuid}
                            title={name}
                            description={`${amount} ${size}${scaleLang}`}
                            onClick={() => { setEditEntry(entry); setShowOptionsDialog(true) }}
                            before={indentCells}
                        >
                            <SectionEntryCell key='toggle' onClick={() => selectEntry(entry.uuid)}>
                                {entry.selected === true
                                    ? <FontAwesomeIcon icon={['fas', 'check-square']} style={{ fontSize: '20px', color: '#6e3076' }} />
                                    : <FontAwesomeIcon icon={['far', 'square']} style={{ fontSize: '20px' }} />
                                }
                            </SectionEntryCell>
                        </SectionEntry>,
                        displayChildren === true && [
                            <div style={{ display: 'flex' }} key={uuid} onClick={() => { setEditEntry(entry); setShowOptionsDialog(true) }}>
                                {indentCells}
                                <div key='indent0' style={{ flexGrow: '0', width: '15px', borderLeft: '5px solid #6e3076' }} />
                                <div style={{ padding: '10px 10px 10px 0' }} >
                                    <b className='purple'><FormattedMessage id="app.recipeIngredients" defaultMessage="Recipe Ingredients" /></b>
                                    <div><FormattedMessage
                                        id="app.MakesXServings"
                                        defaultMessage="Makes {recipe_servings, plural, one {# serving} other {# servings}}"
                                        values={{ recipe_servings: recipe_servings }}
                                    /></div>
                                </div >
                            </div>,
                            ...generateEntry(uuid, [indentCells, <div key={uuid} style={{ display: 'flex', width: '20px', borderLeft: '5px solid #6e3076' }} />])
                        ]
                    ]
                }

                return null;
            });
    }

    const handleDialogSelect = option => {
        if (option === 'editPortion') {
            setShowPortionDialog(true);
        }
        if (option === 'toggleChildren') {
            toggleChildren();
        }
    }

    const handlePortionUpdate = (amount, scale) => {
        setMealPlanEntries(currentState => {
            let entryIndex = currentState.findIndex(e => e.uuid === editEntry.uuid);
            currentState[entryIndex].amount = amount;
            currentState[entryIndex].scale = scale;
            return [...currentState];
        })
        setShowPortionDialog(false);
        setEditEntry();
    }

    return [
        <Dialog key='options' open={showOptionsDialog} onClose={() => { setShowOptionsDialog(false) }} entry={editEntry} onSelect={handleDialogSelect} />,
        <PortionDialog key='portions' open={showPortionDialog} onClose={() => setShowPortionDialog(false)} entry={editEntry} onSave={handlePortionUpdate} />,
        generateEntry(null)
    ]
}