import React, { useState, useEffect, useCallback } from "react";
import { useParams,useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfirmationDialogStateContext } from "sfl-components/Dialog/ConfirmationDialogStateProvider";
import CardButton from 'sfl-components/SFLCards/CardButton';
import Title from 'sfl-components/Title';
import DateCycle from 'sfl-components/DateCycle';
import { useSFLApi } from 'api';

import NewEntrySelectDialog from './NewEntrySelectDialog';
import FoodLogContent from './FoodLogContent.desktop';
import FoodLogMealPlanForm from "views/FoodLog-MealPlanForm";
import FoodLogPastLogForm from "views/FoodLog-PastLogForm";
import FoodLogOtherForm from "views/FoodLog-OtherForm";
import ModeSelectButton from "./ModeSelectButton";


export default function FoodLog() {
    const { userUuid, userType } = useSelector(state => state.session);
    const { memberUuid = userUuid, date: dateParam } = useParams();
    const navigate = useNavigate();
    const { openDialog } = React.useContext(ConfirmationDialogStateContext);
    let [foodLogEntries, setFoodLogEntries] = useState([]);
    const [dateString, setDateString] = useState(dateParam || moment().format('YYYY-MM-DD'));
    const [editEntry, setEditEntry] = useState(null);
    const [selectedMode, setSelectedMode] = useState();
    const [showNewEntrySelect, setShowNewEntrySelect] = useState(false);
    const [selectedSection, setSelectedSection] = useState();
    const [showChildrenList, setShowChildrenList] = useState([]);
    const { get, del } = useSFLApi();
    const intl = useIntl();
    const [mode, setMode] = useState('day')


    const sections = [
        { key: 'breakfast', name: <FormattedMessage id="app.breakfast" defaultMessage="Breakfast" /> },
        { key: 'morning_snack', name: <FormattedMessage id="app.morningSnack" defaultMessage="Morning Snack" /> },
        { key: 'lunch', name: <FormattedMessage id="app.lunch" defaultMessage="Lunch" /> },
        { key: 'afternoon_snack', name: <FormattedMessage id="app.afternoonSnack" defaultMessage="Afternoon Snack" /> },
        { key: 'supper', name: <FormattedMessage id="app.supper" defaultMessage="Supper" /> },
        { key: 'evening_snack', name: <FormattedMessage id="app.eveningSnack" defaultMessage="Evening Snack" /> }
    ]


    for (let i = 0; i < foodLogEntries.length; i++) {
        foodLogEntries[i].displayChildren = showChildrenList.includes(foodLogEntries[i].uuid)

    }

    const fetchData = useCallback((dateString) => {
        const url = mode === 'week'
            ? `foodLogs?status=active&parent_status=active&member_uuid=${memberUuid}&dateFrom=${moment(dateString).subtract(6, 'days').format('YYYY-MM-DD')}&dateTo=${dateString}`
            : `foodLogs?status=active&parent_status=active&member_uuid=${memberUuid}&date=${dateString}`
        return get(url)
            .then(data => {
                setFoodLogEntries(data.foodLogs);
            })
            .catch(error => {
                console.log('error', error);
                setFoodLogEntries([]);
            })
    }, [get, mode, memberUuid]);

    useEffect(() => {
        fetchData(dateString);
    }, [fetchData, dateString]);

    const clearEditItem = () => {
        setEditEntry();
    }

    const referesh = () => {
        clearEditItem();
        fetchData(dateString);
    }

    const deleteFoodLogEntry = (entryUuid) => {
        del(`foodLogs/${entryUuid}`)
            .then(() => {
                toast.success(intl.formatMessage({ id: "foodLog.entryDeleted", defaultMessage: 'Entry Deleted' }));
                referesh();
            })
            .catch(error => {
                console.log(error);
                toast.error(intl.formatMessage({ id: "foodLog.deleteFail", defaultMessage: 'Deletion Failed.' }));
            });
    }

    const handleDeleteClick = (entryUuid) => {
        openDialog({
            open: true,
            title: intl.formatMessage({ id: "app.deleteConfirmation", defaultMessage: 'Delete Confirmation' }),
            description: intl.formatMessage({ id: "app.deleteConfirmationMessage", defaultMessage: 'Are you sure you want to delete this entry?' }),
            onSubmit: () => { deleteFoodLogEntry(entryUuid) }
        });
    }

    const handleEditClick = (uuid) => {
        setEditEntry(uuid);
        setSelectedMode('edit');
    }

    const handleModeSelect = (mode) => {
        setShowNewEntrySelect(false);
        setSelectedMode(mode);
    }
    const toggleChildren = (uuid) => {

        if (showChildrenList.includes(uuid)) {
            setShowChildrenList([...showChildrenList.filter(e => e !== uuid)])
        } else {
            setShowChildrenList([...showChildrenList, uuid])
        }
    }

    const FoodLogEntries = ({ section, date }) => {
        return <ul style={{ margin: 0 }}>
            {foodLogEntries.filter(e => e.section === section && e.parent_log_uuid === null)
                .filter(e => e.date.substr(0, 10) === date)
                .map((e, index) => {
                    const portion = e.portion ? ` - ${e.portion} ${e.scale}` : ''
                    return <li key={index}>
                        {e.name}{portion}
                    </li>
                })}
        </ul>
    }

    const FoodLogGrid = () => {
        const day1 = moment(dateString).subtract(6, 'days').format('YYYY-MM-DD')
        const day2 = moment(dateString).subtract(5, 'days').format('YYYY-MM-DD')
        const day3 = moment(dateString).subtract(4, 'days').format('YYYY-MM-DD')
        const day4 = moment(dateString).subtract(3, 'days').format('YYYY-MM-DD')
        const day5 = moment(dateString).subtract(2, 'days').format('YYYY-MM-DD')
        const day6 = moment(dateString).subtract(1, 'days').format('YYYY-MM-DD')
        const day7 = moment(dateString).format('YYYY-MM-DD')

        return <table style={{ width: '100%', maxWidth: '100%' }} border={0} cellPadding={15} cellSpacing={0}>
            <tr>
                <td></td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day1).format('dddd')}<br />{moment(day1).format('MMM Do')}</td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day2).format('dddd')}<br />{moment(day2).format('MMM Do')}</td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day3).format('dddd')}<br />{moment(day3).format('MMM Do')}</td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day4).format('dddd')}<br />{moment(day4).format('MMM Do')}</td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day5).format('dddd')}<br />{moment(day5).format('MMM Do')}</td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day6).format('dddd')}<br />{moment(day6).format('MMM Do')}</td>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{moment(day7).format('dddd')}<br />{moment(day7).format('MMM Do')}</td>
            </tr>
            {sections.map((section, index) => {
                return <tr key={section.key} style={{ backgroundColor: index % 2 ? '#ffffff' : '#ececec' }}>
                    <td style={{ fontWeight: 'bold', padding: '10px' }}>{section.name}</td>
                    <td><FoodLogEntries section={section.key} date={day1} /></td>
                    <td><FoodLogEntries section={section.key} date={day2} /></td>
                    <td><FoodLogEntries section={section.key} date={day3} /></td>
                    <td><FoodLogEntries section={section.key} date={day4} /></td>
                    <td><FoodLogEntries section={section.key} date={day5} /></td>
                    <td><FoodLogEntries section={section.key} date={day6} /></td>
                    <td><FoodLogEntries section={section.key} date={day7} /></td>
                </tr>
            })}
        </table>
    }


    if (mode === 'week') {
        return <>
            <div style={{ display: 'flex', marginBottom: '40px' }}>
                <Title>
                    <FormattedMessage id="app.foodLog" defaultMessage="Food Log" /><br />
                </Title>
                {userType !== 'member' && <ModeSelectButton
                    mode={mode}
                    onChange={setMode}
                />}
                {userType !== 'member' && <CardButton style={{ marginRight: '30px' }}
                    onClick={() => {
                        navigate(`/members/${memberUuid}/food-logs/list`)
                    }}>
                    <FontAwesomeIcon icon={['fas', 'history']} />
                    <FormattedMessage id="foodLog.viewHistory" defaultMessage="View History" />
                </CardButton>}
                <DateCycle mode='bubble' date={dateString} onChange={setDateString} />
            </div>
            <div >
                <FoodLogGrid />
            </div>
        </>
    }

    return <>
        <div style={{ display: 'flex', marginBottom: '40px' }}>
            <Title>
                <FormattedMessage id="app.foodLog" defaultMessage="Food Log" /><br />
            </Title>
            {userType !== 'member' && <ModeSelectButton
                mode={mode}
                onChange={setMode}
            />}
            {userType !== 'member' && <CardButton style={{ marginRight: '30px' }}
                onClick={() => {
                    navigate(`/members/${memberUuid}/food-logs/list`)
                }}>
                <FontAwesomeIcon icon={['fas', 'history']} />
                <FormattedMessage id="foodLog.viewHistory" defaultMessage="View History" />
            </CardButton>}
            <DateCycle mode='bubble' date={dateString} onChange={setDateString} />
        </div>
        <NewEntrySelectDialog open={showNewEntrySelect} onSelect={handleModeSelect} onClose={() => { setShowNewEntrySelect(false) }} />
        {selectedMode === 'mealPlan' && <FoodLogMealPlanForm memberUuid={memberUuid} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />}
        {selectedMode === 'pastLog' && <FoodLogPastLogForm memberUuid={memberUuid} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />}
        {selectedMode === 'other' && <FoodLogOtherForm memberUuid={memberUuid} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />}
        {selectedMode === 'edit' && <FoodLogOtherForm uuid={editEntry} section={selectedSection} date={dateString} refresh={referesh} onClose={setSelectedMode} />}
        {sections.map(section => {
            const entries = foodLogEntries.filter(log => log.section === section.key);

            const action = {
                title: <FormattedMessage id="foodLog.addFood" defaultMessage="Add Food" />,
                startIcon: <FontAwesomeIcon icon={['fas', 'plus-circle']} fixedWidth />,
                onClick: () => { setShowNewEntrySelect(true); setSelectedSection(section.key) }
            }

            return <FoodLogContent key={section.key} title={section.name} buttons={[action]} entries={entries} onEdit={handleEditClick} onDelete={handleDeleteClick} toggleChildren={toggleChildren} />
        })}
    </>
}