import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from "react-router-dom";

import CardButton from 'sfl-components/SFLCards/CardButton';
import BottomNavbar from "sfl-components/Navbars/BottomNavbar.js";

export default function NavBar(props) {
    const { activeTab = 1 } = props;
    const navigate = useNavigate();
    const { mealPlanUuid = '' } = useParams();

    const buttonStyles = {
        boxShadow: 'none',
        backgroundColor: 'white',
        color: '#444444',
        borderRadius: '0px',
        padding: '0',
        flexGrow: '1',
        marginLeft: '0px',
        height: '60px',
        borderTop: '1px solid #444444'
    }

    const activeButtonStyles = {
        ...buttonStyles,
        color: '#6e3076',
        borderTop: '1px solid #6e3076'
    }

    return <BottomNavbar style={{ height: '60px' }}>
        <CardButton key="button1" onClick={() => navigate(mealPlanUuid ? `/meal-plan/${mealPlanUuid}` : `/meal-plan`)} style={{ ...(activeTab === 1 ? activeButtonStyles : buttonStyles) }}>
            <div>
                <FontAwesomeIcon icon={['fas', 'newspaper']} style={{ marginRight: '0px' }} />
                <div style={{ fontSize: '12px' }}>Current Plan</div>
            </div>
        </CardButton>
        <CardButton key="button2" onClick={() => navigate(`/meal-plan/list/${mealPlanUuid}`)} style={{ ...(activeTab === 2 ? activeButtonStyles : buttonStyles) }}>
            <div>
                <FontAwesomeIcon icon={['fas', 'calendar-alt']} style={{ marginRight: '0px' }} />
                <div style={{ fontSize: '12px' }}>Past Plans</div>
            </div>
        </CardButton>
        <CardButton key="button3" onClick={() => navigate(mealPlanUuid ? `/meal-plan/grocery-list/${mealPlanUuid}` : `/meal-plan/grocery-list`)} style={{ ...(activeTab === 3 ? activeButtonStyles : buttonStyles) }}>
            <div>
                <FontAwesomeIcon icon={['fas', 'shopping-cart']} style={{ marginRight: '0px' }} />
                <div style={{ fontSize: '12px' }}>Grocery List</div>
            </div>
        </CardButton>
    </BottomNavbar>
}