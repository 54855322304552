import React from "react";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { useSelector } from "react-redux";

import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch } from "react-redux";
import { toggleSidebar } from 'store/appSettings';
import { clearSessionToken } from 'store/session'
import { Scrollbars } from 'react-custom-scrollbars';
import css from './styles.module.css'
import './scrollbar.css'

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { useIntl } from 'react-intl';
import SignOutIcon from 'assets/svgIcons/SignOut'

const useStyles = makeStyles(styles);

function Sidebar(props) {
    let language = useSelector(state => state.appSettings.language);
    const dispatch = useDispatch();
    const location = useLocation();
    const intl = useIntl();

    const handleDrawerToggle = () => {
        //setMobileOpen(!mobileOpen);
        dispatch(toggleSidebar());
    }

    const classes = useStyles();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        if (routeName === '/') {
            return false;
        }
        return location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    const { logo, routes } = props;
    var links = (
        <List style={{ paddingLeft: '20px', paddingTop: '20px' }}>
            {routes.map((prop, key) => {

                const menuClass = activeRoute(prop.path)
                    ? `${css.menuItem} ${css.active}`
                    : css.menuItem

                const path = prop.path.replace('{lang}', language)

                if (prop.external) {
                    return (
                        <a
                            href={path}
                            rel="noreferrer"
                            key={key}
                            style={{ fontWeight: 'bold' }}
                            target='_blank'
                            onClick={props.open ? handleDrawerToggle : () => { }}
                        >
                            <ListItem className={menuClass}>
                                {prop.iconComponent}
                                <ListItemText
                                    style={{ maxWidth: '144px', paddingLeft: '15px' }}
                                    primary={prop.id ? intl.formatMessage({ id: prop.id, defaultMessage: prop.name }) : prop.name}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </a>
                    );
                }

                return (
                    <NavLink
                        to={path}
                        key={key}
                        style={{ fontWeight: 'bold' }}
                        onClick={props.open ? handleDrawerToggle : () => { }}
                    >
                        <ListItem className={menuClass}>
                            {prop.iconComponent}
                            <ListItemText
                                style={{ maxWidth: '144px', paddingLeft: '15px' }}
                                primary={prop.id ? intl.formatMessage({ id: prop.id, defaultMessage: prop.name }) : prop.name}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
            <NavLink
                to="/"
                style={{ fontWeight: 'bold' }}
                onClick={props.open ? handleDrawerToggle : () => {
                    dispatch(clearSessionToken());
                }}
            >
                <ListItem className={css.menuItem}>
                    <SignOutIcon size={30} color='currentColor' />
                    <ListItemText
                        style={{ maxWidth: '144px', paddingLeft: '15px' }}
                        primary={intl.formatMessage({ id: 'app.signOut', defaultMessage: 'Sign Out' })}
                        disableTypography={true}
                    />
                </ListItem>
            </NavLink>
        </List>
    );



    var mobileLinks = (
        <List style={{ paddingLeft: '20px', paddingTop: '0px', paddingRight: '20px', paddingBottom: '400px' }}>
            {routes.map((prop, key) => {

                const menuClass = activeRoute(prop.path)
                    ? `${css.menuItemMobile} ${css.active}`
                    : css.menuItemMobile


                return (
                    <NavLink
                        to={prop.path}
                        key={key}
                        style={{ fontWeight: 'bold' }}
                        onClick={props.open ? handleDrawerToggle : () => { }}
                    >
                        <ListItem className={menuClass}>
                            {prop.iconComponent}
                            <ListItemText
                                style={{ maxWidth: '144px', paddingLeft: '15px' }}
                                primary={prop.id ? intl.formatMessage({ id: prop.id, defaultMessage: prop.name }) : prop.name}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}

        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <a
                href="/dashboard"
                className={classNames(classes.logoLink)}
                rel="noopener noreferrer"
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>
            </a>
        </div>
    );
    return <div>
        <Hidden mdUp implementation="css">
            <Drawer
                variant="temporary"
                open={props.open}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                BackdropProps={{
                    style: {
                        height: '100vh'
                    }
                }}
                PaperProps={{
                    style: {
                        width: 'calc(100% - 100px)',
                        maxWidth: '300px',
                        height: '100vh',
                        backgroundColor: '#b4bb00'
                    }
                }}

            >
                {brand}
                {mobileLinks}
            </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
            <Drawer
                anchor="left"
                variant="permanent"
                open
                PaperProps={{
                    style: { backgroundColor: '#b4bb00', borderRight: '0px solid black' }
                }}
            >
                {brand}
                <div style={{ width: '260px', height: 'calc(100vh - 75px)' }}>
                    <Scrollbars
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        renderTrackVertical={props => <div {...props} className="track-vertical" />}
                        style={{ width: '100%', height: '100%' }}
                    >
                        {links}
                    </Scrollbars>
                </div>
            </Drawer>
        </Hidden>
    </div>
}

export default Sidebar;