import { combineReducers } from 'redux'
import { articles } from './articles';
import { locations } from './locations';
import { foods } from './foods';
import { recipes } from './recipes';
import { foodCategories } from './foodCategories';
import { groceryCategories } from './groceryCategories';
import { recipeCategories } from './recipeCategories';
import { resourceCenterCategories } from './resourceCenterCategories';
import { resourceCenterPages } from './resourceCenterPages';
import { admins } from './admins';
import { consultants } from './consultants';
import { members } from './members';
import { medicalConditions } from './medicalConditions';
import { medicalConditionCategories } from './medicalConditionCategories';
import { recipeReviews } from './recipeReviews';
import { session } from './session';
import { templateMealPlans } from './templateMealPlans';
import { mealPlans } from './mealPlans';
import { memberMealPlans } from './memberMealPlans';
import { questionnaires } from './questionnaires';
import { foodLogs } from './foodLogs';
import { appSettings } from './appSettings';
import { generators } from './generators';
import { generatorEntries } from './generatorEntries';


const todoApp = combineReducers({
	appSettings,
	resourceCenterCategories,
	resourceCenterPages,
	articles,
	locations,
	recipes,
	foods,
	recipeReviews,
	templateMealPlans,
	mealPlans,
	foodLogs,
	memberMealPlans,
	admins,
	consultants,
	questionnaires,
	members,
	medicalConditions,
	medicalConditionCategories,
	foodCategories,
	groceryCategories,
	recipeCategories,
	session,
	generators,
	generatorEntries
})

export default todoApp