import React from 'react';

import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import CardIcon from "sfl-components/Card/CardIcon.js";

import { useNavigate } from "react-router-dom";
const CardButton = (props) => {

	const navigate = useNavigate();

	const handleClick = (event) => {
		if(props.to){
			navigate(props.to);
		}else if(props.onClick){
			props.onClick(event);
		}
		
	}
	const icon = props.icon ? <Icon style={{ width: '30px', height: '30px', fontSize: '20px', lineHeight: '30px' }}>{props.icon}</Icon> : null;

	return (<CardIcon color="success" style={{ background: '#6e3076', padding: '0', marginLeft: 'auto', marginTop: '-30px'}} onClick={handleClick}>
		<ListItem button>
			{icon}
			<div style={{ display: 'inline-block', height: '30px', fontSize: '20px', lineHeight: '30px' }}>{props.children}</div>
		</ListItem>
	</CardIcon>);
}

export default CardButton;