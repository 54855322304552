import React from "react"
import ActivityIcon from 'v2/assets/svgIcons/activityTypes/Activity'
import TextField from 'sfl-components/TextField'
import { FormattedMessage } from 'react-intl'

export default function RecipeDetails({ data, activityTypes = [], onChange, onSave }) {
    const { activity_type, description = '', steps = '', duration_minutes = '', distance_miles = '' } = data

    const activity = activityTypes.find(e => e.key == activity_type)

    return <div className='shadowBox' style={{ padding: '20px' }}>
        <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '10px' }}>
            <FormattedMessage id="activityDetails" defaultMessage="Activity details" />
        </div>
        <div style={{ fontSize: '14px', marginBottom: '20px' }}>
            <FormattedMessage id="activityDetails.description" defaultMessage="Enter one or more activity measurements" />
        </div>
        <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ flex: '1', maxWidth: '400px' }}>
                <div
                    className='greenButton'
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px' }}
                    onClick={() => { onChange('activity_type') }}
                >
                    <div><ActivityIcon type={activity_type} size='100' color='currentColor' /></div>
                    <div><FormattedMessage id={`activityType.${activity?.key}`} defaultMessage={activity?.key} /></div>
                </div>
            </div>
            <div style={{ flex: 4, maxWidth: '500px' }}>
                {activity.steps && <div style={{ marginBottom: '20px' }}>
                    <TextField
                        id="steps"
                        name="steps"
                        type="number"
                        style={{ border: '1px solid #cccccc' }}
                        label={<FormattedMessage id={`activityMeasurement.steps.description`} defaultMessage="Steps" />}
                        placeholder={'Ex: 5000'}
                        value={steps}
                        onChange={onChange}
                    />
                </div>}
                {activity.duration_minutes && <div style={{ marginBottom: '20px' }}>
                    <TextField
                        id="duration_minutes"
                        name="duration_minutes"
                        type="number"
                        style={{ border: '1px solid #cccccc' }}
                        label={<FormattedMessage id={`activityMeasurement.duration_minutes.description`} defaultMessage="Duration (minutes)" />}
                        placeholder={'Ex: 60'}
                        value={duration_minutes}
                        onChange={onChange}
                    />
                </div>}
                {activity.distance_miles && <div style={{ marginBottom: '20px' }}>
                    <TextField
                        id="distance_miles"
                        name="distance_miles"
                        type="number"
                        style={{ border: '1px solid #cccccc' }}
                        label={<FormattedMessage id={`activityMeasurement.distance_miles.description`} defaultMessage="Distance (miles)" />}
                        placeholder={'Ex: 2.5'}
                        value={distance_miles}
                        onChange={onChange}
                    />
                </div>}
                <div style={{ marginBottom: '20px' }}>
                    <TextField
                        id="description"
                        name="description"
                        style={{ border: '1px solid #cccccc' }}
                        label={<FormattedMessage id="activityMeasurement.description" defaultMessage="Description" />}
                        value={description}
                        multiline
                        minRows={2}
                        onChange={onChange}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', marginTop: '20px' }}>
                    <div className='greenButton' onClick={onSave}>
                        <FormattedMessage id="app.save" defaultMessage="Save" />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
