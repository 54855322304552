import React from "react";

export default function QuestionnaireCardTitle({ title, description }) {

    return <div>
        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>
            {title}
        </div>
        <div style={{ textAlign: 'center', fontWeight: 300, marginTop: '10px', marginBottom: '60px' }}>
            {description}
        </div>
    </div>
}