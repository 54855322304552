import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';



import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { FormattedMessage } from 'react-intl';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;




const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function CheckboxDialog(props) {

    const classes = useStyles();
    const { label, options, selectedOptions, onChange, open, onClose } = props;

    const handleClick = (uuid) => {
        if (selectedOptions.includes(uuid)) {
            onChange(selectedOptions.filter(e => e !== uuid))
        } else {
            onChange([...selectedOptions, uuid])
        }
    }


    return <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography className={classes.title}>
                    {label}
                </Typography>
                <Button autoFocus color="inherit" onClick={onClose}>
                    <FormattedMessage id="close" defaultMessage="Close" />
                </Button>
            </Toolbar>
        </AppBar>
        <List style={{ marginTop: '65px' }}>
            {options.map((e, index) => {
                return <Fragment key={index}>
                    <ListItem button onClick={() => { handleClick(e.uuid) }}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selectedOptions.includes(e.uuid)}
                        />
                        <ListItemText primary={e.name} secondary={e.description} />
                    </ListItem>
                    <Divider />
                </Fragment>
            })
            }
        </List>
    </Dialog>
}