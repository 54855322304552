import React from "react";

import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux'
import { setLanguage } from 'store/appSettings';
import { useApi } from 'api';


/*
test locations - 00000000-0000-0000-00ac-000000000001
dieppe - ac5990f8-6a37-4bcf-8b75-cc799e964620
fredericton - d7c6de54-df57-4dd3-bd32-b3bc07739f73
fredericton north - daff0808-1e34-4553-9f03-09bcb36dcdc8
quispamsis - 6190a04d-af4d-43b5-9634-99b3e3b1741f
riverview - 50bbd3b4-0fda-4810-9910-68be34dabed1
SJ - d04b7cc1-e973-4b62-9309-3fb55c78db19
SJ West - 62cd89f9-e1fd-4fee-b2de-54ae80618bae
Sussex - 4556f697-657b-4db6-a199-9047773ff1f5
*/

export default function TitleBar() {
    const dispatch = useDispatch();
    const { language = 'en' } = useSelector(state => state.appSettings);
    const session = useSelector(state => state.session);
    const langToggleUsers = ['00000000-0000-0000-0000-0000000000d1', 'e8569e25-5898-447a-b53b-4c775cbfaf1f']
    const { data: { profile = {} } } = useApi('profile')


    const multiLangLocations = [
        '00000000-0000-0000-00ac-000000000001',
        'ac5990f8-6a37-4bcf-8b75-cc799e964620',
        'd7c6de54-df57-4dd3-bd32-b3bc07739f73',
        'daff0808-1e34-4553-9f03-09bcb36dcdc8',
        '6190a04d-af4d-43b5-9634-99b3e3b1741f',
        '50bbd3b4-0fda-4810-9910-68be34dabed1',
        'd04b7cc1-e973-4b62-9309-3fb55c78db19',
        '62cd89f9-e1fd-4fee-b2de-54ae80618bae',
        '4556f697-657b-4db6-a199-9047773ff1f5'
    ]

    let allowMultiLingual = false
    if (session.userType === 'consultant') {
        allowMultiLingual = true
    } else if (langToggleUsers.includes(session.userUuid)) {
        allowMultiLingual = true
    } else if (multiLangLocations.includes(profile.location_uuid)) {
        allowMultiLingual = true
    }



    return <div style={{ display: 'flex', padding: '15px 40px 15px 0px' }}>
        <div style={{ flexGrow: '1' }} />
        {(allowMultiLingual || language === 'fr') && <>
            <Button onClick={() => { dispatch(setLanguage('fr')) }} className={language === 'fr' ? 'languageActive' : 'languageInactive'} style={{ marginRight: '20px' }}>FR</Button>
            <Button onClick={() => { dispatch(setLanguage('en')) }} className={language === 'en' ? 'languageActive' : 'languageInactive'} style={{ marginRight: '20px' }}>EN</Button>
        </>}
    </div>
}