import React, { useState, useEffect, useCallback } from "react";
import { useParams,useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';
import { useSFLApi } from 'api';
import { Row } from 'sfl-components/Containers2';
import Title from 'sfl-components/Title';
import CardButton from 'sfl-components/SFLCards/CardButton';

import bowlImg from 'assets/images/bowls/Paprika circle dishes 400px.png';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';


const DownloadGroceryListButton = (props) => {
    const { uuid } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { get } = useSFLApi();

    const getPDF = async () => {
        setIsLoading(true)
        try {
            const printResults = await get(`mealPlans/${uuid}/printGroceryList`)
            if (!printResults) {
                throw 'API file download error!';
            }
            window.open(printResults.download.path)
        }
        catch (error) {
            console.log('error', error)
            toast.error('Download Failed')
        }
        setIsLoading(false)
    }

    if (isLoading) {
        return <CardButton iconComponent={<CircularProgress size={20} style={{ color: 'white' }} />} style={{ backgroundColor: '#b999ba' }}>Print</CardButton>
    } else {
        return <CardButton icon={['far', 'file-pdf']} onClick={getPDF}>Print</CardButton>
    }
}

export default function GroceryListDesktop() {
    const [isLoading, setIsLoading] = useState(true);
    const [mealPlan, setMealPlan] = useState({});
    const [mealPlanEntries, setMealPlanEntries] = useState([]);
    const [groceryCategories, setGroceryCategories] = useState([]);
    const [error, setError] = useState();
    const { mealPlanUuid } = useParams();
    const { get } = useSFLApi();
    const navigate = useNavigate();


    const fetchData = useCallback(async () => {
        setError();

        try {
            let mealPlan;
            if (mealPlanUuid) {
                const results = await get(`mealPlans/${mealPlanUuid}`);
                mealPlan = results.mealPlan;
            } else {
                const results = await get(`mealPlans?status=active&sort=start_date:desc&limit=1`);
                mealPlan = results.mealPlans.pop();
            }
            setMealPlan(mealPlan);


            await get('groceryCategories?status=active&sort=name')
                .then(results => {
                    results.groceryCategories.push({
                        uuid: null,
                        name: 'Misc.'
                    })
                    setGroceryCategories(results.groceryCategories)
                })

            await get(`mealPlanEntries?status=active&meal_plan_uuid=${mealPlan.uuid}&sort=name`)
                .then(results => {
                    if (results.mealPlanEntries.length === 0) {
                        setError({
                            type: 'warning',
                            title: 'Warning',
                            description: 'The selected meal plan appears to be empty. Please contact your consultant for assistance.'
                        });
                    }

                    if (results.mealPlanEntries.filter(e => e.shopping_list === true).length === 0) {
                        setMealPlanEntries(results.mealPlanEntries.map(e => {
                            return {
                                ...e,
                                shopping_list: true
                            }
                        }))
                    } else {
                        setMealPlanEntries(results.mealPlanEntries)
                    }


                })


        }
        catch (error) {
            setError({
                type: 'error',
                title: 'Error',
                description: 'A error has occured while retreiving the selected meal plan. Please contact your consultant for assistance.'
            });
            console.log('error', error)
        }

        setIsLoading(false);
    }, [get, mealPlanUuid]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (error) {
        console.log(error)
    }

    if (isLoading) {
        return <></>
    }

    /*
    Used to enable all ingredients under a recipe if the recipe is active.
    Allows for users to easily add recipes to their shopping list without having to enable each ingredient.
    */
    const enableIngredients = parentUuid => {
        const childEntries = mealPlanEntries.filter(e => e.parent_entry_uuid === parentUuid);

        for (let i = 0; i < childEntries.length; i++) {
            const index = mealPlanEntries.findIndex(e => e.uuid === childEntries[i].uuid)
            mealPlanEntries[index].shopping_list = true;
            enableIngredients(childEntries[i].uuid)
        }
    }


    const activeRecipeUuids = mealPlanEntries.filter(e => e.type === 'recipe' && e.shopping_list === true).map(e => e.uuid);

    for (let i = 0; i < activeRecipeUuids.length; i++) {
        enableIngredients(activeRecipeUuids[i])
    }

    const list = groceryCategories.map(groceryCategory => {
        //Get all entries on the shopping list that are food for the current category
        let foodEntries = mealPlanEntries.filter(e => e.shopping_list === true && ['food', 'food_child', 'food_parent'].includes(e.type) && e.name !== '' && e.grocery_category_uuid === groceryCategory.uuid);

        if (foodEntries.length === 0) {
            return null
        }

        let uniqueItems = [...new Set(foodEntries.map(e => e.name.toLowerCase()))]
        //<div style={{ color: '#888888' }}>{entry.grocery_description}</div>


        return <div key={groceryCategory.uuid} style={{ padding: '20px 20px' }}>
            <div style={{ border: '1px solid #6e3076', borderRadius: '15px', overflow: 'hidden' }}>
                <div style={{ color: 'white', backgroundColor: '#6e3076', padding: '10px', fontWeight: 'bold' }}>{groceryCategory.name}</div>
                <div className='rowAlternating'>
                    {uniqueItems.map((entryName, index) => <div key={index} style={{ display: 'flex', flexDirection: 'column', padding: '10px', backgroundColor: 'white', borderBottom: '1px solid #eeeeee' }}>
                        <div style={{ fontWeight: '400', fontSize: '18px', color: 'black' }}>{entryName.charAt(0).toUpperCase() + entryName.slice(1)}</div>
                    </div>)}
                </div>
            </div>
        </div>
    }).filter(e => e !== null)

    if (list.length === 0) {
        return [
            <Row key='title'>
                <Title>{mealPlan.name === '' ? 'Grocery List' : mealPlan.name}</Title>
                <CardButton icon={['fas', 'backspace']} onClick={() => navigate(-1)}><FormattedMessage id="app.return" defaultMessage="Return" /></CardButton>
            </Row>,
            <div key='content' style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '32px', marginTop: '100px', marginBottom: '40px' }}><i>
                    <FormattedMessage id="questionnaire.emptyGroceryList" defaultMessage="Your grocery list appears to be empty!" />
                </i></div>
                <img src={bowlImg} alt='' style={{ maxWidth: '300px' }} />
            </div>
        ]
    }

    return [
        <Row key='title'>
            <Title>{mealPlan.name || 'Grocery List'}</Title>
            <CardButton icon={['fas', 'backspace']} onClick={() => navigate(-1)}><FormattedMessage id="app.return" defaultMessage="Return" /></CardButton>
            <DownloadGroceryListButton uuid={mealPlanUuid} />
        </Row>,
        <div key='content' style={{ display: 'flex', paddingTop: '20px' }}>
            <div style={{ flexGrow: '1' }}>
                {list.filter((e, index) => index % 3 === 0)}
            </div>
            <div style={{ flexGrow: '1' }}>
                {list.filter((e, index) => index % 3 === 1)}
            </div>
            <div style={{ flexGrow: '1' }}>
                {list.filter((e, index) => index % 3 === 2)}
            </div>
        </div>
    ]
}
