import React from "react";
import { IntlProvider } from 'react-intl';
import French from 'lang/fr.json';
import English from 'lang/en.json';

import { useSelector } from 'react-redux'

export default function LanguageProvider(props) {
    let { language = 'en' } = useSelector(state => state.appSettings);
    const pathArray = window.location.pathname.split('/')
    const lang = pathArray[1]

    let selectedLanguage = English;
    if (language === 'fr') {
        selectedLanguage = French
    }

    if (lang === 'en') {
        language = 'en'
        selectedLanguage = English
    }
    if (lang === 'fr') {
        language = 'fr'
        selectedLanguage = French
    }

    return <IntlProvider locale={language} messages={selectedLanguage}>{props.children}</IntlProvider>
}