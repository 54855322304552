import React, { useState } from "react"
import Card from '../components/Card'
import Button from '../components/Button'
import CardTitle from '../components/CardTitle'
import Error from '../components/Error'
import TextField from 'sfl-components/TextField/Outlined'
import { FormattedMessage, useIntl } from 'react-intl';


export default function QuestionnaireGoals({ data, onChange, onNext, onPrevious }) {
    const [showError, setShowError] = useState(false)
    const intl = useIntl()
    const { goal_more_energy = false, goal_lose_weight = false, goal_feel_better = false, goal_other = false, goal_notes = '' } = data

    const handleNext = () => {
        const formValid = [goal_more_energy, goal_lose_weight, goal_feel_better, goal_other].includes(true)

        if (goal_other === true && goal_notes === '') {
            setShowError(true)
            return
        }

        if (!formValid) {
            setShowError(true)
            return
        }

        onNext()
    }

    return <Card
        onNext={handleNext}
        onPrevious={onPrevious}
        error={showError && <Error onClick={() => { setShowError(false) }}>
            <FormattedMessage id="questionnaireV2.requiredFieldsError" defaultMessage="Please make a selection to continue." />
        </Error>}
    >
        <CardTitle
            title={<FormattedMessage id="questionnaireV2.goals.title" defaultMessage="What are your goals at Simply For life?" />}
            description={<FormattedMessage id="questionnaireV2.goals.titleDescription" defaultMessage="Please select all that apply." />}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.goals.moreEenergy" defaultMessage="I want to have more energy." />}
            selected={goal_more_energy}
            onClick={() => {
                onChange('goal_more_energy', !goal_more_energy)
                setShowError(false)
            }}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.goals.loseWeight" defaultMessage="I want to lose weight." />}
            selected={goal_lose_weight}
            onClick={() => {
                onChange('goal_lose_weight', !goal_lose_weight)
                setShowError(false)
            }}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.goals.feelBetter" defaultMessage="I want to feel better." />}
            selected={goal_feel_better}
            onClick={() => {
                onChange('goal_feel_better', !goal_feel_better)
                setShowError(false)
            }}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.goals.other" defaultMessage="Other" />}
            selected={goal_other}
            onClick={() => {
                onChange('goal_other', !goal_other)
                setShowError(false)
            }}
        />
        {goal_other && <div>
            <TextField
                multiline
                id="goal_notes"
                placeholder={intl.formatMessage({ id: "questionnaireV2.goals.otherDescription", defaultMessage: "Please tell us about your goals" })}
                value={goal_notes}
                minRows={3}
                onChange={onChange}
            />
        </div>}
    </Card>
}