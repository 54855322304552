import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LoginIcon from '@material-ui/icons/LockOpen';

import ColoredButton from 'sfl-components/Buttons/Button';
import logo from "assets/images/logo-1.png";
import sideImage from "assets/images/loginSideCentered.jpg";

import { useSFLApi } from 'api';
import { toast } from 'react-toastify';

import Layout from '../Layout';

import { FormattedMessage, useIntl } from 'react-intl';

export default function ForgotLoginForm() {
	const [state, setState] = useState({});
	const { first_name = '', last_name = '', email = '' } = state;
	const navigate = useNavigate();
	const { post } = useSFLApi();
	const intl = useIntl();



	const handleChange = (name, value) => {
		setState(currentState => ({ ...currentState, [name]: value }))
	}

	const preventDefault = (event) => {
		event.preventDefault();
	}

	const submitLogin = () => {
		post('actions/passwordReset', state)
			.then(() => {
				setState({})
				toast.success(intl.formatMessage({ id: "forgotLogin.emailSent", defaultMessage: "Reset Email Sent!" }), {
					toastId: 'ForgotLoginSuccess'
				})
			})
			.catch(() => toast.error(intl.formatMessage({ id: "forgotLogin.accountNotFound", defaultMessage: 'Account not found!' }), {
				toastId: 'ForgotLoginInvalidLogin'
			}));
	}

	return <Layout sideImage={sideImage}>
		<form onSubmit={preventDefault} action="">
			<img src={logo} alt='Simply For Life' style={{ width: '100%' }} />
			<div style={{ fontWeight: 'bold', marginTop: '40px' }}>
				<FormattedMessage id="forgotLogin.recoverYourAccount" defaultMessage="Recover Your Account" />
			</div>
			<Grid item xs={12} style={{ padding: '1rem 0' }}>
				<TextField
					id="firstName"
					label={intl.formatMessage({ id: "app.firstName", defaultMessage: "First Name" })}
					value={first_name}
					fullWidth
					variant="outlined"
					onChange={event => handleChange('first_name', event.target.value)}
				/>
			</Grid>
			<Grid item xs={12} style={{ padding: '1rem 0' }}>
				<TextField
					id="lastName"
					label={intl.formatMessage({ id: "app.lastName", defaultMessage: "Last Name" })}
					value={last_name}
					fullWidth
					variant="outlined"
					onChange={event => handleChange('last_name', event.target.value)}
				/>
			</Grid>
			<Grid item xs={12} style={{ padding: '1rem 0' }}>
				<TextField
					id="email"
					label={intl.formatMessage({ id: "app.email", defaultMessage: "Email" })}
					value={email}
					fullWidth
					variant="outlined"
					onChange={event => handleChange('email', event.target.value)}
				/>
			</Grid>
			<Grid item xs={12} style={{ padding: '1rem 0 2rem 0' }}>
				<ColoredButton color="purple" style={{ width: '100%' }} startIcon={<LoginIcon />} type='submit' onClick={submitLogin}>
					<FormattedMessage id="app.reset" defaultMessage="Reset" />
				</ColoredButton>
			</Grid>
			<hr />
			<Grid item xs={12} style={{ padding: '1rem 0', textAlign: 'center' }}>
				<Button onClick={() => navigate('sign-in')}>
					<FormattedMessage id="app.backToSignIn" defaultMessage="Back To Sign In" />
				</Button>
			</Grid>
		</form>
	</Layout>
}