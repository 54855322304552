import React from "react"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from 'sfl-components/TextField'
import { FormattedMessage } from 'react-intl'

export default function RecipeDetails({ data, scales = [], onChange, onSave }) {

    return <div className='shadowBox' style={{ padding: '20px' }}>
        <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '20px' }}>
            <FormattedMessage id="waterLogDetails" defaultMessage="Water Log details" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '500px' }}>
            <div style={{ display: 'flex', gap: '20px' }}>
                <div style={{ marginBottom: '20px' }}>
                    <TextField
                        id="amount"
                        name="amount"
                        type="number"
                        style={{ border: '1px solid #cccccc' }}
                        label={<FormattedMessage id="amount" defaultMessage="Amount" />}
                        placeholder={'Ex: 5'}
                        value={data.amount}
                        onChange={onChange}
                    />
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <RadioGroup name="scale" value={data.scale} onChange={(event) => { onChange('scale', event.target.value) }}>
                        {scales.map(scale => <FormControlLabel key={scale.key} value={scale.key} control={<Radio />} label={<FormattedMessage id={`v.scale.${scale.key}`} values={{ value: data.amount }} defaultMessage={scale.key} />} />)}
                    </RadioGroup>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', marginTop: '20px' }}>
                <div className='greenButton' onClick={onSave}>
                    <FormattedMessage id="app.save" defaultMessage="Save" />
                </div>
            </div>
        </div>
    </div>
}
