import React, { useEffect, useState } from "react";
import Card from '../components/Card'
import CardTitle from '../components/CardTitle'
import CircleCheck from 'v2/assets/svgIcons/CircleCheck'
import CircleDash from 'v2/assets/svgIcons/CircleDash'
import { useSFLApi } from 'api';
import TextField from 'sfl-components/TextField/Outlined';
import { FormattedMessage } from 'react-intl';


const Option = ({ title = "", selected = false, onClick }) => {
    if (selected) {
        return <div className='hoverUnderline' style={{ display: 'flex' }} onClick={onClick}>
            <div style={{ flexGrow: 1, padding: '10px', display: 'flex', alignItems: 'center', marginLeft: '30px', borderBottom: '1px solid #dddddd' }}>{title}</div>
            <div style={{ paddingRight: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #dddddd' }}>
                <CircleCheck
                    size={30}
                    color={"#17B169"}
                />
            </div>
        </div>
    }

    return <div style={{ display: 'flex' }} onClick={onClick}>
        <div className='hoverUnderline' style={{ flexGrow: 1, padding: '10px', display: 'flex', alignItems: 'center', marginLeft: '30px', borderBottom: '1px solid #dddddd' }}>{title}</div>
        <div style={{ paddingRight: '10px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #dddddd' }}>
            <CircleDash
                size={30}
                color={"#cccccc"}
            />
        </div>
    </div>
}

const OptionTitle = ({ title = "", hideToggle = false, children }) => {
    const [showOptions, setShowOptions] = useState(true)
    return <div>
        <div className='hoverUnderline' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #dddddd', padding: '10px', marginBottom: '10px' }} onClick={() => { setShowOptions(!showOptions) }}>
            <div style={{ fontWeight: 600 }}>{title}</div>
            {!hideToggle && <div style={{ color: '#aaaaaa' }}>{showOptions ? <FormattedMessage id="questionnaireV2.hideList" defaultMessage="Hide List" /> : <FormattedMessage id="questionnaireV2.showList" defaultMessage="Show List" />}</div>}
        </div>
        {showOptions && <div style={{ paddingBottom: '30px' }}>
            {children}
        </div>}
    </div>
}

export default function QuestionnaireDietRestrictions({ data, onChange, onNext, onPrevious }) {
    const [categories, setCategories] = useState([])
    const { get } = useSFLApi();
    const { medical_conditions = [], medical_notes = '', medications = '' } = data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await get(`medicalConditions?status=active&sort=name&questionnaire=true`)
                setCategories(results.medicalConditions)
            }
            catch (error) {
                console.log(error)
            }
        }

        fetchData()
    }, [get, setCategories])

    return <Card onNext={onNext} onPrevious={onPrevious}>
        <CardTitle
            title={<FormattedMessage id="questionnaireV2.medical.title" defaultMessage="Do you have any medical conditions?" />}
            description={<FormattedMessage id="questionnaireV2.medical.description" defaultMessage="Please select all applicable conditions." />}
        />
        <OptionTitle title="Conditions">
            {categories.map(category => {
                const isSelected = medical_conditions.includes(category.uuid)

                return <Option
                    key={category.uuid}
                    title={category.name}
                    selected={isSelected}
                    onClick={() => {
                        onChange('medical_conditions', isSelected ? [...medical_conditions.filter(uuid => uuid !== category.uuid)] : [...medical_conditions, category.uuid])
                    }}
                />
            })}
        </OptionTitle>
        <OptionTitle
            title={<FormattedMessage id="questionnaireV2.medical.medicalConditions" defaultMessage="Do you have any other medical conditions?" />}
            hideToggle={true}
        >
            <div style={{ marginLeft: '30px' }}>
                <TextField
                    multiline
                    id="medical_notes"
                    placeholder=""
                    value={medical_notes}
                    minRows={3}
                    onChange={onChange}
                />
            </div>
        </OptionTitle>
        <OptionTitle
            title={<FormattedMessage id="questionnaireV2.medical.medications" defaultMessage="What medications do you currently take?" />}
            hideToggle={true}
        >
            <div style={{ marginLeft: '30px' }}>
                <TextField
                    multiline
                    id="medications"
                    placeholder=""
                    value={medications}
                    minRows={3}
                    onChange={onChange}
                />
            </div>
        </OptionTitle>
    </Card>
}