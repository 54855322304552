import React from "react";

export const Card = ({ style, children }) => {
    return <div className='shadowBox' style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '5px', flex: 1, ...style }}>
        {children}
    </div>
}

export const CardTitle = ({ children }) => {
    return <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center', fontSize: '20px', color: 'white', padding: '10px', fontWeight: '700', textAlign: 'center', backgroundColor: '#17B169', borderRadius: '10px 10px 0px 0px' }}>
        {children}
    </div>
}

export const CardContent = ({ children }) => {
    return <div style={{ padding: '20px' }}>
        {children}
    </div>
}
