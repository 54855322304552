import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        width={size}
        viewBox="0 0 810 810"
    >
        <path fill={color} d="M 401.953125 316.125 C 427.210938 316.125 447.6875 295.652344 447.6875 270.390625 C 447.6875 245.136719 427.210938 224.660156 401.953125 224.660156 C 376.695312 224.660156 356.21875 245.136719 356.21875 270.390625 C 356.21875 295.652344 376.695312 316.125 401.953125 316.125 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 469.636719 384.007812 L 561.441406 196.5 C 566.996094 185.15625 562.300781 171.457031 550.960938 165.90625 C 539.617188 160.351562 525.921875 165.042969 520.367188 176.386719 L 441.554688 337.359375 L 359.523438 337.359375 L 280.710938 176.386719 C 275.15625 165.042969 261.460938 160.351562 250.117188 165.90625 C 238.777344 171.457031 234.082031 185.15625 239.632812 196.5 L 331.441406 384.007812 C 331.597656 384.328125 331.765625 384.644531 331.9375 384.960938 L 331.9375 511.152344 L 296.789062 688.128906 C 294.324219 700.511719 302.367188 712.550781 314.757812 715.015625 C 316.261719 715.3125 317.761719 715.457031 319.242188 715.457031 C 329.925781 715.457031 339.480469 707.929688 341.644531 697.046875 L 378.621094 511.152344 L 422.457031 511.152344 L 459.4375 697.046875 C 461.601562 707.929688 471.148438 715.457031 481.835938 715.457031 C 483.316406 715.457031 484.816406 715.3125 486.324219 715.011719 C 498.707031 712.550781 506.753906 700.511719 504.289062 688.125 L 469.140625 511.152344 L 469.140625 384.960938 C 469.3125 384.644531 469.480469 384.328125 469.636719 384.007812 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 655.640625 190.589844 L 154.402344 190.589844 L 154.402344 181.445312 L 655.640625 181.445312 L 655.640625 190.589844 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 690.046875 277.484375 L 662.605469 277.484375 L 662.605469 94.550781 L 690.046875 94.550781 L 690.046875 277.484375 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 729.023438 254.617188 L 701.582031 254.617188 L 701.582031 117.414062 L 729.023438 117.414062 L 729.023438 254.617188 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 147.4375 277.484375 L 120 277.484375 L 120 94.550781 L 147.4375 94.550781 L 147.4375 277.484375 " fillOpacity="1" fillRule="nonzero"/>
        <path fill={color} d="M 108.460938 254.617188 L 81.023438 254.617188 L 81.023438 117.414062 L 108.460938 117.414062 L 108.460938 254.617188 " fillOpacity="1" fillRule="nonzero"/>
    </svg>
}

export default SvgComponent