import React, { useEffect, useState } from "react";
import { ViewportContext } from 'sfl-components/ViewportProvider';
import Card from '../components/Card'
import CardTitle from '../components/CardTitle'
import Tabs from 'v2/components/Tabs'
import Tab from 'v2/components/Tab'
import { useSFLApi } from 'api';
import { FormattedMessage } from 'react-intl';


const Option = ({ title = "", isLiked = false, isDisliked, onLike, onDislike, onReset }) => {
    const { width } = React.useContext(ViewportContext);

    const tabFontSize = width > 600 ? '1em' : '0.8em'

    return <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', justifyContent: 'center', marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #dddddd' }} >
        <div style={{ flex: 1 }}>{title}</div>
        <div style={{ flex: 0, fontSize: tabFontSize }}>
            <Tabs>
                <Tab
                    name={<FormattedMessage id="questionnaireV2.loveIt" defaultMessage="Love it" />}
                    active={isLiked}
                    onClick={onLike}
                />
                <Tab
                    name={<FormattedMessage id="questionnaireV2.neutral" defaultMessage="Neutral" />}
                    activeColor="grey"
                    active={isDisliked === false && isLiked === false}
                    onClick={onReset}
                />
                <Tab
                    name={<FormattedMessage id="questionnaireV2.hateIt" defaultMessage="Hate it" />}
                    activeColor="red"
                    active={isDisliked}
                    onClick={onDislike}
                />
            </Tabs>
        </div>
    </div>
}

const OptionTitle = ({ title = "", children }) => {
    const [showOptions, setShowOptions] = useState(true)
    return <div>
        <div className='hoverUnderline' style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #dddddd', padding: '10px', marginBottom: '10px' }} onClick={() => { setShowOptions(!showOptions) }}>
            <div style={{ fontWeight: 600 }}>{title}</div>
            <div style={{ color: '#aaaaaa' }}>{showOptions ? <FormattedMessage id="questionnaireV2.hideList" defaultMessage="Hide List" /> : <FormattedMessage id="questionnaireV2.showList" defaultMessage="Show List" />}</div>
        </div>
        {showOptions && <div style={{ paddingBottom: '30px' }}>
            {children}
        </div>}
    </div>
}

export default function QuestionnaireDietRestrictions({ data, onChange, onNext, onPrevious }) {
    const [categories, setCategories] = useState([])
    const { get } = useSFLApi();
    const { likes = [], dislikes = [] } = data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await get(`foodCategories?status=active&sort=name&questionnaire=true`)
                setCategories(results.foodCategories)
            }
            catch (error) {
                console.log(error)
            }
        }

        fetchData()
    }, [get, setCategories])

    const handleReset = (categoryUuid) => {
        onChange('likes', [...likes.filter(uuid => uuid !== categoryUuid)])
        onChange('dislikes', [...dislikes.filter(uuid => uuid !== categoryUuid)])
    }

    const handleLike = (categoryUuid = '', isSelected = false) => {
        if (isSelected) {
            handleReset(categoryUuid)
        } else {
            onChange('likes', [...likes, categoryUuid])
            onChange('dislikes', [...dislikes.filter(uuid => uuid !== categoryUuid)])
        }
    }

    const handleDislike = (categoryUuid = '', isSelected = false) => {
        if (isSelected) {
            handleReset(categoryUuid)
        } else {
            onChange('dislikes', [...dislikes, categoryUuid])
            onChange('likes', [...likes.filter(uuid => uuid !== categoryUuid)])
        }
    }

    return <Card onNext={onNext} onPrevious={onPrevious}>
        <CardTitle
            title={<FormattedMessage id="questionnaireV2.preferences.title" defaultMessage="What are you food preferences?" />}
            description={<FormattedMessage id="questionnaireV2.preferences.description" defaultMessage="We'll try to pick recipe options that you'll love!" />}
        />
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.proteins" defaultMessage="Proteins" />}>
            {categories.filter(category => category.type === 'protein')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.dairy" defaultMessage="Dairy" />}>
            {categories.filter(category => category.type === 'dairy')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.dairyAlternatives" defaultMessage="Dairy Alternatives" />}>
            {categories.filter(category => category.type === 'dairy_alt')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.fruits" defaultMessage="Fruits" />}>
            {categories.filter(category => category.type === 'fruit')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.vegetables" defaultMessage="Vegetables" />}>
            {categories.filter(category => category.type === 'vegetable')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.nutsSeeds" defaultMessage="Nuts / Seeds" />}>
            {categories.filter(category => category.type === 'nut_seed')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title={<FormattedMessage id="questionnaireV2.restrictions.grain" defaultMessage="Grains" />}>
            {categories.filter(category => category.type === 'grain')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
        <OptionTitle title="Misc">
            {categories.filter(category => category.type === 'misc')
                .map(category => {
                    const isLiked = likes.includes(category.uuid)
                    const isDisliked = dislikes.includes(category.uuid)

                    return <Option
                        key={category.uuid}
                        title={category.name}
                        isLiked={isLiked}
                        isDisliked={isDisliked}
                        onLike={() => {
                            handleLike(category.uuid, isLiked)
                        }}
                        onDislike={() => {
                            handleDislike(category.uuid, isDisliked)
                        }}
                        onReset={() => {
                            handleReset(category.uuid)
                        }}
                    />
                })}
        </OptionTitle>
    </Card>
}