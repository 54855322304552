import React from "react";

import MealPlanList from "views/MemberMealPlans/List";
import GroceryList from "views/MemberMealPlans/GroceryList";
import CurrentMealPlan from "views/MemberMealPlans/Current";
import Profile from "views/Profile";
import DashboardPage from "v2/routes/dashboard";
import CurrentFoodLog from "views/FoodLogs/Current";
import Questionnaire from "views/Questionnaires/Form";
import ProgressTrackerDashboard from "views/ProgressTracker/Dashboard";
import Feedback from "v2/routes/feedback";
import RecipesV2 from "v2/routes/recipes";
import Recipe from "v2/routes/recipe";
import ActivityLog from "v2/routes/activity-log";
import ActivityLogEdit from "v2/routes/activity-log/edit";
import WaterLog from "v2/routes/water-log";
import WaterLogEdit from "v2/routes/water-log/edit";
import AppLayout from './AppLayout.js'
import EmptyLayout from './EmptyLayout.js'

import QuestionnaireSignup from 'views-public/QuestionnaireSignup';
import QuestionnaireV2 from "v2/routes/questionnaire";
import BetaDashboard from "v2/routes/dashboard";

import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

const router = createBrowserRouter([
	{
		element: <AppLayout sidebar='member' />,
		children: [
			{ index: true, element: <DashboardPage /> },
			{ path: 'dashboard', element: <DashboardPage /> },
			{ path: 'meal-plan', element: <CurrentMealPlan /> },
			{ path: 'meal-plan/:mealPlanUuid', element: <CurrentMealPlan /> },
			{ path: 'meal-plan/list', element: <MealPlanList /> },
			{ path: 'meal-plan/list/:mealPlanUuid', element: <MealPlanList /> },
			{ path: 'meal-plan/grocery-list', element: <GroceryList /> },
			{ path: 'meal-plan/grocery-list/:mealPlanUuid', element: <GroceryList /> },
			{ path: 'food-log', element: <CurrentFoodLog /> },
			{
				path: 'activity-log',
				children: [
					{ index: true, element: <ActivityLog /> },
					{ path: 'edit', element: <ActivityLogEdit /> },
					{ path: 'edit/:activityUuid', element: <ActivityLogEdit /> },
				]
			},
			{
				path: 'water-log',
				children: [
					{ index: true, element: <WaterLog /> },
					{ path: 'edit', element: <WaterLogEdit /> },
					{ path: 'edit/:waterLogUuid', element: <WaterLogEdit /> },
				]
			},
			{ path: 'recipe-categories', element: <RecipesV2 /> },
			{ path: 'recipe-categories/:categoryUuid', element: <RecipesV2 /> },
			{ path: 'recipe/:recipeUuid', element: <Recipe /> },
			{ path: 'progress-tracker', element: <ProgressTrackerDashboard /> },
			{ path: 'questionnaire', element: <Questionnaire /> },
			{ path: 'feedback', element: <Feedback /> },
			{ path: 'profile', element: <Profile /> },
			{ path: 'beta/dashboard', element: <BetaDashboard /> },
			{ path: '*', element: <DashboardPage /> }
		]
	},
	{ path: 'questionnaire-signup/:tokenUuid', element: <QuestionnaireSignup /> },
	{ path: 'member-questionnaire/:tokenUuid', element: <QuestionnaireV2 /> },
	{
		path: 'en',
		element: <EmptyLayout />,
		children: [
			{ index: true, element: <Navigate to="/" /> },
			{ path: 'questionnaire-signup/:tokenUuid', element: <QuestionnaireSignup /> },
			{ path: 'member-questionnaire/:tokenUuid', element: <QuestionnaireV2 /> }
		]
	},
	{
		path: 'fr',
		element: <EmptyLayout />,
		children: [
			{ index: true, element: <Navigate to="/" /> },
			{ path: 'questionnaire-signup/:tokenUuid', element: <QuestionnaireSignup /> },
			{ path: 'member-questionnaire/:tokenUuid', element: <QuestionnaireV2 /> }
		]
	}
])


export default function Member() {
	return <RouterProvider router={router} />
}