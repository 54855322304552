import React from "react"
import { ViewportContext } from 'sfl-components/ViewportProvider'
import ActivityIcon from 'v2/assets/svgIcons/activityTypes/Activity'
import { FormattedMessage, useIntl } from 'react-intl'
import { dynamicSort } from 'misc';


export default function ActivitySelect({ activityTypes = [], onSelect }) {
    const intl = useIntl();
    const { width } = React.useContext(ViewportContext);
    const isMobile = width <= 600

    const gridStyles =
        isMobile
            ? {}
            : { columnGap: '25px', rowGap: '50px' }

    const buttonStyles =
        isMobile
            ? { border: '1px solid #cccccc', borderRadius: '0' }
            : {}


    for (let i = 0; i < activityTypes.length; i++) {
        activityTypes[i].name = intl.formatMessage({ id: `activityType.${activityTypes[i].key}`, defaultMessage: activityTypes[i].key })
    }


    return <>
        {!isMobile && <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '20px', marginTop: '0px' }}><FormattedMessage id="selectAnActivityType" defaultMessage="Select an activity type" /></div>}
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill,minmax(150px,1fr))', ...gridStyles }}>
            {activityTypes.sort(dynamicSort('name')).map(({ key, name }) => {
                if (key === 'other') {
                    return
                }

                return <div
                    key={key}
                    className='blackButton'
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', ...buttonStyles }}
                    onClick={() => { onSelect(key) }}
                >
                    <div><ActivityIcon type={key} size='100' color='currentColor' /></div>
                    <div>{name}</div>
                </div>
            })}
            <div
                className='blackButton'
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '20px', ...buttonStyles }}
                onClick={() => { onSelect('other') }}
            >
                <div><ActivityIcon type='other' size='100' color='currentColor' /></div>
                <div><FormattedMessage id="activityType.other" defaultMessage="Other" /></div>
            </div>
        </div>
    </>
}
