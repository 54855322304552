import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "sfl-components/Sidebar";

import memberRoutes from "./memberRoutes.js";
import consultantRoutes from "./consultantRoutes.js";
import adminRoutes from "./adminRoutes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo from "assets/images/SFL-logo-black-trans.png";


import MainPanel from 'sfl-components/MainPanel'
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function BaseLayout({ sidebar }) {
    let sidebarOpen = useSelector(state => state.appSettings.sidebarOpen);
    const location = useLocation();
    const { scroll = 0 } = location.state || {}
    // styles
    const classes = useStyles();

    let routes = []
    if (sidebar == 'member') {
        routes = memberRoutes
    } else if (sidebar === 'consultant') {
        routes = consultantRoutes
    } else if (sidebar === 'admin') {
        routes = adminRoutes
    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (scroll > 0) {
                if ((document.body.scrollHeight - window.innerHeight) > 0) {
                    window.scroll(0, scroll)
                }
            } 
        }, 500)

        window.scroll(0, 0)
        return () => clearTimeout(delayDebounceFn)

    }, [scroll])


    return <div className={classes.wrapper}>
        <Sidebar
            routes={routes}
            logo={logo}
            open={sidebarOpen}
        />
        <div className={classes.mainPanel}>
            <MainPanel>
                <Outlet />
            </MainPanel>
        </div>
    </div>
}