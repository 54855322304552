import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import TextField from 'sfl-components/TextField/Standard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardButton from 'sfl-components/SFLCards/CardButton';
import MenuItem from '@material-ui/core/MenuItem';

import { useSFLApi } from 'api';

import BottomNavbar from "sfl-components/Navbars/BottomNavbar.js";
import NavBarTitle from "sfl-components/Navbars/NavBarTitle.js";
import MobileNavbar from "sfl-components/Navbars/MobileNavbar.js";
import SidebarToggle from "sfl-components/Navbars/SidebarToggle.js";
import { FormattedMessage, useIntl } from 'react-intl';



export default function MemberForm() {
	const [state, setState] = useState({});
	const { get, post } = useSFLApi();
	const intl = useIntl();

	const { username = '', password = '', language = 'en', appointment_reminder_sms = 'on' } = state;

	useEffect(() => {
		get('profile')
			.then(results => setState(results.profile))
			.catch(error => {
				console.log(error)
				toast.error(intl.formatMessage({ id: "profile.loadFail", defaultMessage: 'Error Loading Profile' }))
			})
	}, [get, intl]);

	const handleChange = (field, value) => {
		setState(currentState => ({ ...currentState, [field]: value }))
	}

	const handleSave = () => {
		post('profile', state)
			.then(() => {
				toast.success(intl.formatMessage({ id: "profile.profileSaved", defaultMessage: 'Profile Saved' }));
			})
			.catch(error => {
				console.log(error)
				toast.error(intl.formatMessage({ id: "profile.saveFail", defaultMessage: 'Error Saving Profile' }))
			})
	}


	return [
		<MobileNavbar key='MobileNavbar'>
			<SidebarToggle />
			<NavBarTitle><FormattedMessage id="profile.title" defaultMessage="Account Profile" /></NavBarTitle>
		</MobileNavbar>,
		<BottomNavbar key='BottomNavbar'>
			<CardButton key="save" onClick={handleSave} style={{ borderRadius: '0px', padding: '0', flexGrow: '1', marginLeft: '0px', height: '40px', backgroundColor: '#b4bb00' }}>
				<FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} style={{ marginRight: '10px' }} /> <FormattedMessage id="profile.saveProfile" defaultMessage="Save Profile" />
			</CardButton>
		</BottomNavbar>,
		<Paper key='content' style={{ padding: '30px 20px 100px 20px' }}>
			<div style={{ color: '#603373', fontSize: '32px', fontWeight: 'bold', marginBottom: '10px', marginTop: '50px' }}>
				<FormattedMessage id="loginInformation" defaultMessage="Login Information" />
			</div>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						id="username"
						label={intl.formatMessage({ id: "app.username", defaultMessage: "Username" })}
						value={username}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="password"
						label={intl.formatMessage({ id: "app.password", defaultMessage: "Password" })}
						type="password"
						value={password}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
			<div style={{ color: '#603373', fontSize: '32px', fontWeight: 'bold', marginBottom: '10px', marginTop: '50px' }}>
				<FormattedMessage id="preferences" defaultMessage="Preferences" />
			</div>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						id="language"
						select
						label={intl.formatMessage({ id: "app.preferredLanguage", defaultMessage: "Preferred Language" })}
						value={language || 'en'}
						onChange={handleChange}
					>
						<MenuItem key='en' value='en'><FormattedMessage id="app.english" defaultMessage="English" /></MenuItem>
						<MenuItem key='fr' value='fr'><FormattedMessage id="app.french" defaultMessage="French" /></MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="appointment_reminder_sms"
						select
						label={intl.formatMessage({ id: "app.appointmentReminderSMS", defaultMessage: "Appointment Reminder SMS" })}
						value={appointment_reminder_sms || 'on'}
						onChange={handleChange}
					>
						<MenuItem key='on' value='on'><FormattedMessage id="app.on" defaultMessage="On" /></MenuItem>
						<MenuItem key='off' value='off'><FormattedMessage id="app.off" defaultMessage="Off" /></MenuItem>
					</TextField>
				</Grid>
			</Grid>
		</Paper>
	]
}