import React, { useState, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { useSFLApi } from 'api';
import MobileNavbar from 'v2/components/MobileNavbar';
import ActivityForm from './ActivityForm'
import ActivitySelect from "./ActivitySelect";

const activityTypes = [
    { key: 'aerobics', steps: false, duration_minutes: true, distance_miles: false },
    { key: 'biking', steps: false, duration_minutes: true, distance_miles: true },
    { key: 'hiking', steps: true, duration_minutes: true, distance_miles: true },
    { key: 'running', steps: true, duration_minutes: true, distance_miles: true },
    { key: 'sports', steps: false, duration_minutes: true, distance_miles: false },
    { key: 'swimming', steps: false, duration_minutes: true, distance_miles: true },
    { key: 'walking', steps: true, duration_minutes: true, distance_miles: true },
    { key: 'weight_training', steps: false, duration_minutes: true, distance_miles: false },
    { key: 'yoga', steps: false, duration_minutes: true, distance_miles: false },
    { key: 'other', steps: true, duration_minutes: true, distance_miles: true }
]

export default function ActivityEditLoader() {
    const [isLoading, setIsLoading] = useState(true)
    const { activityUuid = '' } = useParams();
    const { get } = useSFLApi()
    const [state, setState] = useState({})
    const [selectedActivity, setSelectedActivity] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!activityUuid) {
                    setIsLoading(false)
                    return
                }

                const results = await get(`memberActivities/${activityUuid}`)

                const data = {
                    activity_type: results.activity.type,
                    description: results.activity.description,
                    ...Object.fromEntries(results.activity.data.map(e => ([e.type, e.value])))
                }
                setState(data)
                setSelectedActivity(results.activity.type)
                setIsLoading(false)
            }
            catch (error) {
                console.log('error', error)
            }
        }

        fetchData()
    }, [activityUuid, get, setState, setSelectedActivity, setIsLoading])

    if (isLoading === true) {
        return <div></div>
    }

    return <ActivityEdit
        defaultSelectedActivity={selectedActivity}
        defaultState={state}
    />
}


const ActivityEdit = ({ defaultSelectedActivity, defaultState = {} }) => {
    const [searchParams] = useSearchParams()
    const { activityUuid = '', memberUuid } = useParams();
    const date = searchParams.get('date')
    const navigate = useNavigate()
    const { post } = useSFLApi()
    const [state, setState] = useState(defaultState)
    const [selectedActivity, setSelectedActivity] = useState(defaultSelectedActivity)

    const handleChange = (key, val) => {
        if (key == 'activity_type') {
            setSelectedActivity(val)
        }

        setState(currentState => {
            return { ...currentState, [key]: val }
        })
    }

    const handleSave = async () => {
        try {
            await post(`memberActivities/${activityUuid}`, {
                "member_uuid": memberUuid,
                "datetime": date,
                "type": selectedActivity,
                "description": state.description,
                "data": [
                    {
                        "value": state.steps,
                        "type": "steps"
                    },
                    {
                        "value": state.duration_minutes,
                        "type": "duration_minutes"
                    },
                    {
                        "value": state.distance_miles,
                        "type": "distance_miles"
                    }
                ]
            })
            navigate(`..?date=${date}`)
        }
        catch (error) {
            console.log('error', error)
        }
    }

    return <>
        <MobileNavbar goBack={true} title={<FormattedMessage id="app.activityLog" defaultMessage="Activity Log" />} />
        {selectedActivity
            ? <ActivityForm
                activityTypes={activityTypes}
                clearSelectedActivity={() => { setSelectedActivity() }}
                data={state}
                onChange={handleChange}
                onSave={handleSave}
            />
            : <ActivitySelect
                activityTypes={activityTypes}
                onSelect={(act) => { handleChange('activity_type', act) }}
            />
        }
    </>
}
