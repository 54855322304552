import React from "react"
import { useIntl } from 'react-intl'
import Card from '../components/Card'
import Logo from 'v2/assets/images/logo.svg'


export default function QuestionnaireGoals({ locationEmail, onNext }) {
    const intl = useIntl()
    const language = intl.locale

    return <Card>
        <div style={{ marginBottom: '50px', textAlign: 'center' }}>
            <img src={Logo} style={{ width: '100%', maxWidth: '400px' }} />
        </div>
        {language === 'en'
            ? <div>
                <div style={{ marginBottom: '20px' }}>
                    We're excited to offer you this free initial health assessment to kickstart your wellness journey with Simply For Life. If you are a returning member - WELCOME BACK!
                </div>
                <div style={{ marginBottom: '20px' }}>
                    To get started, please fill out our questionnaire by clicking the   'Get Started' button.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Don't worry; it won't take long, and it's a fantastic way to take the first step towards a healthier you!
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Your answers will remain confidential and will only be used to design a program that aligns with your individual needs and aspirations. The more details you provide, the better we can customize your experience and help you achieve your health and wellness goals.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    We are here to guide and support you every step of the way. If you have any questions or need assistance while completing the questionnaire, please don't hesitate to reach out to our dedicated team at {locationEmail}
                </div>
                <div style={{ marginBottom: '20px' }}>
                    We can't wait to get started on this exciting journey with you!
                </div>
            </div>
            : <div>
                <div style={{ marginBottom: '20px' }}>
                    Nous sommes ravis de vous offrir cette évaluation de santé initiale gratuite pour démarrer votre parcours de bien-être avec Simply For Life. Si vous êtes un membre fidèle, BIENVENUE !
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Pour commencer, veuillez remplir notre questionnaire en cliquant sur le bouton « Commencer ».
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Ne t'inquiète pas; cela ne prendra pas longtemps et c'est une façon fantastique de faire le premier pas vers une meilleure santé !
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Vos réponses resteront confidentielles et seront utilisées uniquement pour concevoir un programme qui correspond à vos besoins et aspirations individuels. Plus vous fournissez de détails, mieux nous pouvons personnaliser votre expérience et vous aider à atteindre vos objectifs de santé et de bien-être.
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Nous sommes là pour vous guider et vous accompagner à chaque étape du processus. Si vous avez des questions ou avez besoin d'aide pour remplir le questionnaire, n'hésitez pas à contacter notre équipe dédiée à {locationEmail}
                </div>
                <div style={{ marginBottom: '20px' }}>
                    Nous avons hâte de commencer ce voyage passionnant avec vous !
                </div>
            </div>}

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '60px' }}>
            <div className='hoverUnderline' style={{ borderRadius: '25px', padding: '10px 30px', backgroundColor: '#17B169', color: 'white', cursor: 'pointer', fontWeight: 'bold' }} onClick={onNext}>
                {language === 'en' ? 'Get started' : 'Commencer'} &gt;&gt;
            </div>
        </div>
    </Card>
}