import React from "react";

import HomeIcon from 'assets/svgIcons/Home'
import MealPlanIcon from 'assets/svgIcons/MealPlan'
import ChartIcon from 'assets/svgIcons/Chart'
import FoodLogIcon from 'assets/svgIcons/FoodLog'
import RecipesIcon from 'assets/svgIcons/Recipes'
import QuestionnaireIcon from 'assets/svgIcons/Questionnaire'
import FeedbackIcon from 'assets/svgIcons/Feedback'
import ProfileIcon from 'assets/svgIcons/Profile'
import FoodZoneIcon from 'assets/svgIcons/Files'
import RunningIcon from 'assets/svgIcons/Running'
import BottleIcon from 'v2/assets/svgIcons/Bottle'

const dashboardRoutes = [
	{
		path: "dashboard",
		id: "app.dashboard",
		name: "Dashboard",
		iconComponent: <HomeIcon size={30} color='currentColor' />
	},
	{
		path: "meal-plan",
		id: "app.mealPlan",
		name: "Meal Plan",
		iconComponent: <MealPlanIcon size={30} color='currentColor' />
	},
	{
		path: "food-log",
		id: "app.foodLog",
		name: "Food Log",
		iconComponent: <FoodLogIcon size={30} color='currentColor' />
	},
	{
		path: "activity-log",
		id: "activityLog",
		name: "Activity Log",
		iconComponent: <RunningIcon size={30} color='currentColor' />
	},
	{
		path: "water-log",
		id: "app.waterTracker",
		name: "Water Tracker",
		iconComponent: <BottleIcon size={30} color='currentColor' />
	},
	{
		path: "recipe-categories",
		id: "app.recipes",
		name: "Recipes",
		iconComponent: <RecipesIcon size={30} color='currentColor' />
	},
	{
		path: "progress-tracker",
		id: "app.progressTracker",
		name: "Progress Tracker",
		iconComponent: <ChartIcon size={30} color='currentColor' />
	},
	{
		path: "questionnaire",
		id: "app.questionnaire",
		name: "Questionnaire",
		iconComponent: <QuestionnaireIcon size={30} color='currentColor' />
	},
	{
		path: "feedback",
		id: "app.feedback",
		name: "Feedback",
		iconComponent: <FeedbackIcon size={30} color='currentColor' />
	},
	{
		path: "profile",
		id: "app.profile",
		name: "Profile",
		iconComponent: <ProfileIcon size={30} color='currentColor' />
	},
	{
		path: "https://app.simplyforlife.com/files/food-zone-collection-{lang}.pdf",
		id: "foodZone",
		name: "Food Zone",
		iconComponent: <FoodZoneIcon size={30} color='currentColor' />,
		external: true
	}
];

export default dashboardRoutes;
