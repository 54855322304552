import React from "react";

import AerobicsIcon from 'v2/assets/svgIcons/activityTypes/Aerobics'
import BikingIcon from 'v2/assets/svgIcons/activityTypes/Biking'
import HikingIcon from 'v2/assets/svgIcons/activityTypes/Hiking'
import RunningIcon from 'v2/assets/svgIcons/activityTypes/Running'
import SportsIcon from 'v2/assets/svgIcons/activityTypes/Sports'
import SwimmingIcon from 'v2/assets/svgIcons/activityTypes/Swimming'
import WalkingIcon from 'v2/assets/svgIcons/activityTypes/Walking'
import WeightTrainingIcon from 'v2/assets/svgIcons/activityTypes/WeightTraining'
import YogaIcon from 'v2/assets/svgIcons/activityTypes/Yoga'
import OtherIcon from 'v2/assets/svgIcons/activityTypes/Other'


export default function ActivityIcon({ type, size, color }) {
    if (type === 'aerobics') {
        return <AerobicsIcon color={color} size={size} />

    } else if (type === 'biking') {
        return <BikingIcon color={color} size={size} />

    } else if (type === 'hiking') {
        return <HikingIcon color={color} size={size} />

    } else if (type === 'running') {
        return <RunningIcon color={color} size={size} />

    } else if (type === 'sports') {
        return <SportsIcon color={color} size={size} />

    } else if (type === 'swimming') {
        return <SwimmingIcon color={color} size={size} />

    } else if (type === 'walking') {
        return <WalkingIcon color={color} size={size} />

    } else if (type === 'weight_training') {
        return <WeightTrainingIcon color={color} size={size} />

    } else if (type === 'yoga') {
        return <YogaIcon color={color} size={size} />

    }

    return <OtherIcon color={color} size={size} />
}