import React, { useState } from 'react';
import css from './styles.module.css'
import Paper from "@material-ui/core/Paper";
import Pagination from 'sfl-components/Pagination';

export default function WeightPanel({ columns = [], rows = [] }) {
    const [page, setPage] = useState(1)

    if (rows.length === 0) {
        return null
    }

    return <div style={{ padding: '40px 20px' }}>
        <Paper elevation={3}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead style={{ backgroundColor: '#eeeeee' }}>
                    <tr>
                        {columns.map((column, columnIndex) => {
                            return <td key={columnIndex} style={{ padding: '20px 20px', fontSize: '20px', ...column.style }}>{column.value}</td>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows.slice((page - 1) * 10, page * 10).map((row, recordIndex) => {
                        return <tr key={recordIndex} className={css.row}>
                            {row.map((cell, cellIndex) => {
                                return <td key={cellIndex} style={{ padding: '10px 20px', borderBottom: '1px solid #cccccc', ...cell.style }}>
                                    {cell.value}
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </Paper>
        <Pagination
            page={page}
            count={rows.length}
            shape="rounded"
            onChange={setPage}
        />
    </div>
}