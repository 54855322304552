import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

import SearchBox from 'v2/components/SearchBox'
import Tabs from 'v2/components/Tabs'
import Tab from 'v2/components/Tab'
import { useSFLApi } from 'api';

import RecipeSearchResults from './RecipeSearchResults'
import Categories from './Categories'
import MobileNavbar from 'v2/components/MobileNavbar';

export default function Recipes() {
    const navigate = useNavigate();
    const location = useLocation();
    const { search: defSearch = '', tab: defTab = 'categories', tags: defTags = [], categories: defCategories = [] } = location.state || {}

    const [search, setSearch] = useState(defSearch || '')
    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])
    const { get } = useSFLApi();
    const [activeTab, setActiveTab] = useState(defTab || 'categories')
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await get(`recipeCategories?sort=name&status=active`)

                ReactDOM.unstable_batchedUpdates(() => {
                    //Filter recipes without names (missing translations probably)
                    setCategories(results.recipeCategories.filter(e => e.name !== '').map(e => {
                        return {
                            ...e,
                            selected: defCategories.includes(e.uuid)
                        }
                    }))
                    setTags([
                        { name: 'spicy', title: <FormattedMessage id="spicy" defaultMessage="Spicy" /> },
                        { name: 'vegetarian', title: <FormattedMessage id="vegetarian" defaultMessage="Vegetarian" /> },
                        { name: 'vegan', title: <FormattedMessage id="vegan" defaultMessage="Vegan" /> },
                        { name: 'pescatarian', title: <FormattedMessage id="pescatarian" defaultMessage="Pescatarian" /> },
                        { name: 'gluten_free', title: <FormattedMessage id="glutenFree" defaultMessage="Gluten Free" /> },
                        { name: 'dairy_free', title: <FormattedMessage id="dairyFree" defaultMessage="Dairy Free" /> }
                    ])
                    setIsLoading(false)
                })
            }
            catch (error) {
                console.log(error)
            }
        }

        fetchData()
    }, [])

    /*
    useEffect(() => {
        const selectedCategories = categories.filter(e => e.selected).map(e => e.uuid)
        //navigate(null, { replace: true, state: { search: search, tab: activeTab, categories: selectedCategories, scroll: window.scrollY } })

    }, [search, activeTab, categories])
    */


    const handleSearchUpdate = (newSearch) => {
        setSearch(newSearch)
    }

    const handleTabSelect = (category) => {
        setActiveTab(category)
    }

    const handleRecipeSelect = recipeUuid => {
        const selectedCategories = categories.filter(e => e.selected).map(e => e.uuid)
        navigate(null, { replace: true, state: { search: search, tab: activeTab, categories: selectedCategories, scroll: window.scrollY } })
        console.log('replaceStateScroll', window.scrollY)
        navigate(`/recipe/${recipeUuid}`)
    }

    const handleCategoryUpdate = updatedCategories => {
        setCategories(updatedCategories)
    }

    if (isLoading) {
        return null
    }

    return <>
        <MobileNavbar goBack={false} title={<FormattedMessage id="app.recipes" defaultMessage="Recipes" />} />
        <SearchBox value={search} onChange={handleSearchUpdate} onSearch={() => { handleTabSelect('search') }} />
        <Tabs style={{ marginBottom: '20px' }}>
            <Tab
                name={<FormattedMessage id="app.recipeCategories" defaultMessage="Categories" />}
                active={activeTab === 'categories'}
                onClick={() => { handleTabSelect('categories') }}
            />
            <Tab
                name={<FormattedMessage id="app.recipes" defaultMessage="Recipes" />}
                active={activeTab === 'search'}
                onClick={() => { handleTabSelect('search') }}
            />
            <Tab
                name={<FormattedMessage id="app.favourites" defaultMessage="Favourites" />}
                active={activeTab === 'favourites'}
                onClick={() => { handleTabSelect('favourites') }}
            />
        </Tabs>
        {activeTab === 'categories'
            ? <Categories
                categories={categories}
                onCategoryChange={handleCategoryUpdate}
                tags={tags}
                onTagChange={setTags}
                onSearch={() => { setActiveTab('search') }}
            />
            : <RecipeSearchResults
                search={search}
                favourites={activeTab === 'favourites'}
                categories={categories}
                tags={tags}
                onRecipeSelect={handleRecipeSelect}
            />
        }
    </>
}