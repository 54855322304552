import React, { useState } from "react";
import Button from '../components/Button'
import Card from '../components/Card'
import CardTitle from '../components/CardTitle'
import Error from '../components/Error'
import { FormattedMessage } from 'react-intl';


export default function QuestionnaireActivity({ data, onChange, onNext, onPrevious }) {
    const [showError, setShowError] = useState(false)
    const { activity_level = "" } = data

    const handleNext = () => {
        const formValid = activity_level !== ""

        if (!formValid) {
            setShowError(true)
            return
        }

        onNext()
    }

    return <Card
        onNext={handleNext}
        onPrevious={onPrevious}
        error={showError && <Error onClick={() => { setShowError(false) }}>
            <FormattedMessage id="questionnaireV2.requiredFieldsError" defaultMessage="Please make a selection to continue." />
        </Error>}
    >
        <CardTitle
            title={<FormattedMessage id="questionnaireV2.activity.title" defaultMessage="What is your activity level?" />}
            description={<FormattedMessage id="questionnaireV2.activity.description" defaultMessage="The more active you are, the bigger your meal plan portions!" />}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.activity.sedentary" defaultMessage="Sedentary" />}
            description={<FormattedMessage id="questionnaireV2.activity.sedentaryDescription" defaultMessage="Office job, very little activity / exercise." />}
            selected={activity_level === 'sedentary'}
            onClick={() => { onChange('activity_level', 'sedentary') }}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.activity.lightlyActive" defaultMessage="Lightly Active" />}
            description={<FormattedMessage id="questionnaireV2.activity.lightlyActiveDescription" defaultMessage="Light activity / exercise 1-2 days per week." />}
            selected={activity_level === 'lightly_active'}
            onClick={() => { onChange('activity_level', 'lightly_active') }}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.activity.moderatelyActive" defaultMessage="Moderately Active" />}
            description={<FormattedMessage id="questionnaireV2.activity.moderatelyActiveDescription" defaultMessage="Moderate activity / exercise 3-5 days per week." />}
            selected={activity_level === 'moderately_active'}
            onClick={() => { onChange('activity_level', 'moderately_active') }}
        />
        <Button
            title={<FormattedMessage id="questionnaireV2.activity.veryActive" defaultMessage="Very Active" />}
            description={<FormattedMessage id="questionnaireV2.activity.veryActiveDescription" defaultMessage="Heavy activity / exercise 6-7 days per week." />}
            selected={activity_level === 'very_active'}
            onClick={() => { onChange('activity_level', 'very_active') }}
        />
    </Card>
}