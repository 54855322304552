import React from "react";
import Button from '@material-ui/core/Button';

import FilledInput from '@material-ui/core/FilledInput';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormattedMessage } from 'react-intl';

export default function HeightTextField(props) {
    const updateValue = event => {
        props.onHeightChange(event.target.value);
    }


    const mode = props.scale === 'cm' ? 'cm' : 'ft';

    const setHeightToCm = () => {
        if (mode !== 'cm') {
            props.onHeightScaleChange('cm');
        }
    }

    const setHeightToFt = () => {
        if (mode === 'cm') {
            props.onHeightScaleChange('ft');
        }
    }

    const activeStyle = {
        minWidth: '0',
        padding: '0 10px',
        color: 'white',
        backgroundColor: '#202020'
    }

    const inactiveStyle = {
        minWidth: '0',
        padding: '0 10px'
    }

    const InputComponent = props.variant === 'standard' ? Input : FilledInput;

    return <FormControl fullWidth variant={props.variant === 'standard' ? 'standard' : 'filled'}>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <InputComponent
            id={props.id}
            type="text"
            fullWidth
            value={props.height}
            onChange={updateValue}
            endAdornment={
                <InputAdornment position="end">
                    <Button style={mode === 'cm' ? activeStyle : inactiveStyle} onClick={setHeightToCm}><FormattedMessage id="Cm" defaultMessage="Cm" /></Button>
                    <Button style={mode !== 'cm' ? activeStyle : inactiveStyle} onClick={setHeightToFt}><FormattedMessage id="Ft" defaultMessage="ft" /></Button>
                </InputAdornment>
            }
        />
        <FormHelperText id="component-helper-text">{props.helperText}</FormHelperText>
    </FormControl>
}