import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FoodPreferenceSelect from 'sfl-components/FoodPreferenceSelect/Mobile';
import MedicalConditionSelect from 'sfl-components/MedicalConditionSelect/Mobile';
import TextField from 'sfl-components/TextField/Standard';
import HeightTextField from "sfl-components/Inputs/HeightTextField";
import WeightTextField from "sfl-components/Inputs/WeightTextField";
import CardButton from 'sfl-components/SFLCards/CardButton';

import { useSFLApi } from 'api';
import DatePicker from 'sfl-components/Inputs/DatePicker';
import { FormattedMessage, useIntl } from 'react-intl';

const QuestionTitle = (props) => {
    return <div style={{ fontWeight: 'bold', marginTop: '20px', }}>{props.children}</div>
}
const StyledPaper = (props) => {
    return <Paper key='intro' style={{ padding: '30px 20px', marginBottom: '30px' }}>
        <div key='title' style={{ color: '#603373', fontSize: '32px', fontWeight: 'bold', marginBottom: '30px', marginTop: '10px', lineHeight: '2rem' }}>{props.title}</div>
        {props.children}
    </Paper>
}

const Question = React.memo((props) => {
    const { onChange, title, label, ...rest } = props

    return <Grid item xs={12} >
        <QuestionTitle>{title}</QuestionTitle>
        <TextField
            {...rest}
            fullWidth
            label={label}
            onChange={onChange}
        >{props.children}</TextField>
    </Grid>
}, (prevProps, nextProps) => prevProps.value === nextProps.value);
Question.displayName = 'QuestionnaireQuestion';

export default function QuestionnaireForm(props) {
    const { onSave } = props;
    const { tokenUuid } = useParams();
    const [state, setState] = useState({});
    const { post } = useSFLApi();

    const [allergies, setAllergies] = useState([]);
    const [likedFoods, setLikedFoods] = useState([]);
    const [dislikedFoods, setDislikedFoods] = useState([]);
    const [medicalConditions, setMedicalConditions] = useState([]);
    const intl = useIntl();


    const {
        employement_status = '',
        employment_activity_level = '',
        employment_company_name = '',
        height = '',
        height_scale = 'ft',
        current_weight = '',
        current_weight_scale = 'pounds',
        goal_weight = '',
        goal_weight_scale = 'pounds',
        exercise_level = '',
        family_doctor = '',
        last_checkup = '',
        medical_extra = '',
        average_food = '',
        what_other_programs = '',
        what_brings_you = '',
        household = '',
        pets = '',
        tune = '',
        gender = '',
        primary_goal = '',
        goal_celebration = '',
        restaurant_frequency = '',
        cook_frequency = '',
        allergies_restrictions_other = '',
        date_of_birth
    } = state;




    const handleChange = React.useCallback((key, value) => {
        setState(currentState => ({ ...currentState, [key]: value }));
    }, [setState]);

    const handleSave = React.useCallback(async () => {
        post(`questionnaires/token/${tokenUuid}`, {
            data: {
                ...state,
                allergies: allergies,
                liked_foods: likedFoods,
                disliked_foods: dislikedFoods,
                medical_conditions: medicalConditions
            }
        })
            .then(() => {
                onSave();
            })
            .catch(() => toast.error('Save Failed'));
    }, [post, tokenUuid, onSave, state, allergies, likedFoods, dislikedFoods, medicalConditions]);

    const intro = () => {
        return <Grid container spacing={3}>
            <Question
                id="what_brings_you"
                title={intl.formatMessage({ id: "questionnaire.whatBringsYou", defaultMessage: "What brings you to Simply For Life?" })}
                label={intl.formatMessage({ id: "questionnaire.whatBringsYou.label", defaultMessage: "What brought me is..." })}
                multiline
                minRows={1}
                maxRows={10}
                value={what_brings_you}
                onChange={handleChange}
            />
            <Question
                id="what_other_programs"
                title={intl.formatMessage({ id: "questionnaire.whatOtherPrograms", defaultMessage: "What have you tried previously and why didn't it work?" })}
                label={intl.formatMessage({ id: "questionnaire.whatOtherPrograms.label", defaultMessage: "Previous Experiences" })}
                helperText={intl.formatMessage({ id: "questionnaire.whatOtherPrograms.helper", defaultMessage: "Ex: Number of attempts, program names, results, etc." })}
                multiline
                minRows={1}
                maxRows={10}
                value={what_other_programs}
                onChange={handleChange}
            />
            <Question
                id="household"
                title={intl.formatMessage({ id: "questionnaire.household", defaultMessage: "How many people are there in your household and who usualy does the cooking?" })}
                label={intl.formatMessage({ id: "questionnaire.household.label", defaultMessage: "Household" })}
                helperText={intl.formatMessage({ id: "questionnaire.household.helper", defaultMessage: "Ex: Me, my wife, and our two kids (6 and 8). We usualy take turns preparing the meals." })}
                multiline
                minRows={1}
                maxRows={10}
                value={household}
                onChange={handleChange}
            />
            <Question
                id="pets"
                title={intl.formatMessage({ id: "questionnaire.pets", defaultMessage: "Do you have any pets?" })}
                label={intl.formatMessage({ id: "questionnaire.pets.label", defaultMessage: "Pets" })}
                helperText={intl.formatMessage({ id: "questionnaire.pets.helper", defaultMessage: "Make sure to share a picture to your consultant!" })}
                multiline
                minRows={1}
                maxRows={10}
                value={pets}
                onChange={handleChange}
            />
            <Question
                id="tune"
                title={intl.formatMessage({ id: "questionnaire.tune", defaultMessage: "What is your favourite tune?" })}
                value={tune}
                onChange={handleChange}
            />
            <Grid item xs={12}>
                <DatePicker
                    id="date_of_birth"
                    label={intl.formatMessage({ id: "questionnaire.dateOfBirth", defaultMessage: "When were you born?" })}
                    value={date_of_birth}
                    onChange={(date) => { handleChange('date_of_birth', date) }}
                />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.height" defaultMessage="How tall are you?" />
                </QuestionTitle>
                <HeightTextField
                    id="height"
                    label={intl.formatMessage({ id: "questionnaire.height.label", defaultMessage: "Height" })}
                    variant="standard"
                    height={height}
                    scale={height_scale}
                    onHeightChange={(updatedHeight) => handleChange('height', updatedHeight)}
                    onHeightScaleChange={(updatedScale) => handleChange('height_scale', updatedScale)}
                />
            </Grid>
            <Question
                id="gender"
                title={intl.formatMessage({ id: "questionnaire.gender", defaultMessage: "What is your gender?" })}
                label={intl.formatMessage({ id: "questionnaire.gender.label", defaultMessage: "Gender" })}
                helperText={intl.formatMessage({ id: "questionnaire.gender.helper", defaultMessage: "Gender is a factor for determining appropriate portion sizes. If you're unsure which option to pick, please select 'Other' and your consultant will work with you to determine the appropriate portion sizes." })}
                select
                value={gender}
                onChange={handleChange}
            >
                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='male' value='male'><FormattedMessage id="app.male" defaultMessage="Male" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='female' value='female'><FormattedMessage id="app.female" defaultMessage="Female" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='other' value='other'><FormattedMessage id="app.other" defaultMessage="Other" /></MenuItem>
            </Question>
        </Grid>
    }

    const intro2 = () => {
        return <Grid container spacing={3}>
            <Question
                id="primary_goal"
                title={intl.formatMessage({ id: "questionnaire.whatIsYourPrimaryGoal", defaultMessage: "What is your primary goal?" })}
                label={intl.formatMessage({ id: "questionnaire.whatIsYourPrimaryGoal.label", defaultMessage: "Primary goal" })}
                select
                value={primary_goal}
                onChange={handleChange}
            >
                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='moreEnergy' value='moreEnergy'><FormattedMessage id="questionnaire.moreEnergy" defaultMessage="I want to have more energy" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='feelBetter' value='feelBetter'><FormattedMessage id="questionnaire.feelBetter" defaultMessage="I want to feel better" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='loseWeight' value='loseWeight'><FormattedMessage id="questionnaire.loseWeight" defaultMessage="I want to lose weight" /></MenuItem>
            </Question>
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.whatIsYourCurrentWeight" defaultMessage="What's your current weight?" />
                </QuestionTitle>
                <WeightTextField
                    id="current_weight"
                    label={intl.formatMessage({ id: "questionnaire.whatIsYourCurrentWeight.label", defaultMessage: "Current Weight" })}
                    variant="standard"
                    weight={current_weight}
                    scale={current_weight_scale}
                    onWeightChange={(updatedWeight) => handleChange('current_weight', updatedWeight)}
                    onWeightScaleChange={(updatedWeightScale) => handleChange('current_weight_scale', updatedWeightScale)}
                />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.whatIsYourGoalWeight" defaultMessage="What's your goal weight?" />
                </QuestionTitle>
                <WeightTextField
                    id="goal_weight"
                    label={intl.formatMessage({ id: "questionnaire.whatIsYourGoalWeight.label", defaultMessage: "Goal Weight" })}
                    variant="standard"
                    weight={goal_weight}
                    scale={goal_weight_scale}
                    onWeightChange={(updatedWeight) => handleChange('goal_weight', updatedWeight)}
                    onWeightScaleChange={(updatedWeightScale) => handleChange('goal_weight_scale', updatedWeightScale)}
                />
            </Grid>
            <Question
                id="goal_celebration"
                title={intl.formatMessage({ id: "questionnaire.celebration", defaultMessage: "Once you reach your goal, how would you like to celebrate?" })}
                label={intl.formatMessage({ id: "questionnaire.celebration.label", defaultMessage: "Celebration" })}
                multiline
                minRows={1}
                maxRows={10}
                value={goal_celebration}
                onChange={handleChange}
            />
        </Grid>
    }

    const food = () => {
        return <Grid container spacing={3}>
            <Question
                id="average_food"
                title={intl.formatMessage({ id: "questionnaire.averageFood", defaultMessage: "What do you normally eat during the run of a day?" })}
                label={intl.formatMessage({ id: "questionnaire.averageFood.label", defaultMessage: "Daily meals" })}
                helperText={intl.formatMessage({ id: "questionnaire.averageFood.helper", defaultMessage: "For example: I normally have a muffin and a latte for breakfast and for lunch I have..." })}
                multiline
                minRows={1}
                maxRows={10}
                value={average_food}
                onChange={handleChange}
            />
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.whatDoYouLikeToEat" defaultMessage="What types of foods do you love to eat?" />
                </QuestionTitle>
                <FoodPreferenceSelect id='likedFoods' label={intl.formatMessage({ id: "Likes", defaultMessage: "Likes" })} variant="standard" selectedOptions={likedFoods} onChange={setLikedFoods} />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.whatDoYouNotLikeToEat" defaultMessage="What types of foods do you not like to eat?" />
                </QuestionTitle>
                <FoodPreferenceSelect id='dislikedFoods' label={intl.formatMessage({ id: "Dislikes", defaultMessage: "Dislikes" })} variant="standard" selectedOptions={dislikedFoods} onChange={setDislikedFoods} />
            </Grid>
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.whatAreYouAllergicTo" defaultMessage="What types of foods are you allergic to?" />
                </QuestionTitle>
                <FoodPreferenceSelect id='allergyFoods' label={intl.formatMessage({ id: "Allergies", defaultMessage: "Allergies" })} variant="standard" selectedOptions={allergies} onChange={setAllergies} />
            </Grid>
            <Question
                id="allergies_restrictions_other"
                title={intl.formatMessage({ id: "questionnaire.allergiesOther", defaultMessage: "Are there any other allergies or restrictions you would like to mention?" })}
                label={intl.formatMessage({ id: "questionnaire.allergiesOther.label", defaultMessage: "Other allergies and restrictions" })}
                multiline
                minRows={1}
                maxRows={10}
                value={allergies_restrictions_other}
                onChange={handleChange}
            />
        </Grid>
    }

    const exercise = () => {
        return <Grid container spacing={0}>
            <Question
                id="exercise_level"
                title={intl.formatMessage({ id: "questionnaire.howMuchDoYouExercise", defaultMessage: "How many hours per week do you exercise?" })}
                label={intl.formatMessage({ id: "questionnaire.howMuchDoYouExercise.label", defaultMessage: "Exercise" })}
                select
                value={exercise_level}
                onChange={handleChange}
            >
                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='none' value='none'><FormattedMessage id="questionnaire.exerciseLevel.none" defaultMessage="I don't exercise" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='low' value='low'><FormattedMessage id="questionnaire.exerciseLevel.low" defaultMessage="Under 2 hours" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='medium' value='medium'><FormattedMessage id="questionnaire.exerciseLevel.medium" defaultMessage="Between 2 to 5 hours" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='high' value='high'><FormattedMessage id="questionnaire.exerciseLevel.high" defaultMessage="More than 5 hours" /></MenuItem>
            </Question>
            <Question
                id="restaurant_frequency"
                title={intl.formatMessage({ id: "questionnaire.restaurantFrequency", defaultMessage: "On average, how often do you eat meals from restaurant?" })}
                label={intl.formatMessage({ id: "questionnaire.restaurantFrequency.label", defaultMessage: "Resutants per week" })}
                helperText={intl.formatMessage({ id: "questionnaire.restaurantFrequency.helper", defaultMessage: "This includes dine in, take out, etc." })}
                select
                value={restaurant_frequency}
                onChange={handleChange}
            >
                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'><FormattedMessage id="questionnaire.oneToFivePerWeek" defaultMessage="1-5 times per week" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'><FormattedMessage id="questionnaire.sixToTenPerWeek" defaultMessage="6-10 times per week" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='11' value='11'><FormattedMessage id="questionnaire.elevenOrMorePerWeek" defaultMessage="11+ times per week" /></MenuItem>
            </Question>
            <Question
                id="cook_frequency"
                title={intl.formatMessage({ id: "questionnaire.cookFrequency", defaultMessage: "On average, how often do you prepare meals at home?" })}
                label={intl.formatMessage({ id: "questionnaire.cookFrequency.label", defaultMessage: "Cooking per week" })}
                select
                value={cook_frequency}
                onChange={handleChange}
            >
                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'><FormattedMessage id="questionnaire.oneToFivePerWeek" defaultMessage="1-5 times per week" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'><FormattedMessage id="questionnaire.sixToTenPerWeek" defaultMessage="6-10 times per week" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='11' value='11'><FormattedMessage id="questionnaire.elevenOrMorePerWeek" defaultMessage="11+ times per week" /></MenuItem>
            </Question>
        </Grid>
    }

    const medical = () => {
        return <Grid container spacing={3}>
            <Question
                id="family_doctor"
                title={intl.formatMessage({ id: "questionnaire.familyDoctor", defaultMessage: "Who is your family doctor?" })}
                label={intl.formatMessage({ id: "questionnaire.familyDoctor.label", defaultMessage: "Family Doctor" })}
                value={family_doctor}
                onChange={handleChange}
            />
            <Question
                id="last_checkup"
                title={intl.formatMessage({ id: "questionnaire.lastCheckup", defaultMessage: "When was your last checkup?" })}
                label={intl.formatMessage({ id: "questionnaire.lastCheckup.label", defaultMessage: "Last Checkup" })}
                helperText={intl.formatMessage({ id: "questionnaire.lastCheckup.helper", defaultMessage: "An approximate date is sufficient if you're unsure." })}
                value={last_checkup}
                onChange={handleChange}
            />
            <Grid item xs={12} >
                <QuestionTitle>
                    <FormattedMessage id="questionnaire.MedicalConditionsSelect" defaultMessage="Do you suffer from any of the following medical conditions?" />
                </QuestionTitle>
                <MedicalConditionSelect id='medicalConditions' label={intl.formatMessage({ id: "questionnaire.MedicalConditions", defaultMessage: "Medical Conditions" })} variant="standard" selectedOptions={medicalConditions} onChange={(medicalConditionsList) => { setMedicalConditions(medicalConditionsList) }} />
            </Grid>
            <Question
                id="medical_extra"
                title={intl.formatMessage({ id: "questionnaire.MedicalConditionsExtra", defaultMessage: "Do you have any other medical conditions?" })}
                label={intl.formatMessage({ id: "questionnaire.MedicalConditionsExtra.label", defaultMessage: "Other conditions and medications" })}
                multiline
                minRows={1}
                maxRows={10}
                value={medical_extra}
                onChange={handleChange}
            />
        </Grid>
    }

    const employement = () => {
        return <Grid container spacing={3}>
            <Question
                id="employement_status"
                title="Which option best describes your employement status?"
                label="Employement Status"
                select
                value={employement_status}
                onChange={handleChange}
            >
                <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='full_time' value='full_time'><FormattedMessage id="questionnaire.employedFullTime" defaultMessage="Employed Full time" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='part_time' value='part_time'><FormattedMessage id="questionnaire.employedPartTime" defaultMessage="Employed Part time" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='education' value='education'><FormattedMessage id="questionnaire.unemployedEduction" defaultMessage="Unemployed in order to further my eduction" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='illness' value='illness'><FormattedMessage id="questionnaire.unemployedDueToDisbility" defaultMessage="Unemployed due to disbility, stress, sick leave, etc." /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='unemployed' value='unemployed'><FormattedMessage id="questionnaire.temporarilyUnemployed" defaultMessage="Temporarily unemployed, but looking for work" /></MenuItem>
                <MenuItem style={{ whiteSpace: 'normal' }} key='retired' value='retired'><FormattedMessage id="questionnaire.retired" defaultMessage="Retired (congratulations!)" /></MenuItem>
            </Question>
            {['full_time', 'part_time'].includes(employement_status) &&
                <>
                    <Question
                        id="employment_activity_level"
                        title={intl.formatMessage({ id: "questionnaire.employmentActivityLevel", defaultMessage: "How physically engaged are you at work?" })}
                        label={intl.formatMessage({ id: "questionnaire.employmentActivityLevel.label", defaultMessage: "Physical Engagement" })}
                        select
                        value={employment_activity_level}
                        onChange={handleChange}
                    >
                        <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='low' value='low'><FormattedMessage id="questionnaire.employmentActivityLevel.low" defaultMessage="Very little (mostly sitting)" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='medium' value='medium'><FormattedMessage id="questionnaire.employmentActivityLevel.medium" defaultMessage="A moderate amount (mostly walking and standing)" /></MenuItem>
                        <MenuItem style={{ whiteSpace: 'normal' }} key='high' value='high'><FormattedMessage id="questionnaire.employmentActivityLevel.high" defaultMessage="Heavy Activity (manual labor)" /></MenuItem>
                    </Question>
                    <Question
                        id="employment_company_name"
                        title={intl.formatMessage({ id: "questionnaire.companyName", defaultMessage: "What's the name of your company?" })}
                        label={intl.formatMessage({ id: "questionnaire.companyName.label", defaultMessage: "Company Name" })}
                        value={employment_company_name}
                        onChange={handleChange}
                    />
                </>
            }
        </Grid>
    }


    return <div style={{ backgroundColor: 'rgb(238, 238, 238)' }}>
        <StyledPaper key='intro' title={intl.formatMessage({ id: "questionnaire.tellUsAboutYourself", defaultMessage: "Tell us about yourself" })}>
            {intro()}
        </StyledPaper>,
        <StyledPaper key='intro2' title={intl.formatMessage({ id: "questionnaire.whatAreYourGoals", defaultMessage: "What are you goals?" })}>
            {intro2()}
        </StyledPaper>,
        <StyledPaper key='food' title={intl.formatMessage({ id: "questionnaire.whatAreYourPreferences", defaultMessage: "What are you food preferences?" })}>
            {food()}
        </StyledPaper>,
        <StyledPaper key='exercise' title={intl.formatMessage({ id: "questionnaire.whatHabitsDoYouHave", defaultMessage: "What habits do you have?" })}>
            {exercise()}
        </StyledPaper>,
        <StyledPaper key='medical' title={intl.formatMessage({ id: "questionnaire.medicalInformation", defaultMessage: "Medical Information" })}>
            {medical()}
        </StyledPaper>,
        <StyledPaper key='employement' title={intl.formatMessage({ id: "questionnaire.whatDoYouDoForALiving", defaultMessage: "What do you do for a living?" })}>
            {employement()}
        </StyledPaper>
        <div style={{ textAlign: 'center', padding: '20px 0 80px 0' }}>
            <CardButton onClick={handleSave}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} />
                <FormattedMessage id="questionnaire.saveQuestionnaire" defaultMessage="Save Questionnaire" />
            </CardButton>
        </div>
    </div>
}