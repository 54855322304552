import * as React from "react"
import Tag from 'v2/components/Tags/Tag'
import Clock from 'v2/assets/svgIcons/Clock'

export default function TimeTag({ minutes }) {
    return minutes
        ? <Tag
            colorLeft="#CC5500"
            colorRight="#DAA06D"
            Icon={Clock}
            title={`${minutes} Minutes`}
        />
        : null
}