import React from "react";
import { ViewportContext } from 'sfl-components/ViewportProvider';
import { FormattedMessage } from 'react-intl';

export default function QuestionnaireCard({ children, error, onNext, onPrevious }) {
    const { width } = React.useContext(ViewportContext);

    const paddingTopPx = width > 600 ? '100px' : '0px'
    const paddingPx = width > 600 ? '50px' : '20px'

    return <div style={{ display: 'flex', justifyContent: 'center', paddingTop: paddingTopPx }}>
        <div className={width > 600 ? 'shadowBox' : null} style={{ width: '100%', maxWidth: '600px', padding: paddingPx }}>
            {children}
            <div style={{ marginTop: '30px' }}>
                {error}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row-reverse', marginTop: '30px' }}>
                {onNext && <div className='hoverUnderline' style={{ borderRadius: '25px', padding: '10px 30px', backgroundColor: '#17B169', color: 'white', cursor: 'pointer', fontWeight: 'bold' }} onClick={onNext}>
                    <FormattedMessage id="questionnaireV2.next" defaultMessage="Next" /> &gt;&gt;
                </div>}
                {onPrevious && <div className='hoverUnderline' style={{ color: '#aaaaaa', cursor: 'pointer' }} onClick={onPrevious}>
                    <FormattedMessage id="questionnaireV2.previous" defaultMessage="Previous" />
                </div>}
            </div>
        </div>
    </div>
}