import * as React from "react"
import Tag from 'v2/components/Tags/Tag'
import Vegetarian from 'v2/assets/svgIcons/Vegetarian'

export default function PescatarianTag() {
    return <Tag
        colorLeft="#00563B"
        colorRight="#17B169"
        Icon={Vegetarian}
        title="Pescatarian"
    />
}