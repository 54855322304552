import React from "react";
import LatestNews from './LatestNews';
import Socials from './Socials';
import { FormattedMessage } from 'react-intl';
import MobileNavbar from 'v2/components/MobileNavbar';
import Appointments from "./Appointments";


export default function RecipeDetails() {
    return <>
        <MobileNavbar title={<FormattedMessage id="app.dashboard" defaultMessage="Dashboard" />} />
        <Appointments />
        <div style={{ marginBottom: '100px' }}>
            <LatestNews />
        </div>
        <div>
            <Socials />
        </div>
    </>
}