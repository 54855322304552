import * as React from "react"

function SvgComponent({ size, color }) {
    return <svg
        height={size}
        viewBox="0 0 512 512"
        width={size}
    >
        <path fill={color} d="M307.39,0H217.6a64,64,0,0,0-64,64V345.6a64,64,0,0,0,64,64H396.8a64,64,0,0,0,64-64v-192ZM256,204.8h51.2a12.8,12.8,0,1,1,0,25.6H256a12.8,12.8,0,1,1,0-25.6Zm102,128H256a12.8,12.8,0,1,1,0-25.6H358a12.8,12.8,0,1,1,0,25.6Zm.38-51.2H256a12.8,12.8,0,1,1,0-25.6H358.4a12.8,12.8,0,0,1,0,25.6Zm0-115.2a64,64,0,0,1-64-64V30.9L429.9,166.4Z" />
        <path fill={color} d="M281.6,435.2H166.4A38.44,38.44,0,0,1,128,396.8V64.09a63.83,63.83,0,0,0-25.6,51.11V396.8a64,64,0,0,0,64,64H345.6a63.83,63.83,0,0,0,51.11-25.6H281.6Z" />
        <path fill={color} d="M294.4,486.4H115.2A38.44,38.44,0,0,1,76.8,448V115.29A63.83,63.83,0,0,0,51.2,166.4V448a64,64,0,0,0,64,64H294.4a63.83,63.83,0,0,0,51.11-25.6Z" />
    </svg>
}

export default SvgComponent