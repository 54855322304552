import React, { useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import { Paper } from '@material-ui/core'


export default function ImageModal({ imageUrl, children }) {
    const [isOpen, setIsOpen] = useState(false)

    return [
        <div key="image" onClick={() => { setIsOpen(true) }}>
            {children}
        </div>,
        <Dialog key="dialog" open={isOpen} onClose={() => { setIsOpen(false) }}
            maxWidth='xl'
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    maxHeight: '100vh'
                }
            }}>
            <Paper>
                <img src={imageUrl} style={{ maxHeight: '100vh', display: 'block' }} alt='' onClick={() => { setIsOpen(false) }} />
            </Paper>
        </Dialog>
    ]
}