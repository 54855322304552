import React from "react";

export default function IngredientList({ ingredients = [] }) {
    //TODO: Kind of dirty
    const ingSort = (ingA, ingB) => {
        const a = ingA.amount
        const b = ingB.amount
        // equal items sort equally
        if (a === b) {
            return 0;
        }

        // nulls sort after anything else
        if (a === '') {
            return 1;
        }
        if (b === '') {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first

        return 0
    }

    const tableData = (parentUuid = null) => {
        return ingredients.filter((e) => e.parent_uuid === parentUuid).sort(ingSort)
            .map((ingredient, index) => {
                if (ingredient.type === 'group') {
                    return [
                        <div key={index} style={{ fontWeight: 600, marginTop: '20px' }}>
                            {ingredient.name}
                        </div>,
                        tableData(ingredient.uuid)
                    ]
                }
                return <div key={index} style={{ display: 'flex', gap: '5px' }}>
                    <div style={{ borderBottom: '1px solid #eeeeee', padding: '5px 10px', marginBottom: '5px', flex: 1 }}>
                        <div style={{ fontWeight: 400 }}>{ingredient.amount} {ingredient.size}{ingredient.scale} {ingredient.name}</div>
                        <div style={{ fontSize: '12px' }}>{ingredient.description}</div>
                    </div>
                </div>
            })
    }


    return <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        {tableData().flat()}
    </div>
}
