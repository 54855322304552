import React, { useState } from "react";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage, useIntl } from 'react-intl';

import TextField from 'sfl-components/TextField/Outlined';
import { useSFLApi } from 'api';

import bowlImg from 'assets/images/bowls/Bal_Leaves_circle_dishes_400px.png';


export default function MemberFeedback() {
    const [state, setState] = useState({});
    const [formComplete, setFormComplete] = useState(false);
    const { post } = useSFLApi();
    const intl = useIntl();

    const { general_rating = '', general_feedback = '' } = state;

    const handleChange = (field, value) => {
        setState(currentState => ({ ...currentState, [field]: value }))
    }

    const handleSave = async () => {
        try {
            await post('feedbacks', state)
            setFormComplete(true)
            toast.success(intl.formatMessage({ id: "feedback.success", defaultMessage: 'Your feedback has been submitted!' }))
        }
        catch (error) {
            console.log(error)
            toast.error(intl.formatMessage({ id: "feedback.fail", defaultMessage: 'An error has occured.' }))
        }
    }


    if (formComplete) {
        return <div style={{ textAlign: 'center' }} onClick={() => { setFormComplete(false) }}>
            <div style={{ fontSize: '32px', marginTop: '100px', marginBottom: '40px' }}>
                <i><FormattedMessage id="feedback.yourFeedbackHasBeenSubmitted" defaultMessage="Your feedback has been submitted!" /></i>
            </div>
            <img src={bowlImg} alt='' style={{ maxWidth: '300px' }} />
        </div>
    }


    return <div className='shadowBox' style={{ padding: '20px' }}>
        <div style={{ fontWeight: 700, fontSize: '20px', marginBottom: '10px' }}>
            <FormattedMessage id="feedback.title" defaultMessage="Member Feedback" />
        </div>
        <div>
            <FormattedMessage
                id="feedback.intro"
                defaultMessage="Simply For Life wouldn't be where it is today without our members. 
                                    We don’t believe in perfection, but feedback is key to improve or services and your experience 
                                    If yould like to provide some feedback (good or bad), we'd love to hear from you."
            />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid #eeeeee', marginTop: '50px' }}>
            <div style={{ flex: 1, minWidth: '400px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                {intl.formatMessage({ id: "feedback.howWouldYouRateUs", defaultMessage: "How would you rate your experience at Simply For Life?" })}
            </div>
            <div style={{ flex: 1, minWidth: '400px' }}>
                <TextField
                    id="general_rating"
                    name="general_rating"
                    select
                    value={general_rating}
                    onChange={handleChange}
                >
                    <MenuItem style={{ whiteSpace: 'normal' }} key='empty' value=''></MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='1' value='1'>1 (<FormattedMessage id="feedback.theWorst" defaultMessage="The Worst" />)</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='2' value='2'>2</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='3' value='3'>3</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='4' value='4'>4</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='5' value='5'>5</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='6' value='6'>6</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='7' value='7'>7</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='8' value='8'>8</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='9' value='9'>9</MenuItem>
                    <MenuItem style={{ whiteSpace: 'normal' }} key='10' value='10'>10 (<FormattedMessage id="feedback.theBest" defaultMessage="The Best" />)</MenuItem>
                </TextField>
            </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: 1, minWidth: '400px', fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                {intl.formatMessage({ id: "feedback.howCanWeDoBetter", defaultMessage: "What could we do to make your experience better?" })}
            </div>
            <div style={{ flex: 1, minWidth: '400px', }}>
                <TextField
                    id="general_feedback"
                    name="general_feedback"
                    multiline
                    minRows={3}
                    maxRows={20}
                    value={general_feedback}
                    onChange={handleChange}
                />
            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '140px' }}>
            <div className='greenButton' onClick={handleSave}>
                <FontAwesomeIcon icon={['fas', 'cloud-upload-alt']} style={{ marginRight: '10px' }} />
                <FormattedMessage id="feedback.submitFeedback" defaultMessage="Submit Feedback" />
            </div>
        </div>
    </div>
}