import * as React from "react"
import { useIntl } from 'react-intl';
import Search from 'v2/assets/svgIcons/Search'

import { InputBase } from '@material-ui/core';

export default function SearchBox({ value, onChange, onSearch }) {
	const intl = useIntl()

    const handleChange = (event) => {
        onChange(event.target.value)
    }
    const preventDefault = (event) => {
        event.preventDefault();
    }

    return <div style={{ border: '1px solid #cccccc', backgroundColor: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <form onSubmit={preventDefault} action="/" style={{ width: '100%' }}>
            <InputBase
                placeholder={intl.formatMessage({ id: 'app.searchForARecipe', defaultMessage: 'Recipe Search' })}
                value={value}
                onChange={handleChange}
                style={{ width: '100%', fontWeight: 200, padding: '10px', color: 'inherit' }}
            />
            <input type='submit' onClick={onSearch} style={{ display: 'none' }} />
        </form>
        <div style={{ padding: '10px', cursor: 'pointer' }} onClick={onSearch}>
            <Search
                size={"20"}
                color="#cccccc"
            />
        </div>
    </div>
}